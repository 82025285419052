export enum CubesatSize {
  '0.5U' = '0.5U',
  '1U' = '1U',
  '1.5U' = '1.5U',
  '2U' = '2U',
  '3U' = '3U',
  '6U' = '6U',
  '12U' = '12U',
  '16U' = '16U',
  'Other' = 'Other',
}
