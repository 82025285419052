import { defineStore } from 'pinia';
import { ILaunchesStoreState } from '@/common/interfaces/launch/launches-store-state.interface';
import { IFetchAllParams, ILaunch } from '@/common/interfaces';

export const useLaunchesStore = defineStore('launches', {
  state: (): ILaunchesStoreState => ({
    list: [],
    count: 0,
    limit: 100,
    error: null,
    loading: true,
    /* temp dictionaries */
    padNames: [],
    padLocations: [],
    vehicleNames: [],
  }),
  getters: {
    getById: (state) => (id: string) =>
      state.list.find((launch: ILaunch) => launch.id === id),
  },
  actions: {
    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launches.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchAllPublic(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launches.fetchAllPublic(params);
        this.setList(response.data ?? response, response.count);
        return response.data;
      } catch (err) {
        this.setError(err);
        throw err;
      } finally {
        this.setLoading(false);
      }
    },

    async fetchAllDraft(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launches.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(launchId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launches.fetchOne(launchId);

        const index = this.list.findIndex((el) => el.id === launchId);

        if (index > -1) {
          this.updateByIndex({
            index,
            launch: response,
          });
        } else {
          this.add(response);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<ILaunch>) {
      this.setError(null);

      try {
        const response = await this.$api.launches.update(data);
        this.setItemData(response);
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async create(data: Partial<ILaunch>) {
      this.setError(null);

      try {
        const response = await this.$api.launches.create(data);
        this.unshift(response);
        return response;
      } catch (err) {
        this.setError(err);
      }
    },

    async remove(id: string) {
      this.setError(null);

      try {
        await this.$api.launches.delete(id);
        this.deleteById(id);
      } catch (err) {
        this.setError(err);
      }
    },

    async fetchDictionaries() {
      const [padNames, padLocations, vehicleNames] = await Promise.all([
        this.$api.pads.fetchNames(),
        this.$api.pads.fetchLocations(),
        this.$api.vehicles.fetchNames(),
      ]);

      this.padNames = padNames;
      this.padLocations = padLocations;
      this.vehicleNames = vehicleNames;
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: ILaunch[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    setItemData(launch: ILaunch) {
      const index = this.list.findIndex((item) => item.id === launch.id);

      if (index !== -1) {
        this.list[index] = launch;
      }
    },

    add(launch: ILaunch) {
      this.list.push(launch);
    },

    unshift(launch: ILaunch) {
      this.list.unshift(launch);
    },

    updateByIndex(payload: { index: number; launch: ILaunch }) {
      this.list[payload.index] = payload.launch;
    },

    deleteById(id: string) {
      const index = this.list.findIndex((item) => item.id === id);

      if (index !== -1) {
        this.list.splice(index, 1);
      }
    },
  },
});
