import { RouteRecordRaw } from 'vue-router';
import CompanyPage from './company.page.vue';
import { useStore } from '@/stores';

export const CompanyRoute: RouteRecordRaw = {
  path: '/company',
  name: 'company',
  component: CompanyPage,
  beforeEnter: (to, from, next) => {
    const { userStore, missionsStore } = useStore();
    userStore.fetch();
    missionsStore.fetchAll();
    next();
  },
};
