import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc37a76e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm-credentials-notice__title" }
const _hoisted_2 = { class: "confirm-credentials-notice__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_col = _resolveComponent("pp-col")!
  const _component_pp_row = _resolveComponent("pp-row")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, {
    onClick: _ctx.showConfirmCredentialsModal,
    class: "confirm-credentials-notice"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_row, { class: "__grid" }, {
        default: _withCtx(() => [
          _createVNode(_component_pp_col, { cols: "auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_icon, {
                class: "confirm-credentials-notice__icon",
                name: "alert",
                size: "20"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_pp_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(`Please, confirm your ${_ctx.fieldName}`), 1),
              _createElementVNode("div", _hoisted_2, _toDisplayString(`Confirm ${_ctx.fieldName}`), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick"]))
}