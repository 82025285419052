<template>
  <li>
    <pp-row class="__grid">
      <pp-col cols="auto"><pp-icon name="check" :size="16" /></pp-col>
      <pp-col><slot /></pp-col>
    </pp-row>
  </li>
</template>

<style scoped lang="scss">
.__grid {
  align-items: center;
}
</style>
