import { ValuesOf } from '@/utils/types';

export const PayloadFormFactor = {
  CUBESAT: 'Cubesat',
  SMALLSAT: 'Smallsat',
  POCKETQUBE: 'PocketQube',
  /** Same as `Hosted Payload` */
  INSTRUMENT: 'Instrument / Sensor',
} as const;

export type PayloadFormFactor = ValuesOf<typeof PayloadFormFactor>;
