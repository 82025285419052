import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "__value"
}
const _hoisted_2 = {
  key: 1,
  class: "__value"
}
const _hoisted_3 = {
  key: 2,
  class: "__value"
}
const _hoisted_4 = {
  key: 3,
  class: "__value"
}
const _hoisted_5 = {
  key: 4,
  class: "__value"
}
const _hoisted_6 = {
  key: 5,
  class: "__value"
}
const _hoisted_7 = {
  key: 6,
  class: "__value"
}
const _hoisted_8 = {
  key: 7,
  class: "__value"
}
const _hoisted_9 = {
  key: 8,
  class: "__value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_chip = _resolveComponent("pp-chip")!
  const _component_pp_labeled_value = _resolveComponent("pp-labeled-value")!
  const _component_pp_markdown = _resolveComponent("pp-markdown")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_spacer = _resolveComponent("pp-spacer")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card_header, null, {
        default: _withCtx(() => [
          _createTextVNode("Configuration")
        ]),
        _: 1
      }),
      (_ctx.architecture.configuration)
        ? (_openBlock(), _createBlock(_component_pp_card_body, { key: 0 }, {
            default: _withCtx(() => [
              (_ctx.architecture.configuration.formFactor)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Form factor",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.formFactor), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.units)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Size",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.units), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.dryMass)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Dry mass",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.dryMass) + " kg ", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.wetMass)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Wet mass",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.wetMass) + " kg ", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.numberOfSatellites)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Number of identical satellites",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.numberOfSatellites), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.deployerStatus)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Deployer",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.deployerStatus), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.deployerMass)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Deployer mass",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.configuration.deployerMass) + " kg ", 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.protrudingElements)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Protruding elements",
                      vertical: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.architecture.configuration.protrudingElements), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.configuration.additionalNotes)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Additional notes",
                      vertical: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_markdown, {
                          source: _ctx.architecture.configuration.additionalNotes,
                          allowedTags: _ctx.AllowedMarkdownTags
                        }, null, 8, ["source", "allowedTags"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_pp_card_actions, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "outline",
                onClick: _ctx.showConfigurationModal,
                title: _ctx.architecture.configuration?.id ? 'Edit' : 'Add'
              }, null, 8, ["onClick", "title"]),
              _createVNode(_component_pp_spacer)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}