import { App } from 'vue';
import { useMixpanel } from '@/services';

export default {
  install(app: App) {
    app.directive('track', {
      mounted(el, binding) {
        const { trackEl } = useMixpanel();
        const { modifiers } = binding;

        if (modifiers.click) {
          el.addEventListener('click', () => {
            const { value } = binding;
            trackEl(el, 'clicked', value);
          });
        }

        if (modifiers.submit) {
          el.addEventListener('submit', () => {
            const { value } = binding;
            trackEl(el, 'submitted', value);
          });
        }
      },
    });
  },
};
