import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_markdown = _resolveComponent("pp-markdown")!
  const _component_pp_labeled_value = _resolveComponent("pp-labeled-value")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_spacer = _resolveComponent("pp-spacer")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card_header, null, {
        default: _withCtx(() => [
          _createTextVNode("Concept of Operations")
        ]),
        _: 1
      }),
      (_ctx.architecture.operationalAlgorithm)
        ? (_openBlock(), _createBlock(_component_pp_card_body, { key: 0 }, {
            default: _withCtx(() => [
              (_ctx.architecture.operationalAlgorithm?.description)
                ? (_openBlock(), _createBlock(_component_pp_labeled_value, {
                    key: 0,
                    label: "Description",
                    vertical: "",
                    class: "__value"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_pp_markdown, {
                        source: _ctx.architecture.operationalAlgorithm?.description,
                        allowedTags: _ctx.AllowedMarkdownTags
                      }, null, 8, ["source", "allowedTags"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_pp_card_actions, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "outline",
                onClick: _ctx.showOAModal,
                title: _ctx.architecture.operationalAlgorithm?.id ? 'Edit' : 'Add'
              }, null, 8, ["onClick", "title"]),
              _createVNode(_component_pp_spacer)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}