import { IArchitecture, ICommonFile } from '@/common/interfaces';
import { RequestManager } from '@/utils';
import { UserRole } from '@/common/enums';
import { BaseApi } from './base.api';

export class ArchitectureApi extends BaseApi<IArchitecture> {
  constructor(role: UserRole) {
    super('architecture', role);
  }

  async createForMission(missionId: string, data: Partial<IArchitecture>) {
    const response = await RequestManager.post<IArchitecture>(
      `${this.path}/mission/${missionId}`,
      data,
    );
    return response.data;
  }

  async fetchAllForMission(
    missionId: string,
    params: Record<string, string> = { sort: 'id' },
  ) {
    const response = await RequestManager.get<IArchitecture[]>(
      `${this.path}/mission/${missionId}`,
      { params },
    );
    return response.data;
  }

  async updateFiles(architectureId: string, files: ICommonFile[]) {
    const response = await RequestManager.put<IArchitecture>(
      `${this.path}/${architectureId}/files`,
      { files },
    );
    return response.data;
  }

  async fetchForRequest(
    requestId: string,
    params: Record<string, string> = { sort: 'id' },
  ) {
    const response = await RequestManager.get<IArchitecture[]>(
      `${this.path}/request/${requestId}`,
      { params },
    );
    return response.data;
  }
}

export default ArchitectureApi;
