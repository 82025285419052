import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ff29476"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_col = _resolveComponent("pp-col")!
  const _component_pp_row = _resolveComponent("pp-row")!

  return (_openBlock(), _createBlock(_component_pp_row, { class: "terms" }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_col, {
        class: "__tabs-group",
        cols: "12"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("button", {
              key: tab.title,
              class: _normalizeClass(["__button", { active: _ctx.activeTab.title === tab.title }]),
              onClick: ($event: any) => (_ctx.activeTab = tab)
            }, _toDisplayString(tab.title), 11, _hoisted_1))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_pp_col, null, {
        default: _withCtx(() => [
          _createElementVNode("iframe", {
            class: "__iframe",
            src: _ctx.activeTab.url
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}