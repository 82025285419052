import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_decline_request_form = _resolveComponent("decline-request-form")!
  const _component_alert_screen = _resolveComponent("alert-screen")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    "max-width": "800"
  }, {
    default: _withCtx(() => [
      (_ctx.step === 1)
        ? (_openBlock(), _createBlock(_component_decline_request_form, {
            key: 0,
            view: _ctx.view,
            response: _ctx.response,
            onCancel: _ctx.close,
            onOk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.step++))
          }, null, 8, ["view", "response", "onCancel"]))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createBlock(_component_pp_card, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_card_body, null, {
                default: _withCtx(() => [
                  _createVNode(_component_alert_screen, {
                    type: "success",
                    title: "You declined request",
                    text: "Redirect to requests page"
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_pp_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_pp_button, { onClick: _ctx.finish }, {
                    default: _withCtx(() => [
                      _createTextVNode("Close")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClose"]))
}