import { createRouter, createWebHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import {
  CookieTypes,
  MixpanelActions,
  useMixpanel,
} from '@/services/analytics';
import { AdminRoutes } from '@/routes/admin';
import { useStore } from '@/stores';
import useModals from '@/composables/useModals';
import { AccessType } from '@/common/enums/access/access-type.enum';

import { AuthRoute } from './auth';
import { CompanyRoute } from './company';
import { DefaultRoute } from './default';
import { ErrorRoute } from './errors';
import { MissionRoutes } from './mission';
import { RequestRoutes } from './request';
import { SettingsRoutes } from './settings';
import { SignupRoute } from './signup';

const { track } = useMixpanel();

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    AdminRoutes,
    AuthRoute,
    CompanyRoute,
    DefaultRoute,
    ErrorRoute,
    MissionRoutes,
    RequestRoutes,
    SettingsRoutes,
    SignupRoute,
  ],
  scrollBehavior() {
    document.querySelector('.scrollable')?.scrollTo(0, 0);
  },
});

router.beforeEach(async (to, from, next) => {
  const { appStore, authStore, userStore } = useStore();
  const { showConfirm } = useModals();
  const { cookies } = useCookies();

  const isInitialized = appStore.initialized;
  const isLoggedIn = authStore.isLoggedIn;

  if (!isInitialized) {
    await appStore.init();
  }

  track(MixpanelActions.ROUTE_TO, {
    route: to.name,
  });

  if (to.meta.access === AccessType.Public) {
    return next();
  }

  if (!isLoggedIn) {
    authStore.setUrl(to.fullPath);
    return next({ name: 'auth' });
  }

  if (to.meta.access === AccessType.Admin) {
    cookies.set(
      CookieTypes.SKIP_MIXPANEL,
      'true',
      Infinity,
      undefined,
      '.preciouspayload.com',
    );

    cookies.set(
      CookieTypes.SKIP_MIXPANEL,
      'true',
      Infinity,
      undefined,
      '.payload.guide',
    );

    if (userStore.isMimic) {
      const confirmed = await showConfirm(
        'You are going to admin page. Do you want to exit mimic mode?',
      );
      if (confirmed) {
        await userStore.unimpersonate();
      } else {
        return next(from); // next() must be called always
      }
    }

    if (!userStore.isAdmin) {
      return next({
        name: 'forbidden',
        hash: to.path,
      });
    }
  }

  const isSignup = to.matched.some(
    (record) => record.name === SignupRoute.name,
  );
  if (!isSignup && !userStore.isSufficientInfo) {
    return next({ name: 'signup' });
  }

  next();
});

router.afterEach((to) => {
  const { searchStore } = useStore();
  searchStore.addRecentVisited(to);
});
