
import { computed, defineComponent, ref } from 'vue';
import { IUserProfile } from '@/common/interfaces';
import ProfileAvatar from '@/components/profile/profile-avatar.vue';
import { useRouter } from 'vue-router';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { useStore } from '@/stores';

export default defineComponent({
  name: 'profile-route',

  components: {
    ProfileAvatar,
  },

  setup() {
    const { userStore, appStore } = useStore();
    const router = useRouter();
    const { showModal } = useModals();

    const links = [
      {
        title: 'Privacy Policy',
        url: 'https://www.iubenda.com/privacy-policy/44828816',
      },
      {
        title: 'Terms and conditions',
        url: 'https://www.iubenda.com/terms-and-conditions/44828816',
      },
      {
        title: 'Cookie Policy',
        url: 'https://www.iubenda.com/privacy-policy/44828816/cookie-policy',
      },
    ];

    const profile = computed<IUserProfile>(() => userStore.data);

    const jobTitle = computed({
      get() {
        return profile.value.metadata.jobTitle || '';
      },
      set(value: string) {
        if (!profileData.value.metadata) {
          profileData.value.metadata = {};
        }

        profileData.value.metadata.jobTitle = value;
      },
    });

    const profileData = ref<Partial<IUserProfile>>({});

    function reset() {
      Object.assign(profileData.value, {
        ...profile.value,
      });
    }

    async function logout() {
      await appStore.logout();
      router.push({ name: 'auth' });
    }

    async function submit() {
      await userStore.update(profileData.value);

      reset();
    }

    function showConfirmCredentialsModal(): void {
      showModal(Modals.CONFIRM_CREDENTIALS_MODAL);
    }

    async function handleUpdateAvatar(data: Partial<IUserProfile>) {
      await userStore.update(data);
      reset();
    }

    reset();

    return {
      profile,
      jobTitle,
      profileData,
      links,
      reset,
      submit,
      logout,
      showConfirmCredentialsModal,
      handleUpdateAvatar,
    };
  },
});
