<template>
  <section class="settings">
    <div class="__tabs-group">
      <router-link class="__tab" :to="{ name: 'profile' }" exact>
        Profile
      </router-link>

      <router-link class="__tab" :to="{ name: 'terms' }" exact>
        Terms & Polices
      </router-link>
    </div>

    <div class="__tab-content">
      <router-view />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';
@import '@/assets/styles/tabs.scss';

.settings {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: gap('md');

  @include sm {
    padding: gap('xl');
  }
}

.__tabs-group {
  display: none;

  @include sm {
    display: flex;
  }
}

.__tab {
  @include tab;
}

.router-link-active {
  @include tab-active;
}

.__tab-content {
  padding: 0;
  flex: 1;
  align-items: stretch;
  position: relative;

  @include sm {
    @include tab-content;
  }
}
</style>
