<template>
  <div class="skeleton-sidebar-submenu">
    <pp-skeleton-loader
      class="__item"
      v-for="item in 5"
      :key="item"
      type="text"
      light
    />
  </div>
</template>

<script>
export default {
  name: 'skeleton-sidebar-submenu',
};
</script>

<style lang="scss" scoped>
$blockName: 'skeleton-sidebar-submenu';

.#{$blockName} {
  .__item {
    height: 24px;
  }
}
</style>
