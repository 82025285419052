
import { computed, defineComponent } from 'vue';
import SkeletonTableList from '@/components/skeletons/skeleton-table-list.vue';
import RequestsList from '@/views/request/admin-requests-list.vue';
import { useStore } from '@/stores';

export default defineComponent({
  name: 'admin-mission-requests-page',

  components: {
    RequestsList,
    SkeletonTableList,
  },
  setup() {
    const { requestsStore } = useStore();

    const requests = computed(() => requestsStore.list);
    const isLoading = computed(() => requestsStore.loading);

    return {
      requests,
      isLoading,
    };
  },
});
