
import { computed, defineComponent, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { EntityNotFoundException } from '@/common/exceptions';
import { ICompany, IMission, ITab, ITableData } from '@/common/interfaces';
import CompanyDetails from '@/views/company/company-details.vue';
import { useStore } from '@/stores';
import useModals from '@/composables/useModals';
import CustomTable from '@/components/table/custom-table.vue';
import TabsPanel from '@/components/tabs/tabs-panel.vue';
import SkeletonTableList from '@/components/skeletons/skeleton-table-list.vue';
import ColorDivider from '@/components/color-divider/color-divider.vue';
import CompanyTeam from '@/components/company/company-team.vue';
import SkeletonCompany from '@/components/skeletons/skeleton-company.vue';

export default defineComponent({
  name: 'admin-one-company-page',

  components: {
    SkeletonCompany,
    CompanyDetails,
    CustomTable,
    TabsPanel,
    SkeletonTableList,
    ColorDivider,
    CompanyTeam,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { showConfirm } = useModals();
    const { companyStore, missionsStore } = useStore();

    const company = computed(() =>
      companyStore.getById(route.params.companyId as string),
    );
    const membersError = computed(() => companyStore.membersError);

    const companyIsLoading = computed(() => companyStore.loading);

    watchEffect(() => {
      if (companyStore.error instanceof EntityNotFoundException) {
        router.replace({
          name: 'not-found',
          params: { path: route.path.split('/').filter((v) => v) },
        });
      }
    });

    const tabList: ITab[] = [
      {
        key: 'missions',
        name: 'Missions',
      },
    ];

    const missions = computed(() => missionsStore.list);
    const missionListIsLoading = computed(() => missionsStore.loading);
    const missionTableData = computed<ITableData>(() => ({
      headers: [
        {
          text: 'Mission',
          value: 'name',
        },
        {
          text: 'Status',
          value: 'currentPhase',
        },
        {
          text: 'Requests',
          value: 'requests',
        },
        {
          text: 'Created by',
          value: 'createdBy',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      items: missions.value,
    }));

    function updateCompany(data: Partial<ICompany>) {
      companyStore.update(data);
    }

    function createMission() {
      router.push({
        name: 'admin-create-mission',
        query: {
          companyId: company.value?.id,
        },
      });
    }

    function showItem(item: IMission): void {
      router.push({
        name: 'admin-one-mission',
        params: { missionId: item.id },
      });
    }

    const members = computed(() => companyStore.members);

    function updateCompanyField(field: keyof ICompany, value: unknown) {
      updateCompany({
        ...company.value,
        [field]: value,
      });
    }

    async function removeMission(mission: IMission) {
      if (!mission.id) {
        return;
      }

      const confirmed = await showConfirm(
        `Are you sure you want to delete mission "${mission.name}"?`,
      );

      if (confirmed) {
        await missionsStore.remove(mission.id);
      }
    }

    return {
      company,
      companyIsLoading,
      missionListIsLoading,
      missionTableData,
      tabList,
      members,
      membersError,
      showItem,
      createMission,
      updateCompany,
      updateCompanyField,
      removeMission,
    };
  },
});
