import { IInvitation } from '@/common/interfaces';
import { RequestManager } from '@/utils';

export class InviteApi {
  path = 'invite';

  async fetchOne(uuid: string) {
    const response = await RequestManager.get<IInvitation>(
      `${this.path}/${uuid}`,
    );
    return response.data;
  }
}
