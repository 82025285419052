import { Component, markRaw, ref } from 'vue';
import { Modals } from '@/modals';
import { InteractionModalType } from '@/common/enums';

export type IModalProps = Record<string, unknown>;

export type IModalEmits = Record<string, unknown>;

export interface IModal<T = IModalProps, E = IModalEmits> {
  name: string;
  component: Component;
  props: T;
  emits: E;
}

const registeredModals = ref<Record<string, Component>>({});
const modals = ref<IModal[]>([]);

const useModals = () => {
  function registerModal(name: Modals, component: Component): void {
    if (!registeredModals.value[name]) {
      registeredModals.value[name] = markRaw(component);
    }
  }

  function showModal<T = IModalProps, E = IModalEmits>(
    name: Modals,
    props?: T,
    emits?: E,
  ): void {
    if (modals.value.find((modal) => modal.name === name)) {
      return;
    }

    if (!registeredModals.value[name]) {
      return;
    }

    modals.value.push({
      name,
      component: registeredModals.value[name],
      props: props ?? {},
      emits: emits ?? {},
    });
  }

  function showModalComponent<T = IModalProps, E = IModalEmits>(
    name: string,
    component: Component,
    props?: T,
    emits?: E,
  ) {
    if (modals.value.find((modal) => modal.name === name)) {
      return;
    }

    modals.value.push({
      name,
      component: markRaw(component),
      props: props ?? {},
      emits: emits ?? {},
    });
  }

  function showConfirm(message: string): Promise<boolean> {
    return new Promise((resolve) => {
      const props: IModalProps = {
        message,
        type: InteractionModalType.CONFIRM,
      };

      const emits: IModalEmits = {
        ok: () => resolve(true),
        cancel: () => resolve(false),
      };

      showModal(Modals.INTERACTION_MODAL, props, emits);
    });
  }

  function showPrompt(message: string): Promise<string | null> {
    return new Promise((resolve) => {
      const props: IModalProps = {
        message,
        type: InteractionModalType.PROMPT,
      };

      const emits: IModalEmits = {
        ok: (result: string) => resolve(result),
        cancel: () => resolve(null),
      };

      showModal(Modals.INTERACTION_MODAL, props, emits);
    });
  }

  function showAlert(
    type: string,
    title: string,
    text?: string,
  ): Promise<void> {
    return new Promise((resolve) => {
      const props: IModalProps = {
        type,
        title,
        text,
      };

      const emits: IModalEmits = {
        cancel: () => resolve(),
      };

      showModal(Modals.ALERT_MODAL, props, emits);
    });
  }

  function hideModal(name: Modals | string): void {
    const index = modals.value.findIndex((modal) => modal.name === name);

    if (index !== -1) {
      modals.value.splice(index, 1);
    }
  }

  return {
    modals,
    registerModal,
    showModal,
    showModalComponent,
    showConfirm,
    showPrompt,
    showAlert,
    hideModal,
  };
};

export default useModals;
