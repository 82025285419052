import { RouteRecordRaw } from 'vue-router';
import AdminMissionPage from './admin-mission-page.vue';
import AdminCreateMissionPage from './admin-create-mission-page.vue';
import AdminOneMissionPage from './admin-one-mission-page.vue';
import AdminListMissionPage from './admin-list-mission-page.vue';
import AdminMissionRequestsPage from '@/routes/admin/routes/missions/admin-mission-requests-page.vue';
import { useStore } from '@/stores';

export const adminMissionsRoutes: RouteRecordRaw = {
  path: '/admin/mission',
  component: AdminMissionPage,
  name: 'admin-missions',
  redirect: { name: 'admin-list-mission' },
  children: [
    {
      path: 'create',
      name: 'admin-create-mission',
      component: AdminCreateMissionPage,
    },
    {
      path: ':missionId',
      name: 'admin-one-mission',
      component: AdminOneMissionPage,
      beforeEnter: (to, from, next) => {
        const { missionsStore, architectureStore } = useStore();

        const missionId = to.params.missionId as string;

        missionsStore.fetchOne(missionId);
        architectureStore.fetchForMission(missionId);

        next();
      },
    },
    {
      path: ':missionId/requests',
      name: 'admin-mission-requests',
      component: AdminMissionRequestsPage,
      beforeEnter: (to, from, next) => {
        const { requestsStore } = useStore();

        requestsStore.fetchAll({
          filter: `missionId||$eq||${to.params.missionId}`,
        });

        next();
      },
    },
    {
      path: '/admin/missions',
      name: 'admin-list-mission',
      component: AdminListMissionPage,
      beforeEnter: (to, from, next) => {
        const { missionsStore } = useStore();

        missionsStore.fetchAll({ limit: missionsStore.limit });
        next();
      },
    },
    {
      path: '/admin/missions/deleted',
      name: 'admin-list-deleted-mission',
      component: AdminListMissionPage,
      beforeEnter: (to, from, next) => {
        const { missionsStore } = useStore();

        missionsStore.fetchDeleted({ limit: missionsStore.limit });
        next();
      },
    },
  ],
};
