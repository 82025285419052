
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  toRef,
  watch,
} from 'vue';

import { IMission } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { useStore } from '@/stores';

import SkeletonMission from '@/components/skeletons/skeleton-mission.vue';
import { CheckList, CheckListItem } from '@/components/check-list';

export default defineComponent({
  components: {
    CheckList,
    CheckListItem,
    SkeletonMission,
  },

  props: {
    mission: {
      type: Object as PropType<IMission>,
      default: () => ({
        id: '',
        name: '',
        description: '',
      }),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    anonymous: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { showModal } = useModals();
    const { missionsStore } = useStore();

    const mission = toRef(props, 'mission');

    const data = reactive({
      id: mission?.value?.id ?? '',
      name: mission?.value?.name ?? '',
      description: mission.value?.description ?? '',
    });

    const launchDateFormatted = computed(() => {
      const { launchDate } = mission.value;
      const quarter = launchDate?.quarter ? `Q${launchDate.quarter}` : '';
      const result = [quarter, launchDate?.year].filter(Boolean).join('x');
      return result;
    });

    const update = (): void => {
      if (mission.value?.id) {
        missionsStore.update(data);
      }
    };

    function showDetailModal(): void {
      showModal(Modals.EDIT_DETAIL_MODAL, {
        mission,
      });
    }

    function showEditGoalsModal(): void {
      showModal(Modals.EDIT_GOALS_MODAL, {
        mission,
      });
    }

    function showEditPayloadTypeModal(): void {
      showModal(Modals.EDIT_PAYLOAD_TYPE_MODAL, {
        mission,
      });
    }

    watch(mission, ({ id, name, description }) => {
      Object.assign(data, {
        id,
        name,
        description,
      });
    });

    return {
      data,
      launchDateFormatted,
      update,
      showDetailModal,
      showEditGoalsModal,
      showEditPayloadTypeModal,
    };
  },
});
