
import { defineComponent, onMounted, onUnmounted } from 'vue';
import useModals from '@/composables/useModals';

export default defineComponent({
  name: 'modals-container',
  setup() {
    const { modals } = useModals();

    function handleEscKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        modals.value.pop();
      }
    }

    onMounted(() => {
      document.addEventListener('keyup', handleEscKeyDown);
    });

    onUnmounted(() => {
      document.removeEventListener('keyup', handleEscKeyDown);
    });

    return {
      modals,
    };
  },
});
