import { IFetchAllParams, IMission, Paginated } from '@/common/interfaces';
import { BaseApi } from './base.api';
import { RequestManager } from '@/utils';
import { UserRole } from '@/common/enums';
import { defaultFetchAllParams } from '@/common/const';

export class MissionApi extends BaseApi<IMission> {
  constructor(role: UserRole) {
    super('mission', role);
  }

  async fetchDeleted(
    params: IFetchAllParams = defaultFetchAllParams,
  ): Promise<Paginated<IMission>> {
    const response = await RequestManager.get<Paginated<IMission>>(
      this.path + `/deleted`,
      {
        params: {
          ...defaultFetchAllParams,
          ...params,
        },
      },
    );
    return response.data;
  }

  async fetchForRequest(requestId: string) {
    const response = await RequestManager.get<IMission>(
      this.path + `/request/${requestId}`,
    );
    return response.data;
  }
}
