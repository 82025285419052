import { HttpException, IHttpExceptionData } from './http.exception';

export class ForbiddenException extends HttpException {
  constructor(message: string);
  constructor(data: string | IHttpExceptionData) {
    if (typeof data === 'string') {
      super({
        statusCode: 403,
        message: data,
      });
    } else {
      super({
        ...data,
        statusCode: 403,
      });
    }
  }
}
