export enum ErrorCodes {
  /* Auth */
  USER_DELETED = 'user_deleted',
  COMPANY_DELETED = 'company_deleted',

  /* View */
  VIEW_SUBMIT_BAD_STATUS = 'view_submit_bad_status',
  VIEW_ASK_DETAILS_BAD_STATUS = 'view_ask_details_bad_status',
  VIEW_DECLINE_BAD_STATUS = 'view_decline_bad_status',
  VIEW_REVOKE_BAD_STATUS = 'view_revoke_bad_status',
}
