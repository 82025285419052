import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createBlock(_component_avatar, {
    class: "profile-avatar",
    modelValue: _ctx.profile.metadata?.avatarUrl,
    label: _ctx.label,
    editable: _ctx.editable,
    "onUpdate:modelValue": _ctx.handleUpdate
  }, null, 8, ["modelValue", "label", "editable", "onUpdate:modelValue"]))
}