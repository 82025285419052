import ccl from 'country-codes-list';

/** @see https://en.wikipedia.org/wiki/United_States_sanctions */
const sanctioned = new Set([
  // https://preciouspayload.slack.com/archives/C02HJNTQPPE/p1677511864933479?thread_ts=1677063480.903899&cid=C02HJNTQPPE
  'Cuba',
  'Iran (Islamic Republic of)',
  'Iran',
  'North Korea',
  'Russia',
  'Syria',
  'Syrian Arab Republic',
]);

const first = [
  'United States of America',
  'United Kingdom',
  'United Arab Emirates',
  'Canada',
  'France',
  'Germany',
  'India',
  'Ireland',
  'Israel',
  'Italy',
  'Netherlands',
  'Spain',
  'Singapore',
  'Sweden',
  'Japan',
  'Australia',
];

export interface ICountryCode {
  /** Phone code starting with + */
  value: string;
  /** Formatted phone code starting with + */
  label: string;
  /** Flag emoji */
  flag: string;
  /** Country name */
  name: string;
}

export const countryCodes: ICountryCode[] = ccl
  .all()
  .filter((country) => {
    const name = country.countryNameEn;
    return !sanctioned.has(name);
  })
  .map((country) => ({
    value: `+${country.countryCallingCode}`,
    label: `+${country.countryCallingCode}`,
    flag: country.flag,
    name: country.countryNameEn,
  }))
  .sort((a, b) => {
    const a1 = first.indexOf(a.name);
    const b1 = first.indexOf(b.name);

    if (a1 !== -1 && b1 !== -1) {
      return a1 - b1;
    }
    if (a1 !== -1) {
      return -1;
    }
    if (b1 !== -1) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  });

export const countries = countryCodes.map((country) => country.name);

const countryByCode = new Map<string, ICountryCode>();
countryCodes.forEach((country) => {
  countryByCode.set(country.value, country);
});

export function findByCode(phone: string): ICountryCode | null {
  let code = phone.replace(/[^0-9]/g, '').slice(0, 5);

  while (code.length > 0) {
    const country = countryByCode.get(`+${code}`);
    if (country) {
      return country;
    }
    code = code.slice(0, -1);
  }

  return null;
}

export function findByName(name: string): ICountryCode | null {
  const country = countryCodes.find((country) => country.name === name);
  return country || null;
}
