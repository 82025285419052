
import { defineComponent, ref, toRefs } from 'vue';
import { IResponse, IView } from '@/common/interfaces';
import { useStore } from '@/stores';

interface IDeclineData {
  reason: string;
}

export default defineComponent({
  props: {
    view: {
      type: Object as () => IView,
      required: true,
    },
    response: {
      type: Object as () => IResponse,
    },
  },

  emits: ['cancel', 'ok'],

  setup(props, { emit }) {
    const { response } = toRefs(props);
    const { viewsStore } = useStore();

    const data = ref<IDeclineData>({
      reason: response.value?.reason ?? '',
    });

    async function ok() {
      await viewsStore.decline(props.view.id, data.value);
      // TODO: loader
      emit('ok');
    }

    return {
      data,
      close,
      ok,
    };
  },
});
