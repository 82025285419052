
import { ICommonFile } from '@/common/interfaces';
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { MissionDetails } from '@/views/mission';
import Architectures from '@/views/architecture/architectures.vue';
import ProjectGallery from '@/components/project-gallery/project-gallery.vue';
import ColorDivider from '@/components/color-divider/color-divider.vue';
import MissionRequests from '@/components/mission/mission-requests.vue';
import { useStore } from '@/stores';
import { EntityNotFoundException } from '@/common/exceptions';

export default defineComponent({
  name: 'admin-one-mission-page',

  components: {
    MissionRequests,
    MissionDetails,
    Architectures,
    ProjectGallery,
    ColorDivider,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { missionsStore, architectureStore } = useStore();

    const missionId = ref(route.params.missionId as string);

    const mission = computed(() => missionsStore.getById(missionId.value));

    const isMissionsLoading = computed(() => missionsStore.loading);

    watchEffect(() => {
      if (missionsStore.error instanceof EntityNotFoundException) {
        router.replace({
          name: 'not-found',
          params: { path: route.path.split('/').filter((v) => v) },
        });
      }
    });

    const currentArchIndex = computed(() => architectureStore.currentIndex);

    const architectureList = computed(() => architectureStore.list);

    const architectureImages = computed(() => architectureStore.files);

    const isArchitecturesLoading = computed(() => architectureStore.loading);

    const requests = computed(() => {
      if (!mission.value) {
        return [];
      }

      return mission.value.requests.map((request) => ({
        ...request,
        mission: mission.value,
      }));
    });

    function updateFiles(files: ICommonFile[]): void {
      architectureStore.updateFiles({
        id: architectureList.value[currentArchIndex.value]?.id,
        files,
      });
    }

    return {
      mission,
      requests,
      currentArchIndex,
      architectureImages,
      isMissionsLoading,
      isArchitecturesLoading,
      updateFiles,
    };
  },
});
