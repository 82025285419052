export const AllowedMarkdownTags: string[] = [
  'p',
  'ol',
  'ul',
  'li',
  'h3',
  'h4',
  'b',
  'br',
  'strong',
  'pre',
  'a',
];
