
import { computed, defineComponent, PropType, ref, toRefs } from 'vue';
import SumField from '@/components/fields/sum-field.vue';
import { BudgetExpectation } from '@/common/enums/request/budget-expectation.enum';
import { SupplierSelectionCriteria } from '@/common/enums/request/supplier-selection-criteria.enum';
import { LaunchDateFlexibility } from '@/common/enums/request/launch-date-flexibility.enum';
import { SupplierSelectionTime } from '@/common/enums/request/supplier-selection-time.enum';
import { IRequest } from '@/common/interfaces';
import DateField from '@/components/fields/date-field.vue';

export default defineComponent({
  name: 'request-info',
  components: {
    DateField,
    SumField,
  },
  props: {
    modelValue: {
      type: Object as PropType<Partial<IRequest>>,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const request = ref<Partial<IRequest>>(generateComputedProps());

    function enumToArray(enumValue: object): string[] {
      return Object.values(enumValue);
    }

    function generateComputedProps(): Record<string, unknown> {
      const computedProps: Record<string, unknown> = {};

      for (const key in modelValue.value) {
        computedProps[key] = computed({
          get: () => modelValue.value[key as keyof IRequest],
          set: (value) =>
            emit('update:modelValue', {
              ...modelValue.value,
              [key]: value,
            }),
        });
      }

      return computedProps;
    }

    return {
      request,
      budgetExpectationOptions: enumToArray(BudgetExpectation),
      supplierSelectionCriteriaOptions: enumToArray(SupplierSelectionCriteria),
      launchDateFlexibilityOptions: enumToArray(LaunchDateFlexibility),
      supplierSelectionTimeOptions: enumToArray(SupplierSelectionTime),
    };
  },
});
