
import { IArchitecture, IInsurance } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { defineComponent, PropType, ref, toRef } from 'vue';

export default defineComponent({
  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { showModal } = useModals();

    const insurance = ref<Partial<IInsurance>>({});
    const architecture = toRef(props, 'architecture');

    function showInsuranceModal(item: Partial<IInsurance>) {
      insurance.value = item;
      showModal(Modals.INSURANCE_MODAL, {
        insurance,
        architecture,
      });
    }

    return {
      insurance,
      showInsuranceModal,
    };
  },
});
