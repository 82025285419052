import { ViewStatus } from '../enums';
export const ViewStatusDictionary: Record<
  ViewStatus,
  { title: string; color: string }
> = {
  [ViewStatus.WAITING_FOR_RESPONSE]: {
    title: 'Waiting for response',
    color: 'grey',
  },
  [ViewStatus.EXPIRED]: {
    title: 'Expired',
    color: 'red',
  },
  [ViewStatus.DECLINED]: {
    title: 'Declined',
    color: 'red',
  },
  [ViewStatus.DETAILS_REQUESTED]: {
    title: 'Details requested',
    color: 'light-blue',
  },
  [ViewStatus.SUBMITTED]: {
    title: 'Submitted',
    color: 'green',
  },
};
