import Smartlook from 'smartlook-client';
import { useCookies } from 'vue3-cookies';
import { CookieTypes } from './cookie-types.enum';

export function useSmartlook(): void {
  const { cookies } = useCookies();

  if (cookies.isKey(CookieTypes.SKIP_MIXPANEL)) {
    console.info('Smartlook is disabled');
    return;
  }

  Smartlook.init('874c5074b58dd6ee3de353b89a5aeb842bab3890');
}
