import { MONTHS, MONTHS_SHORT } from '@/common/const';
import { ILaunchDate } from '@/common/interfaces';

/**
 * Format `LaunchDateDto` into readable text.
 * @param date `LaunchDateDto` to format.
 * @param pattern pattern letters separated by space.
 * Where:\
 * 'D' is Day, but visible only if Month specified,\
 * 'M' is full Month,\
 * 'm' is short month (3 letters),\
 * 'Q' is Quarter,\
 * 'Y' is Year,\
 * 'MQ'/'mQ' is Month if specified or Quarter.\
 * `Default`: 'D MQ Y'
 * @returns Formatted text.
 */
export function formatLaunchDate(
  date: ILaunchDate,
  pattern = 'D MQ Y',
): string {
  if (!date) return '';

  const { day, month = -1, quarter, year } = date;
  const M = MONTHS[month - 1];
  const m = MONTHS_SHORT[month - 1];
  const Q = quarter ? `Q${quarter}` : '';
  const Y = year;

  const D = M ? day : '';

  const parts = pattern
    .split(' ')
    .map((pat) => {
      switch (pat) {
        case 'D':
          return D;
        case 'M':
          return M;
        case 'm':
          return m;
        case 'Q':
          return Q;
        case 'Y':
          return Y;
        case 'MQ':
          return M || Q;
        case 'mQ':
          return m || Q;
        default:
          return '';
      }
    })
    .filter((part) => part);

  return parts.join(' ').trim();
}
