import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5436f27c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "__value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_labeled_value = _resolveComponent("pp-labeled-value")!
  const _component_pp_chip = _resolveComponent("pp-chip")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_spacer = _resolveComponent("pp-spacer")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card_header, null, {
        default: _withCtx(() => [
          _createTextVNode("Licensing")
        ]),
        _: 1
      }),
      (_ctx.architecture.licensing)
        ? (_openBlock(), _createBlock(_component_pp_card_body, { key: 0 }, {
            default: _withCtx(() => [
              (_ctx.architecture.licensing?.name)
                ? (_openBlock(), _createBlock(_component_pp_labeled_value, {
                    key: 0,
                    label: "License name",
                    vertical: "",
                    class: "__value"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.architecture.licensing?.name), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.architecture.licensing?.type)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "License type",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.architecture.licensing?.type), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.architecture.licensing?.country)
                ? (_openBlock(), _createBlock(_component_pp_labeled_value, {
                    key: 2,
                    label: "Country of spacecraft/payload registration",
                    vertical: "",
                    class: "__value"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.architecture.licensing?.country), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.architecture.licensing?.responsible)
                ? (_openBlock(), _createBlock(_component_pp_labeled_value, {
                    key: 3,
                    label: "Responsible for application",
                    vertical: "",
                    class: "__value"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.architecture.licensing?.responsible), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.architecture.licensing?.status)
                ? (_openBlock(), _createBlock(_component_pp_labeled_value, {
                    key: 4,
                    label: "Application status",
                    vertical: "",
                    class: "__value"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.architecture.licensing?.status), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_pp_card_actions, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "outline",
                onClick: _ctx.showLicensingModal,
                title: _ctx.architecture.licensing?.id ? 'Edit' : 'Add'
              }, null, 8, ["onClick", "title"]),
              _createVNode(_component_pp_spacer)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}