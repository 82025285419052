
import uploadcare from 'uploadcare-widget';
import { defineComponent, PropType } from 'vue';
import { ICommonFile } from '@/common/interfaces';
import { IFilesPromise } from '@/types/uploadcare-widget';

export default defineComponent({
  name: 'file-list',

  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array as PropType<Array<ICommonFile>>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    async function handleOpenAddFilesModal(): Promise<void> {
      const dialog = uploadcare.openDialog<IFilesPromise>([], 'file', {
        publicKey: process.env.VUE_APP_UPLOADCARE,
        multiple: true,
        tabs: 'file url gdrive gphotos dropbox onedrive',
      });

      dialog.done((result) => {
        result.promise().done(() => {
          const fileList: ICommonFile[] = [];

          result.files().forEach((filePromise) => {
            filePromise.done((file) => {
              fileList.push({
                name: file.name,
                url: file.cdnUrl,
              });
            });
          });

          const newValue = (props.modelValue || []).concat(fileList);
          emit('update:modelValue', newValue);
        });
      });
    }

    function deleteFile(index: number): void {
      const newValue = props.modelValue?.filter((v, i) => i !== index);
      emit('update:modelValue', newValue);
    }

    return {
      handleOpenAddFilesModal,
      deleteFile,
    };
  },
});
