import { defineStore } from 'pinia';
import {
  IFetchAllParams,
  ILaunchDraft,
  ILaunchesDraftStoreState,
} from '@/common/interfaces';

export const useLaunchesDraftStore = defineStore('launches_draft', {
  state: (): ILaunchesDraftStoreState => ({
    list: [],
    count: 0,
    limit: 100,
    error: null,
    loading: true,
  }),
  getters: {
    getById: (state) => (id: string) =>
      state.list.find((launchDraft: ILaunchDraft) => launchDraft.id === id),
  },
  actions: {
    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launchesDraft.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchAllDraft(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launchesDraft.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(launchId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.launchesDraft.fetchOne(launchId);

        const index = this.list.findIndex((el) => el.id === launchId);

        if (index > -1) {
          this.updateByIndex({
            index,
            launchDraft: response,
          });
        } else {
          this.add(response);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<ILaunchDraft>) {
      this.setError(null);

      try {
        const launchDraft = await this.$api.launchesDraft.update(data);

        const index = this.list.findIndex((el) => el.id === launchDraft.id);

        if (index > -1) {
          this.updateByIndex({
            index,
            launchDraft,
          });
        }
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async apply(draftId: string) {
      try {
        return await this.$api.launchesDraft.apply(draftId);
      } catch (err) {
        this.setError(err);
      }
    },

    async decline(draftId: string) {
      try {
        const launchDraft = await this.$api.launchesDraft.delete(draftId);

        const index = this.list.findIndex((el) => el.id === launchDraft.id);

        if (index > -1) {
          this.updateByIndex({
            index,
            launchDraft,
          });
        }
      } catch (err) {
        this.setError(err);
      }
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: ILaunchDraft[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    add(launch: ILaunchDraft) {
      this.list.push(launch);
    },

    updateByIndex(payload: { index: number; launchDraft: ILaunchDraft }) {
      this.list[payload.index] = payload.launchDraft;
    },
  },
});
