import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock, renderList as _renderList, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/gallery-load.svg'


const _withScopeId = n => (_pushScopeId("data-v-9b638a32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__selected-image-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "__selected-file"
}
const _hoisted_4 = {
  key: 1,
  class: "__selected-image",
  src: _imports_0,
  alt: "Gallery load"
}
const _hoisted_5 = { class: "__previews-wrapper" }
const _hoisted_6 = { class: "__previews" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "__preview-file"
}
const _hoisted_10 = { class: "__filename" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_text_field = _resolveComponent("pp-text-field")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    "max-width": "734"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_header, null, {
            default: _withCtx(() => [
              _createTextVNode("Add project files")
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_body, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_pp_icon, {
                  class: "__loading",
                  name: "settings"
                }),
                (_ctx.selectedImage)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.isSelectedImg)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "__selected-image",
                            src: _ctx.selectedImage.url,
                            alt: "Selected image"
                          }, null, 8, _hoisted_2))
                        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_pp_icon, {
                              name: "file",
                              size: "72"
                            }),
                            _createElementVNode("div", null, _toDisplayString(_ctx.selectedImage.name), 1)
                          ]))
                    ], 64))
                  : (_openBlock(), _createElementBlock("img", _hoisted_4))
              ]),
              (!_ctx.isLoading && _ctx.selectedImage)
                ? (_openBlock(), _createBlock(_component_pp_text_field, {
                    key: 0,
                    class: "__description-field",
                    "model-value": _ctx.selectedImage.description,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedImage.description) = $event)),
                    placeholder: "Add description"
                  }, null, 8, ["model-value"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadedImages, (img, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(['__preview', { _selected: index === _ctx.activeIndex }]),
                      key: index,
                      style: _normalizeStyle({ backgroundImage: `url(${img.url})` }),
                      onClick: () => _ctx.chooseImage(index)
                    }, [
                      _createElementVNode("button", {
                        class: "__remove-btn",
                        onClick: () => _ctx.removeImage(index)
                      }, [
                        _createVNode(_component_pp_icon, {
                          name: "delete",
                          size: "14"
                        })
                      ], 8, _hoisted_8),
                      (!_ctx.isImg(img))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_pp_icon, {
                              name: "file",
                              size: "24"
                            }),
                            _createElementVNode("div", _hoisted_10, _toDisplayString(img.name), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ], 14, _hoisted_7))
                  }), 128))
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["__preview", { '_with-offset': _ctx.images.length }]),
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openFileDialog && _ctx.openFileDialog(...args)))
                }, [
                  _createVNode(_component_pp_icon, { name: "plus" })
                ], 2)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_pp_button, { onClick: _ctx.save }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}