import { RouteRecordRaw } from 'vue-router';
import { LayoutType } from '@/common/enums/layout';
import { useUserStore } from '@/stores/user';

import ProxyPage from '@/components/proxy-page.vue';
import SignupPage from './signup.page.vue';
import SignupSOPage from './signup-so.page.vue';
import SignupSPPage from './signup-sp.page.vue';
import SignupMAPage from './signup-ma.page.vue';
import SignupInvitedPage from './signup-invited.page.vue';

export const SignupRoute: RouteRecordRaw = {
  path: '/signup',
  name: 'signup parent',
  component: ProxyPage,
  meta: {
    layout: LayoutType.Simplified,
  },
  children: [
    {
      path: '',
      name: 'signup',
      component: SignupPage,
    },
    {
      path: 'so',
      name: 'signup-so',
      component: SignupSOPage,
    },
    {
      path: 'sp',
      name: 'signup-sp',
      component: SignupSPPage,
    },
    {
      path: 'ma',
      name: 'signup-ma',
      component: SignupMAPage,
    },
    {
      path: 'invited',
      name: 'signup-invited',
      component: SignupInvitedPage,
    },
  ],
  beforeEnter: (_to, _from, next) => {
    const store = useUserStore();
    if (store.isSufficientInfo) {
      next({ name: 'home' });
    } else {
      next();
    }
  },
};
