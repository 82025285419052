
import { ICommonFile } from '@/common/interfaces';
import { computed, defineComponent, toRefs } from 'vue';
import { isImage } from '@/utils/is-image';

const __default__ = defineComponent({
  name: 'project-gallery-card',
  props: {
    file: {
      type: Object as () => ICommonFile,
      required: true,
    },
  },
  setup(props) {
    const { file } = toRefs(props);

    const fontSizeMap = {
      4: 16,
      5: 12,
      6: 10,
      7: 8,
      9: 6,
    };

    const isImg = computed<boolean>(() => isImage(file.value.name));

    const fileExt = computed<string>(
      () => file.value.name.split('.').pop() || '',
    );

    const fontSize = computed<string>(() => {
      for (const [fileExtLength, fontSize] of Object.entries(fontSizeMap)) {
        if (fileExt.value.length <= Number(fileExtLength)) {
          return fontSize + 'px';
        }
      }

      return '6px';
    });

    function openImage(): void {
      window.open(file.value.url, '_blank');
    }

    return {
      isImg,
      fileExt,
      fontSize,
      openImage,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "563d451e": (_ctx.fontSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__