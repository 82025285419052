<template>
  <div class="skeleton-sidebar-menu">
    <pp-skeleton-loader
      class="__item"
      v-for="item in 5"
      :key="item"
      type="text"
      light
    >
    </pp-skeleton-loader>
  </div>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-sidebar-menu {
  display: flex;
  flex-direction: column;
  @include gap('sm');
}

.__item {
  height: 24px;
}
</style>
