
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue';

export default defineComponent({
  name: 'pp-accordion',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, slots }) {
    const { isOpen, content } = toRefs(props);
    const contentNode = ref();
    const bodyNode = ref();

    const isOpenProxy = computed({
      get: () => isOpen.value,
      set: (value) => emit('update:isOpen', value),
    });

    watch([isOpenProxy, slots.content, content], () => {
      nextTick(setHeight);
    });

    onMounted(setHeight);

    function setHeight(): void {
      bodyNode.value.style.height = isOpenProxy.value
        ? `${contentNode.value.offsetHeight}px`
        : 0;
    }

    function switchAccordion(): void {
      isOpenProxy.value = !isOpenProxy.value;
    }

    return {
      isOpenProxy,
      contentNode,
      bodyNode,
      switchAccordion,
    };
  },
});
