<template>
  <div class="skeleton-sidebar-header">
    <pp-skeleton-loader light type="avatar-sm" />
    <pp-skeleton-loader class="__name" light type="h3" />
  </div>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-sidebar-header {
  .__name {
    margin-top: gap('lg');
    margin-bottom: gap('xl');
  }
}
</style>
