import { RouteRecordRaw } from 'vue-router';
import AdminLaunchesPage from './admin-launches-page.vue';
import AdminListLaunchesPage from './admin-list-launches-page.vue';
import AdminOneLaunch from './admin-one-launch-page.vue';
import AdminOneLaunchDraft from './admin-one-launch-draft-page.vue';
import AdminCreateLaunchPage from './admin-create-launch-page.vue';
import { useStore } from '@/stores';

export const adminLaunchesRoutes: RouteRecordRaw = {
  path: '/admin/launch',
  component: AdminLaunchesPage,
  name: 'admin-launches',
  redirect: { name: 'admin-list-launches' },
  children: [
    {
      path: '/admin/launches',
      name: 'admin-list-launches',
      component: AdminListLaunchesPage,
      beforeEnter: (to, from, next) => {
        const { launchesStore } = useStore();
        launchesStore.fetchAll({ limit: 0 });
        next();
      },
    },
    {
      path: '/admin/launches/draft',
      name: 'admin-list-draft-launches',
      component: AdminListLaunchesPage,
      beforeEnter: (to, from, next) => {
        const { launchesDraftStore } = useStore();
        launchesDraftStore.fetchAll({ limit: 0 });
        next();
      },
    },
    {
      path: ':launchId',
      name: 'admin-one-launch',
      component: AdminOneLaunch,
      beforeEnter: (to, from, next) => {
        const { launchesStore, companyStore } = useStore();

        const launchId = to.params.launchId as string;

        launchesStore.fetchOne(launchId);
        companyStore.fetchAll({
          filter: 'serviceTypes||$notnull',
        });
        next();
      },
    },
    {
      path: '/admin/launches/draft/:launchDraftId',
      name: 'admin-one-launch-draft',
      component: AdminOneLaunchDraft,
      beforeEnter: (to, from, next) => {
        const { launchesDraftStore, companyStore } = useStore();

        const launchDraftId = to.params.launchDraftId as string;

        launchesDraftStore.fetchOne(launchDraftId);
        companyStore.fetchAll({
          filter: 'serviceTypes||$notnull',
        });

        next();
      },
    },
    {
      path: '/admin/launches/create',
      name: 'admin-create-launch',
      component: AdminCreateLaunchPage,
      beforeEnter: (to, from, next) => {
        const { companyStore } = useStore();

        companyStore.fetchAll({
          filter: 'serviceTypes||$notnull',
        });

        next();
      },
    },
  ],
};
