export function useAuth0() {
  const oauthRedirectUrl = `${process.env.VUE_APP_BASE_URL}/auth/callback`;
  const oauthQuery = new URLSearchParams({
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
    response_type: 'code',
    redirect_uri: oauthRedirectUrl,
    scope: 'openid profile email',
    audience: 'https://dev.payload.guide',
  });
  const oauthLink = `https://${process.env.VUE_APP_AUTH0_ORIGIN}/authorize?${oauthQuery}`;

  const googleAuthLink = `${oauthLink}&connection=google-oauth2`;
  const microsoftAuthLink = `${oauthLink}&connection=windowslive`;

  return {
    googleAuthLink,
    microsoftAuthLink,
  };
}
