<template>
  <section class="skeleton-profile">
    <div class="__avatar">
      <pp-skeleton-loader
        type="circle"
        style="min-width: 130px; min-height: 130px"
      />
      <pp-skeleton-loader type="h3" style="width: 200px; margin-left: 8px" />
    </div>

    <pp-skeleton-loader type="text" style="margin-bottom: 16px" />
    <pp-skeleton-loader type="text" style="margin-bottom: 16px" />
    <pp-skeleton-loader type="text" style="margin-bottom: 16px" />
    <pp-skeleton-loader type="text" />
  </section>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.__avatar {
  display: flex;
  align-items: center;
}
</style>
