<template>
  <div class="skeleton-request">
    <pp-row class="_center">
      <pp-skeleton-loader class="__title" type="h3" />
      <pp-spacer />
      <pp-skeleton-loader class="__btn" type="button" />
    </pp-row>

    <div class="__content">
      <pp-skeleton-loader class="__field _description" type="text" />
      <pp-skeleton-loader class="__field" type="text" />
      <pp-skeleton-loader class="__field" type="text" />
      <pp-skeleton-loader class="__field" type="text" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-request {
  background-color: $primary-white;
  margin-bottom: gap('md');
  padding: gap('xl');
  padding-bottom: gap('md');

  .__title {
    width: 110px;
  }

  .__btn {
    width: 172px;
  }

  .__field {
    margin: gap('lg') 0;
  }

  ._description {
    height: 52px;
  }

  .__content {
    margin-top: gap('md');
  }
}

.row._center {
  justify-content: center;
}
</style>
