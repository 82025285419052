import useModals from '@/composables/useModals';

import { ConfirmCredentialsModal } from '@/components/confirm-credentials';
import InteractionModal from '@/components/interaction-modal/interaction-modal.vue';
import AlertModal from '@/components/modals/alert.modal.vue';
import ConfirmCodeModal from '@/components/modals/confirm-code-modal.vue';
import InviteMemberModal from '@/components/modals/invite-member-modal.vue';
import ProjectGalleryModal from '@/components/project-gallery/project-gallery.modal.vue';
import ViewResponseModal from '@/components/response/view-response.modal.vue';
import { AddVideoLinkModal } from '@/components/video';
import ConfigurationModal from '@/views/architecture/subsystems/configuration/configuration-modal.vue';
import ExportControlModal from '@/views/architecture/subsystems/export-control/export-control-modal.vue';
import GroundStationModal from '@/views/architecture/subsystems/ground-station/ground-station-modal.vue';
import InsuranceModal from '@/views/architecture/subsystems/insurance/insurance-modal.vue';
import LicensingModal from '@/views/architecture/subsystems/licensing/licensing-modal.vue';
import OperationalAlgorithmModal from '@/views/architecture/subsystems/operational-algorithm/operational-algorithm.modal.vue';
import OrbitModal from '@/views/architecture/subsystems/orbit/orbit-modal.vue';
import PayloadModal from '@/views/architecture/subsystems/payload/payload-modal.vue';
import PropulsionSystemModal from '@/views/architecture/subsystems/propulsion-system/propulsion-system-modal.vue';
import { CompanyFundingModal, CompanyLocationModal } from '@/views/company';
import CreateCompanyModal from '@/views/company/create-company.modal.vue';
import ProviderInfoModal from '@/views/launches/provider-info-modal.vue';
import EditDetailsModal from '@/views/mission/modals/edit-details.modal.vue';
import EditGoalsModal from '@/views/mission/modals/edit-goals.modal.vue';
import EditPayloadTypeModal from '@/views/mission/modals/edit-payload-type.modal.vue';
import DeclineRequestModal from '@/views/request/modals/decline-request.modal.vue';
import InfoRequestModal from '@/views/request/modals/info-request.modal.vue';
import AcceptRequestModal from '@/views/request/modals/submit-response.modal.vue';
import SearchModal from '@/views/search/search-modal.vue';
import UpdateUserModal from '@/views/user/update-user.modal.vue';

export enum Modals {
  ADD_VIDEO_LINK_MODAL = 'add-video-link-modal',
  ALERT_MODAL = 'alert-modal',
  COMPANY_FUNDING_MODAL = 'company-funding-modal',
  COMPANY_LOCATION_MODAL = 'company-location-modal',
  CONFIGURATION_MODAL = 'configuration-modal',
  CONFIRM_CODE_MODAL = 'confirm-code-modal',
  CONFIRM_CREDENTIALS_MODAL = 'confirm-credentials-modal',
  CREATE_COMPANY = 'create-company-modal',
  DECLINE_REQUEST_MODAL = 'decline-request-modal',
  EDIT_DETAIL_MODAL = 'edit-detail-modal',
  EDIT_GOALS_MODAL = 'edit-goals-modal',
  EDIT_PAYLOAD_TYPE_MODAL = 'edit-payload-type-modal',
  EXPORT_CONTROL_MODAL = 'export-control-modal',
  GROUND_STATION_MODAL = 'ground-station-modal',
  INFO_REQUEST_MODAL = 'info-request-modal',
  INSURANCE_MODAL = 'insurance-modal',
  INTERACTION_MODAL = 'interaction-modal',
  INVITE_MEMBER_MODAL = 'invite-member-modal',
  LICENSING_MODAL = 'licensing-modal',
  OPERATIONAL_ALGORITHM_MODAL = 'operational-algorithm-modal',
  ORBIT_MODAL = 'orbit-modal',
  PAYLOAD_MODAL = 'payload-modal',
  PROJECT_GALLERY_MODAL = 'project-gallery-modal',
  PROPULSION_SYSTEM_MODAL = 'propulsion-system-modal',
  PROVIDER_INFO_MODAL = 'provider-info-modal',
  SEARCH_MODAL = 'search-modal',
  SUBMIT_RESPONSE_MODAL = 'submit-response-modal',
  UPDATE_USER_MODAL = 'update-user-modal',
  VIEW_RESPONSE_MODAL = 'view-response-modal',
}

export function initModals(): void {
  const { registerModal } = useModals();

  registerModal(Modals.ADD_VIDEO_LINK_MODAL, AddVideoLinkModal);
  registerModal(Modals.ALERT_MODAL, AlertModal);
  registerModal(Modals.COMPANY_FUNDING_MODAL, CompanyFundingModal);
  registerModal(Modals.COMPANY_LOCATION_MODAL, CompanyLocationModal);
  registerModal(Modals.CONFIGURATION_MODAL, ConfigurationModal);
  registerModal(Modals.CONFIRM_CODE_MODAL, ConfirmCodeModal);
  registerModal(Modals.CONFIRM_CREDENTIALS_MODAL, ConfirmCredentialsModal);
  registerModal(Modals.CREATE_COMPANY, CreateCompanyModal);
  registerModal(Modals.DECLINE_REQUEST_MODAL, DeclineRequestModal);
  registerModal(Modals.EDIT_DETAIL_MODAL, EditDetailsModal);
  registerModal(Modals.EDIT_GOALS_MODAL, EditGoalsModal);
  registerModal(Modals.EDIT_PAYLOAD_TYPE_MODAL, EditPayloadTypeModal);
  registerModal(Modals.EXPORT_CONTROL_MODAL, ExportControlModal);
  registerModal(Modals.GROUND_STATION_MODAL, GroundStationModal);
  registerModal(Modals.INFO_REQUEST_MODAL, InfoRequestModal);
  registerModal(Modals.INSURANCE_MODAL, InsuranceModal);
  registerModal(Modals.INTERACTION_MODAL, InteractionModal);
  registerModal(Modals.INVITE_MEMBER_MODAL, InviteMemberModal);
  registerModal(Modals.LICENSING_MODAL, LicensingModal);
  registerModal(Modals.OPERATIONAL_ALGORITHM_MODAL, OperationalAlgorithmModal);
  registerModal(Modals.ORBIT_MODAL, OrbitModal);
  registerModal(Modals.PAYLOAD_MODAL, PayloadModal);
  registerModal(Modals.PROJECT_GALLERY_MODAL, ProjectGalleryModal);
  registerModal(Modals.PROPULSION_SYSTEM_MODAL, PropulsionSystemModal);
  registerModal(Modals.PROVIDER_INFO_MODAL, ProviderInfoModal);
  registerModal(Modals.SEARCH_MODAL, SearchModal);
  registerModal(Modals.SUBMIT_RESPONSE_MODAL, AcceptRequestModal);
  registerModal(Modals.UPDATE_USER_MODAL, UpdateUserModal);
  registerModal(Modals.VIEW_RESPONSE_MODAL, ViewResponseModal);
}
