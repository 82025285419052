import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8d309b78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__insurance-title" }
const _hoisted_2 = { class: "__insurance-name" }
const _hoisted_3 = {
  key: 0,
  class: "__value"
}
const _hoisted_4 = {
  key: 1,
  class: "__value"
}
const _hoisted_5 = {
  key: 2,
  class: "__value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_small_button = _resolveComponent("pp-small-button")!
  const _component_pp_chip = _resolveComponent("pp-chip")!
  const _component_pp_labeled_value = _resolveComponent("pp-labeled-value")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_spacer = _resolveComponent("pp-spacer")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card_header, null, {
        default: _withCtx(() => [
          _createTextVNode("Insurance")
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.architecture.insurance, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.id
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(item.name), 1),
            _createVNode(_component_pp_small_button, {
              title: "Edit",
              onClick: ($event: any) => (_ctx.showInsuranceModal(item))
            }, null, 8, ["onClick"])
          ]),
          _createVNode(_component_pp_card_body, { class: "subsystem-item" }, {
            default: _withCtx(() => [
              (item.type)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Insurance type",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.type), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : _createCommentVNode("", true),
              (item.amount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Sum insured",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.amount) + " " + _toDisplayString(item.currency), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : _createCommentVNode("", true),
              (item.contractor)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_pp_labeled_value, {
                      label: "Contractor",
                      inline: "",
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pp_chip, { color: "light-blue" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.contractor), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : _createCommentVNode("", true),
              (item.status)
                ? (_openBlock(), _createBlock(_component_pp_labeled_value, {
                    key: 3,
                    label: "Status",
                    vertical: "",
                    class: "__value"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.status), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ], 64))
      }), 128)),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_pp_card_actions, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "outline",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showInsuranceModal({}))),
                title: "Add new insurance"
              }),
              _createVNode(_component_pp_spacer)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}