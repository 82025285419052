type IDebouncedFunction<T extends (...args: Parameters<T>) => void> = (
  ...args: Parameters<T>
) => void;

/**
 * This function cannot use function types with a return statement in generic
 */
export const debounce = <T extends (...args: Parameters<T>) => void>(
  fn: T,
  delay = 0,
  immediate = false,
): IDebouncedFunction<T> => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>): void => {
    if (immediate && !timeout) {
      fn(...args);
    }

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
