import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_col = _resolveComponent("pp-col")!
  const _component_pp_row = _resolveComponent("pp-row")!
  const _component_ground_station_item = _resolveComponent("ground-station-item")!
  const _component_orbit_item = _resolveComponent("orbit-item")!
  const _component_configuration_item = _resolveComponent("configuration-item")!
  const _component_payload_item = _resolveComponent("payload-item")!
  const _component_propulsion_system_item = _resolveComponent("propulsion-system-item")!
  const _component_operational_algorithm_item = _resolveComponent("operational-algorithm-item")!
  const _component_export_control_item = _resolveComponent("export-control-item")!
  const _component_licensing_item = _resolveComponent("licensing-item")!
  const _component_insurance_item = _resolveComponent("insurance-item")!

  return (_openBlock(), _createBlock(_component_pp_row, { class: "architecture" }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_col, {
        cols: "12",
        sm: "6",
        lg: "4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_pp_row, { class: "__column-title" }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_col, { class: "__arch-level-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Mission level")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ground_station_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"]),
          _createVNode(_component_orbit_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"])
        ]),
        _: 1
      }),
      _createVNode(_component_pp_col, {
        cols: "12",
        sm: "6",
        lg: "4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_pp_row, { class: "__column-title" }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_col, { class: "__arch-level-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Spacecraft level")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_configuration_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"]),
          _createVNode(_component_payload_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"]),
          _createVNode(_component_propulsion_system_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"]),
          _createVNode(_component_operational_algorithm_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"])
        ]),
        _: 1
      }),
      _createVNode(_component_pp_col, {
        cols: "12",
        sm: "6",
        lg: "4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_pp_row, { class: "__column-title" }, {
            default: _withCtx(() => [
              _createVNode(_component_pp_col, { class: "__arch-level-title" }, {
                default: _withCtx(() => [
                  _createTextVNode("Service level")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_export_control_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"]),
          _createVNode(_component_licensing_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"]),
          _createVNode(_component_insurance_item, {
            architecture: _ctx.architecture,
            readonly: _ctx.readonly,
            class: "__subsystem-card"
          }, null, 8, ["architecture", "readonly"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}