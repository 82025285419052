import {
  IResponseDecline,
  IView,
  IViewStoreState,
  IResponseSubmit,
} from '@/common/interfaces';
import { defineStore } from 'pinia';

export const useViewsStore = defineStore('view', {
  state: (): IViewStoreState => ({
    loading: false,
    error: null,
    list: [],
    count: 0,
    limit: 100,
  }),

  getters: {
    getByRequestId: (state: IViewStoreState) => (id: string) =>
      state.list.find((v) => v.request?.id === id),
  },

  actions: {
    async fetchAll() {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.views.fetchAll();
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(viewId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const view = await this.$api.views.fetchOne(viewId);
        this.setList([view]);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async submit(id: string, response: IResponseSubmit) {
      const view = await this.$api.views.submit(id, response);
      this.update(view);
    },

    async askDetails(id: string, data: IResponseDecline) {
      const view = await this.$api.views.askDetails(id, data);
      this.update(view);
    },

    async decline(id: string, data: IResponseDecline) {
      const view = await this.$api.views.decline(id, data);
      this.update(view);
    },

    async revoke(id: string) {
      const view = await this.$api.views.revoke(id);
      this.update(view);
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: IView[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    update(item: IView) {
      const index = this.list.findIndex((view) => view.id === item.id);
      if (index > -1) {
        this.list[index] = item;
      } else {
        this.list.push(item);
      }
    },

    reset() {
      this.$reset();
    },
  },
});
