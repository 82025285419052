import { IUserProfile } from '@/common/interfaces';
import { RequestManager } from '@/utils';

export class UserApi {
  async fetch() {
    const response = await RequestManager.get<IUserProfile>('/user/profile');
    return response.data;
  }

  async update(data: Partial<IUserProfile>) {
    const response = await RequestManager.patch<IUserProfile>(
      '/user/profile',
      data,
    );
    return response.data;
  }

  async saveLaunches(launchIds: string[]) {
    const response = await RequestManager.put<IUserProfile>(
      '/user/launches',
      launchIds,
    );
    return response.data;
  }

  async impersonate(companyId: string) {
    const response = await RequestManager.post<IUserProfile>(
      `/admin/user/impersonate/${companyId}`,
    );
    return response.data;
  }

  async unimpersonate() {
    const response = await RequestManager.post<IUserProfile>(
      `/admin/user/unimpersonate`,
    );
    return response.data;
  }
}
