import {
  ArchitectureApi,
  AuthApi,
  CompanyApi,
  InviteApi,
  LaunchesApi,
  LaunchesDraftApi,
  MissionApi,
  PadsApi,
  RequestApi,
  SubsystemApi,
  UserApi,
  UsersApi,
  VehiclesApi,
  ViewsApi,
} from '@/api';

import { UserRole } from '@/common/enums';
import { PiniaPluginContext } from 'pinia';

export const api = {
  auth: new AuthApi(),
  user: new UserApi(),
  invite: new InviteApi(),
  pads: new PadsApi(UserRole.User),
  users: new UsersApi(UserRole.User),
  views: new ViewsApi(UserRole.User),
  company: new CompanyApi(UserRole.User),
  missions: new MissionApi(UserRole.User),
  requests: new RequestApi(UserRole.User),
  launches: new LaunchesApi(UserRole.User),
  vehicles: new VehiclesApi(UserRole.User),
  subsystems: new SubsystemApi(UserRole.User),
  architecture: new ArchitectureApi(UserRole.User),
  launchesDraft: new LaunchesDraftApi(UserRole.User),
};

export function apiPiniaPlugin({ store }: PiniaPluginContext) {
  function setRole(role: UserRole) {
    Object.assign(api, {
      pads: new PadsApi(role),
      users: new UsersApi(role),
      views: new ViewsApi(role),
      company: new CompanyApi(role),
      missions: new MissionApi(role),
      requests: new RequestApi(role),
      launches: new LaunchesApi(role),
      vehicles: new VehiclesApi(role),
      subsystems: new SubsystemApi(role),
      architecture: new ArchitectureApi(role),
      launchesDraft: new LaunchesDraftApi(role),
    });
  }

  store.$api = api;
  store.$setRole = setRole;
}
