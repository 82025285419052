import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62b75e61"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__list-actions" }
const _hoisted_2 = { class: "__actions" }
const _hoisted_3 = { class: "__requests" }
const _hoisted_4 = { class: "__request-amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_company = _resolveComponent("skeleton-company")!
  const _component_company_team = _resolveComponent("company-team")!
  const _component_pp_markdown_editor = _resolveComponent("pp-markdown-editor")!
  const _component_company_details = _resolveComponent("company-details")!
  const _component_color_divider = _resolveComponent("color-divider")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_skeleton_table_list = _resolveComponent("skeleton-table-list")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_tabs_panel = _resolveComponent("tabs-panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.companyIsLoading || !_ctx.company)
      ? (_openBlock(), _createBlock(_component_skeleton_company, { key: 0 }))
      : (_openBlock(), _createBlock(_component_company_details, {
          key: 1,
          company: _ctx.company,
          serviceTypesVisible: true,
          onUpdate: _ctx.updateCompany,
          class: "__company-details"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_company_team, {
              "company-id": _ctx.company.id,
              members: _ctx.members,
              error: _ctx.membersError,
              class: "__company-team"
            }, null, 8, ["company-id", "members", "error"]),
            _createVNode(_component_pp_markdown_editor, {
              "model-value": _ctx.company.news,
              label: "News",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => _ctx.updateCompanyField('news', value))
            }, null, 8, ["model-value"])
          ]),
          _: 1
        }, 8, ["company", "onUpdate"])),
    _createVNode(_component_color_divider),
    _createVNode(_component_tabs_panel, {
      tabList: _ctx.tabList,
      class: "__lists"
    }, {
      missions: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_pp_button, { onClick: _ctx.createMission }, {
            default: _withCtx(() => [
              _createTextVNode("Create mission")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        (_ctx.missionListIsLoading)
          ? (_openBlock(), _createBlock(_component_skeleton_table_list, { key: 0 }))
          : (_openBlock(), _createBlock(_component_custom_table, {
              key: 1,
              data: _ctx.missionTableData
            }, {
              [`item.createdBy`]: _withCtx(({ item }) => [
                (item.createdBy)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: {
              name: 'admin-one-user',
              params: { userId: item.createdBy?.id },
            },
                      class: "_truncated"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.createdBy?.id), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true)
              ]),
              [`item.actions`]: _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_pp_button, {
                    onClick: ($event: any) => (_ctx.showItem(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("View")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_pp_button, {
                    type: "error",
                    onClick: ($event: any) => (_ctx.removeMission(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Delete ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]),
              [`item.requests`]: _withCtx(({ item }) => [
                _createElementVNode("span", _hoisted_3, [
                  _createTextVNode(" Requests "),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.requests?.length || 0), 1)
                ])
              ]),
              _: 2
            }, 1032, ["data"]))
      ]),
      _: 1
    }, 8, ["tabList"])
  ], 64))
}