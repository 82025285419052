import { ApiRolesBasis } from '@/api/base.api';
import { UserRole } from '@/common/enums';

import { RequestManager } from '@/utils';

export class PadsApi extends ApiRolesBasis {
  constructor(role: UserRole) {
    super('pad', role);
  }

  async fetchNames() {
    const response = await RequestManager.get(`${this.path}/names`);
    return response.data;
  }

  async fetchLocations() {
    const response = await RequestManager.get(`${this.path}/locations`);
    return response.data;
  }
}
