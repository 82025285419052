import Settings from './settings.page.vue';
import Profile from './profile.page.vue';
import Terms from './terms.page.vue';
import { RouteRecordRaw } from 'vue-router';

export const SettingsRoutes: RouteRecordRaw = {
  path: '/settings',
  name: 'settings',
  component: Settings,
  redirect: { name: 'profile' },
  children: [
    {
      path: 'profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: 'terms',
      name: 'terms',
      component: Terms,
    },
  ],
};
