export function emptyStringToUndefined(
  data: Record<string, unknown>,
): Record<string, unknown> {
  for (const item in data) {
    if (data[item] === '') {
      data[item] = undefined;
    }
  }

  return data;
}
