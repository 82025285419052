import { BaseApi } from '@/api/base.api';
import { UserRole } from '@/common/enums';
import { ILaunch, ILaunchDraft } from '@/common/interfaces';
import { RequestManager } from '@/utils';

export class LaunchesDraftApi extends BaseApi<ILaunchDraft> {
  constructor(role: UserRole) {
    super('launch-draft', role);
  }

  async apply(draftId: string): Promise<ILaunch> {
    const response = await RequestManager.post<ILaunch>(
      `${this.path}/${draftId}/apply`,
    );

    return response.data;
  }
}
