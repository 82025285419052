export enum LaunchOrbitInclinationType {
  EQUATORIAL = 'Equatorial',
  LOW = 'Low Incl.',
  MID = 'Mid Incl.',
  /** International Space Station, specified as 51.6° */
  ISS = 'ISS',
  /** Sun Synchronous, specified around 98° */
  SSO = 'SSO',
  POLAR = 'Polar',
}
