
import { computed, defineComponent, toRef } from 'vue';
import { IUserProfile } from '@/common/interfaces';
import avatar from '../avatar.vue';

export default defineComponent({
  components: { avatar },
  name: 'profile-avatar',
  props: {
    profile: {
      type: Object as () => IUserProfile,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:profile'],
  setup(props, { emit }) {
    const profile = toRef(props, 'profile');
    const label = computed<string>(() => {
      if (!props.profile) {
        return '';
      }
      const { name, surname } = props.profile;
      return [name, surname]
        .map((str) => (str ? str[0] : ''))
        .join('')
        .toUpperCase();
    });

    function handleUpdate(avatarUrl: string) {
      const newProfile = JSON.parse(JSON.stringify(profile.value));
      newProfile.metadata.avatarUrl = avatarUrl;
      emit('update:profile', newProfile);
    }

    return {
      label,
      handleUpdate,
    };
  },
});
