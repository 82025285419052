import { BaseApi } from '@/api/base.api';
import { defaultFetchAllParams } from '@/common/const';
import { UserRole } from '@/common/enums';
import { IFetchAllParams, ILaunch, Paginated } from '@/common/interfaces';
import { RequestManager } from '@/utils';

export class LaunchesApi extends BaseApi<ILaunch> {
  constructor(role: UserRole) {
    super('launch', role);
  }

  async fetchAllPublic(
    params: IFetchAllParams = defaultFetchAllParams,
  ): Promise<Paginated<ILaunch>> {
    const secret = '5dbf0d1a44c444f6996c272eee3ade47';
    const response = await RequestManager.get<Paginated<ILaunch>>(
      `${this.path}/${secret}`,
      {
        params,
      },
    );
    return response.data;
  }
}
