
import { onMounted } from 'vue';
import { IHeadway } from '@/common/interfaces';

export default {
  name: 'app-headway',
  setup() {
    onMounted(() => {
      initHeadway();
    });

    function initHeadway(): void {
      const accountKey = process.env.VUE_APP_HEADWAY;

      if (!accountKey) {
        throw new Error('Add a headway account key to .env');
      }

      if ('Headway' in window) {
        const { Headway } = window;

        (Headway as IHeadway).init({
          selector: '.app-headway',
          account: accountKey,
          trigger: '.__headway-anchor',
        });
      }
    }
  },
};
