import {
  IFetchAllParams,
  IRequest,
  IRequestStoreState,
  IShareRequest,
} from '@/common/interfaces';
import { MixpanelActions, useMixpanel } from '@/services/analytics';
import { defineStore } from 'pinia';
import { UpdateRequestDto } from '@/common/interfaces/request/update-request.dto';

const { track } = useMixpanel();

export const useRequestsStore = defineStore('request', {
  state: (): IRequestStoreState => ({
    list: [],
    count: 0,
    limit: 100,
    error: null,
    loading: false,
  }),

  getters: {
    getById: (state) => (id: string) =>
      state.list.find((request: IRequest) => request.id === id),
  },

  actions: {
    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.requests.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.requests.fetchOne(requestId);

        const index = this.list.findIndex((el) => el.id === requestId);

        if (index > -1) {
          this.updateByIndex({
            index,
            request: response,
          });
        } else {
          this.add(response);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async create(data: Partial<IRequest>) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.requests.create(data);
        this.add(response);
        track(MixpanelActions.CREATE_REQUEST_DONE, response);
        return response;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<UpdateRequestDto>) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.requests.update(data);
        this.setData(response);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async remove(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        await this.$api.requests.delete(requestId);
        const index = this.list.findIndex((el) => el.id === requestId);

        if (index !== -1) {
          this.list.splice(index, 1);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async share(data: IShareRequest) {
      try {
        await this.$api.requests.share(data);
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async withdraw(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.requests.withdraw(requestId);
        this.setData(response);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async resend(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.requests.resend(requestId);
        this.setData(response);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: IRequest[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    setData(data: IRequest) {
      const index = this.list.findIndex((request) => request.id === data.id);

      if (index !== -1) {
        this.list[index] = data;
      }
    },

    add(data: IRequest) {
      this.list.push(data);
    },

    updateByIndex(data: { index: number; request: IRequest }) {
      this.list[data.index] = data.request;
    },

    reset() {
      this.$reset();
    },
  },
});
