
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { ILevelSelectorOption } from './level-selector-option.interface';

export default defineComponent({
  name: 'level-selector',

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    options: {
      type: Array as PropType<Array<ILevelSelectorOption>>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);

    const selectedValue = computed(() =>
      options.value.find((option) => option.value === modelValue.value),
    );

    function onChange(value: number) {
      emit('update:modelValue', value);
    }

    return {
      selectedValue,
      onChange,
    };
  },
});
