import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { ILaunch } from '@/common/interfaces';
import { useLaunchesStore } from '@/stores/launches';

/**
 * Get launch ids from url query, fetch from API and save to localStorage.
 * @example URL: `?launch=1&launch=2&launch=3...`
 */
export function useQueryLaunches() {
  const route = useRoute();
  const launchesStore = useLaunchesStore();

  const _launchIds = ref<string[]>([]);
  const launches = ref<ILaunch[]>([]);

  const launchIds = computed<string[]>({
    get: () => _launchIds.value,
    set: (ids: string[]) => {
      _launchIds.value = [...ids];
      storeLaunches(ids);
    },
  });

  async function fetchLaunches() {
    try {
      if (launchIds.value.length) {
        launches.value = await launchesStore.fetchAllPublic({
          filter: `id||$in||${launchIds.value}`,
        });
      }
    } catch (err) {
      console.error(err);
    }

    return launches.value;
  }

  function hasLaunchesStored() {
    return !!localStorage.getItem('launches');
  }

  function storeLaunches(ids: string[]) {
    ids = ids.filter(Boolean);
    if (!ids.length) {
      return cleanStore();
    }
    localStorage.setItem('launches', JSON.stringify(ids));
  }

  function restoreLaunches(): string[] {
    let result: string[] = [];

    try {
      result = JSON.parse(localStorage.getItem('launches') as string) || [];
    } catch (err) {
      console.error(`Failed to restore launches from localStorage:`, err);
    }

    return result;
  }

  function cleanStore() {
    localStorage.removeItem('launches');
  }

  onMounted(() => {
    let ids: string[] = [];

    if (route.query.launch) {
      const _ids = Array.isArray(route.query.launch)
        ? route.query.launch
        : [route.query.launch];

      ids = _ids.filter(Boolean) as string[];
    } else if (localStorage.getItem('launches')) {
      ids = restoreLaunches();
    }

    if (ids.length) {
      launchIds.value = ids;
      fetchLaunches();
    }
  });

  return {
    launches,
    fetchLaunches,
    hasLaunchesStored,
    storeLaunches,
    restoreLaunches,
    cleanStore,
  };
}
