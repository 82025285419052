<template>
  <div class="skeleton-launch-form">
    <div class="__item" v-for="i in 10" :key="i">
      <pp-skeleton-loader class="__title" type="plain" />
      <pp-skeleton-loader class="__field" type="plain" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-launch-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 575px;
  width: 100%;
  @include gap('md');
}

.__item {
  width: 100%;
}

.__title {
  width: 100px;
  height: 16px;
  margin-bottom: 4px;
}

.__field {
  height: 32px;
}
</style>
