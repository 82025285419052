import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!

  return (_openBlock(), _createBlock(_component_avatar, {
    class: _normalizeClass(["company-avatar", `size-${_ctx.size}`]),
    modelValue: _ctx.company.avatarUrl,
    label: _ctx.label,
    editable: _ctx.editable,
    "onUpdate:modelValue": _ctx.handleUpdate
  }, null, 8, ["class", "modelValue", "label", "editable", "onUpdate:modelValue"]))
}