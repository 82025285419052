import { RouteRecordRaw } from 'vue-router';
import MissionPage from './mission.page.vue';
import ViewMissionPage from './view-mission.page.vue';
import CreateMissionPage from './create-mission.page.vue';
import { MixpanelActions, useMixpanel } from '@/services';
import { useStore } from '@/stores';

const { track } = useMixpanel();

export const MissionRoutes: RouteRecordRaw = {
  path: '/mission',
  component: MissionPage,
  children: [
    {
      path: '/mission/:missionId',
      name: 'mission',
      component: ViewMissionPage,
      beforeEnter: (to, from, next) => {
        const { missionsStore, architectureStore } = useStore();

        const missionId = to.params.missionId as string;

        missionsStore.fetchOne(missionId);
        architectureStore.fetchForMission(missionId);
        next();
      },
    },
    {
      path: 'create',
      name: 'create-mission',
      component: CreateMissionPage,
      beforeEnter: (to, from, next) => {
        track(MixpanelActions.CREATE_MISSION_PAGE_OPENED);
        next();
      },
    },
  ],
};
