
import { ICompany } from '@/common/interfaces';
import { computed, defineComponent, PropType, toRef } from 'vue';
import avatar from '../avatar.vue';
import { CompanyAvatarSize } from './enums/company-avatar';

export default defineComponent({
  components: { avatar },
  name: 'company-avatar',
  props: {
    company: {
      type: Object as PropType<ICompany>,
      required: true,
    },
    size: {
      type: String as PropType<CompanyAvatarSize>,
      default: CompanyAvatarSize.Default,
    },
    editable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['update:company'],
  setup(props, { emit }) {
    const company = toRef(props, 'company');
    const label = computed<string>(() => {
      if (!props.company) {
        return '';
      }
      return (props.company?.name || '')
        .split(/\s/)
        .map((str) => str[0])
        .join('')
        .substring(0, 2)
        .toUpperCase();
    });

    function handleUpdate(avatarUrl: string) {
      emit('update:company', {
        ...company.value,
        avatarUrl,
      });
    }

    return {
      label,
      handleUpdate,
    };
  },
});
