import { RouteRecordRaw } from 'vue-router';

import { MixpanelActions, useMixpanel } from '@/services';
import { useStore } from '@/stores';

import Request from './request.page.vue';
import Requests from './requests.page.vue';
import CreateRequest from './create-request.page.vue';
import IncomingListRequestsPage from './incoming-list-requests.page.vue';
import IncomingOneRequestPage from './incoming-one-request.page.vue';
import OneRequestPage from '@/routes/request/one-request-page.vue';

const { track } = useMixpanel();

export const RequestRoutes: RouteRecordRaw = {
  path: '/request',
  component: Request,
  children: [
    {
      path: '/requests',
      name: 'requests',
      component: Requests,
      beforeEnter: (_to, _from, next) => {
        const { requestsStore } = useStore();

        requestsStore.fetchAll();

        track(MixpanelActions.REQUESTS_PAGE_OPENED);
        next();
      },
    },
    {
      path: '/incoming-requests',
      name: 'incoming-requests',
      component: IncomingListRequestsPage,
      beforeEnter: (to, from, next) => {
        const { viewsStore } = useStore();

        viewsStore.fetchAll();

        next();
      },
    },
    {
      path: '/request/:requestId/view/:viewId',
      name: 'incoming-request-detail',
      component: IncomingOneRequestPage,
      beforeEnter: async (to, _from, next) => {
        const { companyStore, missionsStore, architectureStore, viewsStore } =
          useStore();

        const requestId = to.params.requestId as string;
        const viewId = to.params.viewId as string;

        await viewsStore.fetchOne(viewId);
        companyStore.fetchForRequest(requestId);
        missionsStore.fetchForRequest(requestId);
        architectureStore.fetchForRequest(requestId);

        next();
      },
    },
    {
      path: '/request/:requestId',
      name: 'edit-request',
      component: OneRequestPage,
      beforeEnter: (to, from, next) => {
        const { requestsStore, missionsStore } = useStore();

        const requestId = to.params.requestId as string;

        requestsStore.fetchOne(requestId);
        missionsStore.fetchAll();

        next();
      },
    },
    {
      path: 'create',
      name: 'create-request',
      component: CreateRequest,
      beforeEnter: (to, from, next) => {
        track(MixpanelActions.CREATE_REQUEST_PAGE_OPENED);
        next();
      },
    },
  ],
};
