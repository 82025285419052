
import { SubsystemType } from '@/common/enums';
import { Currency } from '@/common/enums/currency';
import { InsuranceContractor, InsuranceType } from '@/common/enums/insurance';
import { IArchitecture, IInsurance } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { defineComponent, PropType, ref, toRef, watchEffect } from 'vue';
import SumField from '@/components/fields/sum-field.vue';
import { useStore } from '@/stores';

export default defineComponent({
  components: {
    SumField,
  },

  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },

    insurance: {
      type: Object as () => Partial<IInsurance>,
      required: true,
    },
  },

  setup(props) {
    const { hideModal } = useModals();
    const { architectureStore } = useStore();

    const insuranceType = Object.values(InsuranceType);
    const contractors = Object.values(InsuranceContractor);

    const insurance = toRef(props, 'insurance');
    const architecture = toRef(props, 'architecture');

    const data = ref<Partial<IInsurance>>({});

    const agreedWithDelete = ref(false);

    watchEffect(() => {
      data.value = {
        currency: Currency.USD,
        ...insurance.value,
      };

      agreedWithDelete.value = false;
    });

    function close() {
      agreedWithDelete.value = false;

      hideModal(Modals.INSURANCE_MODAL);
    }

    function saveItem() {
      architectureStore.updateSubsystem({
        subsystem: SubsystemType.INSURANCE,
        subsystemId: insurance.value.id,
        architectureId: architecture.value.id,
        data: data.value,
      });

      close();
    }

    function deleteItem() {
      if (agreedWithDelete.value) {
        architectureStore.deleteSubsystem({
          subsystem: SubsystemType.INSURANCE,
          subsystemId: insurance.value.id,
          architectureId: architecture.value.id,
        });
        close();
      } else {
        agreedWithDelete.value = true;
      }
    }

    return {
      insuranceType,
      contractors,
      data,
      agreedWithDelete,
      close,
      saveItem,
      deleteItem,
    };
  },
});
