
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup() {
    const types = {
      info: {
        icon: 'help',
      },
      success: {
        icon: 'check-circle',
      },
      warning: {
        icon: 'alert',
      },
      error: {
        icon: 'alert',
      },
    };
    return {
      types,
    };
  },
});
