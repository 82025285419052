
import { GroundStationType, SubsystemType } from '@/common/enums';
import { IArchitecture, IGroundStation } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { defineComponent, PropType, ref, toRef, watch } from 'vue';
import { AllowedMarkdownTags } from '@/common/const/allowed-markdown-tags';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
  },

  setup(props) {
    const { architectureStore } = useStore();
    const { hideModal } = useModals();

    const architecture = toRef(props, 'architecture');

    const types = Object.values(GroundStationType);

    const groundStation = ref<IGroundStation>({
      ...architecture.value.groundStation,
    });

    watch(architecture, (changes) => {
      groundStation.value = changes.groundStation ?? '';
    });

    function close() {
      hideModal(Modals.GROUND_STATION_MODAL);
    }

    function handleSave() {
      architectureStore.updateSubsystem({
        subsystem: SubsystemType.GROUND_STATION,
        subsystemId: architecture.value.groundStation?.id,
        architectureId: architecture.value.id,
        data: groundStation.value,
      });

      close();
    }

    return {
      types,
      groundStation,
      AllowedMarkdownTags,
      close,
      handleSave,
    };
  },
});
