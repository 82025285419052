import { ILaunch } from '@/common/interfaces';
import { IAnalyticsList } from '@/common/types/analytics.type';
import { formatLaunchDate } from '@/utils';

export interface IAnalyticsLaunchData {
  launch_name: string;
  date: string;
  orbit: string;
  provider_name: string;
  vehicle: string;
}

export function useLaunchAnalytics() {
  /** Convert parameters for analytics into json object */
  function serialize(launch: ILaunch): IAnalyticsLaunchData | null;
  function serialize(launches: ILaunch[]): IAnalyticsList<IAnalyticsLaunchData>;
  function serialize(
    launch: ILaunch | ILaunch[],
  ): IAnalyticsLaunchData | IAnalyticsList<IAnalyticsLaunchData> | null {
    if (Array.isArray(launch)) {
      return serializeLaunches(launch);
    }

    return serializeLaunch(launch);
  }

  function serializeLaunch(launch: ILaunch): IAnalyticsLaunchData | null {
    if (!launch) {
      return null;
    }

    const { name, launchDate, vehicle, orbit } = launch;
    const dateString = formatLaunchDate(launchDate);
    const orbitString = orbit
      ? [orbit.altitudeType, orbit.inclinationType].filter((v) => v).join(' ')
      : '';

    return {
      launch_name: name,
      date: dateString,
      orbit: orbitString,
      provider_name: vehicle?.company?.name ?? '',
      vehicle: vehicle?.name ?? '',
    };
  }

  function serializeLaunches(
    launches: ILaunch[],
  ): IAnalyticsList<IAnalyticsLaunchData> {
    const list = launches
      .map(serializeLaunch)
      .filter(Boolean) as IAnalyticsLaunchData[];

    return {
      count: list.length,
      list,
    };
  }

  return {
    serialize,
  };
}
