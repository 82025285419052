
import { ICompany } from '@/common/interfaces';
import { defineComponent, PropType, reactive, toRef } from 'vue';
import { countries } from '@/common/const';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    company: {
      type: Object as PropType<ICompany>,
      required: true,
    },
  },

  setup(props) {
    const { companyStore, userStore } = useStore();
    const { hideModal } = useModals();

    const company = toRef(props, 'company');

    const data = reactive<Partial<ICompany>>({
      id: company.value.id,
      city: company.value.city,
      country: company.value.country,
    });

    function close(): void {
      hideModal(Modals.COMPANY_LOCATION_MODAL);
    }

    async function update(): Promise<void> {
      const response = await companyStore.update(data);

      userStore.setData({
        ...userStore.data,
        company: response,
      });
      close();
    }

    return {
      data,
      countries,
      close,
      update,
    };
  },
});
