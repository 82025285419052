import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27a83590"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__title" }
const _hoisted_2 = {
  key: 0,
  class: "__items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_project_gallery_card = _resolveComponent("project-gallery-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.label), 1),
      (!_ctx.readonly)
        ? (_openBlock(), _createBlock(_component_pp_button, {
            key: 0,
            type: "outline",
            title: "Upload",
            onClick: _ctx.showProjectGalleryModal
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_pp_icon, { name: "plus" })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.images?.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, idx) => {
            return (_openBlock(), _createBlock(_component_project_gallery_card, {
              key: idx,
              file: img
            }, null, 8, ["file"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}