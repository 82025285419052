import { IResponseDecline, IResponseSubmit, IView } from '@/common/interfaces';
import { RequestManager } from '@/utils';
import { UserRole } from '@/common/enums';
import { BaseApi } from './base.api';

export class ViewsApi extends BaseApi<IView> {
  constructor(role: UserRole) {
    super('views', role);
  }

  async submit(id: string, dto: IResponseSubmit): Promise<IView> {
    const { data } = await RequestManager.post<IView>(
      `${this.path}/${id}/submit`,
      dto,
    );

    return data;
  }

  async askDetails(id: string, dto: IResponseDecline): Promise<IView> {
    const { data } = await RequestManager.post<IView>(
      `${this.path}/${id}/ask-details`,
      dto,
    );

    return data;
  }

  async decline(id: string, dto: IResponseDecline): Promise<IView> {
    const { data } = await RequestManager.post<IView>(
      `${this.path}/${id}/decline`,
      dto,
    );

    return data;
  }

  async revoke(id: string): Promise<IView> {
    const { data } = await RequestManager.post<IView>(
      `${this.path}/${id}/revoke`,
    );

    return data;
  }
}
