import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40af8da7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "__label"
}
const _hoisted_2 = {
  key: 1,
  class: "__progress"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_progress_circular = _resolveComponent("pp-progress-circular")!
  const _component_pp_icon = _resolveComponent("pp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar", { _transparent: _ctx.backgroundImage }]),
    style: _normalizeStyle({ backgroundImage: _ctx.backgroundImage })
  }, [
    (!_ctx.backgroundImage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_pp_progress_circular, {
            value: _ctx.loadingProgress,
            indeterminate: _ctx.loadingProgress === 1
          }, null, 8, ["value", "indeterminate"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.editable && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "__add",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFileDialog && _ctx.openFileDialog(...args)))
        }, [
          _createVNode(_component_pp_icon, { name: "add" })
        ]))
      : _createCommentVNode("", true)
  ], 6))
}