import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_skeleton_loader = _resolveComponent("pp-skeleton-loader")!
  const _component_pp_select = _resolveComponent("pp-select")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    "max-width": "800"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_header, null, {
            default: _withCtx(() => [
              _createTextVNode("Edit user " + _toDisplayString(_ctx.user.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_body, null, {
            default: _withCtx(() => [
              (_ctx.companiesIsLoading)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_pp_skeleton_loader, {
                      type: "text",
                      style: {"width":"30%"}
                    }),
                    _createVNode(_component_pp_skeleton_loader, { type: "h3" })
                  ], 64))
                : (_openBlock(), _createBlock(_component_pp_select, {
                    key: 1,
                    modelValue: _ctx.data.companyId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.companyId) = $event)),
                    options: _ctx.companies,
                    label: "Company",
                    "label-prop": "name",
                    "value-prop": "id",
                    searchable: ""
                  }, null, 8, ["modelValue", "options"]))
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.companiesIsLoading)
                ? (_openBlock(), _createBlock(_component_pp_skeleton_loader, {
                    key: 0,
                    type: "button"
                  }))
                : (_openBlock(), _createBlock(_component_pp_button, {
                    key: 1,
                    onClick: _ctx.save
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Save")
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}