
import { defineComponent } from 'vue';
import { ref } from 'vue';
import { IMission } from '@/common/interfaces';
import { MissionGoals } from '@/common/enums';
import { Modals } from '@/modals';
import useModals from '@/composables/useModals';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    mission: {
      type: Object as () => IMission,
      required: true,
    },
  },

  setup(props) {
    const { hideModal } = useModals();
    const { missionsStore } = useStore();

    const missionGoals = Object.values(MissionGoals);

    const goals = ref(
      props.mission.goals ? [...props.mission.goals].filter((v) => !!v) : [],
    );

    function close() {
      hideModal(Modals.EDIT_GOALS_MODAL);
    }

    async function handleSave() {
      await missionsStore.update({
        goals: goals.value,
        id: props.mission.id,
      });

      close();
    }

    return {
      goals,
      missionGoals,
      close,
      handleSave,
    };
  },
});
