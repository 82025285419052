
import { computed, defineComponent, ref, toRefs } from 'vue';
import PpAccordion from '@/components/common/PPAccordion.vue';

export default defineComponent({
  name: 'share-request-item',
  components: { PpAccordion },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['select', 'updateMessage'],
  setup(props, { emit }) {
    const { supplier, index } = toRefs(props);
    const isAccordionOpen = ref<boolean>(false);

    const message = computed<string>({
      get: () => supplier.value.message,
      set: (value) => emit('updateMessage', value),
    });

    function switchAccordion(): void {
      isAccordionOpen.value = !isAccordionOpen.value;
    }

    function selectSupplier(): void {
      emit('select', index.value);
    }

    function updateMessage(): void {
      emit('updateMessage', index.value);
    }

    // TODO: Find better way
    function reinitMessage(): void {
      message.value = `${message.value}`;
    }

    return {
      message,
      isAccordionOpen,
      reinitMessage,
      switchAccordion,
      selectSupplier,
      updateMessage,
    };
  },
});
