export function getFilledObject(someObject: Record<string, unknown>) {
  const filledObject: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(someObject)) {
    if (typeof value !== undefined) {
      filledObject[key] = value;
    }
  }

  return filledObject;
}
