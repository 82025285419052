import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65d935d9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "__file"
}
const _hoisted_2 = { class: "__file-wrapper" }
const _hoisted_3 = { class: "__file-ext" }
const _hoisted_4 = { class: "__file-name" }
const _hoisted_5 = { class: "__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, {
    class: "project-gallery-card",
    onClick: _ctx.openImage
  }, {
    default: _withCtx(() => [
      (_ctx.isImg)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "__img",
            style: _normalizeStyle({ backgroundImage: `url(${_ctx.file.url})` })
          }, null, 4))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_pp_icon, {
                name: "file",
                size: "72"
              }),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fileExt), 1)
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.file.name), 1)
          ])),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.file.description), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}