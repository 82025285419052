import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f4e78f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_details = _resolveComponent("company-details")!
  const _component_color_divider = _resolveComponent("color-divider")!
  const _component_mission_details = _resolveComponent("mission-details")!
  const _component_architectures = _resolveComponent("architectures")!
  const _component_project_gallery = _resolveComponent("project-gallery")!
  const _component_pp_chip = _resolveComponent("pp-chip")!
  const _component_pp_spacer = _resolveComponent("pp-spacer")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_actions_panel = _resolveComponent("actions-panel")!

  return (_ctx.mission)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (!_ctx.view?.request?.anonymous)
          ? (_openBlock(), _createBlock(_component_company_details, {
              key: 0,
              company: _ctx.company,
              isLoading: _ctx.isCompanyLoading,
              readonly: "",
              class: "__section"
            }, null, 8, ["company", "isLoading"]))
          : _createCommentVNode("", true),
        _createVNode(_component_color_divider),
        _createVNode(_component_mission_details, {
          mission: _ctx.mission,
          isLoading: _ctx.isMissionsLoading,
          anonymous: _ctx.view?.request?.anonymous,
          readonly: "",
          class: "__section"
        }, null, 8, ["mission", "isLoading", "anonymous"]),
        _createVNode(_component_color_divider),
        _createVNode(_component_architectures, {
          mission: _ctx.mission,
          readonly: "",
          class: "__section"
        }, null, 8, ["mission"]),
        _createVNode(_component_color_divider),
        (_ctx.architectureImageList?.length)
          ? (_openBlock(), _createBlock(_component_project_gallery, {
              key: 1,
              label: "Architecture files",
              images: _ctx.architectureImageList,
              readonly: "",
              class: "__section"
            }, null, 8, ["images"]))
          : _createCommentVNode("", true),
        (
        _ctx.view.status === _ctx.ViewStatus.DECLINED ||
        _ctx.view.status === _ctx.ViewStatus.SUBMITTED
      )
          ? (_openBlock(), _createBlock(_component_actions_panel, { key: 2 }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, [
                  (_ctx.view.status)
                    ? (_openBlock(), _createBlock(_component_pp_chip, {
                        key: 0,
                        wrap: "",
                        color: _ctx.ViewStatusDictionary[_ctx.view.status].color
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.ViewStatusDictionary[_ctx.view.status].title), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_pp_spacer),
                _createVNode(_component_pp_button, {
                  class: "__action-btn",
                  type: "outline",
                  title: "Revoke proposal",
                  color: "orange",
                  onClick: _ctx.revokeView
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_actions_panel, { key: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_pp_button, {
                  type: "error",
                  onClick: _ctx.decline,
                  title: "Decline"
                }, null, 8, ["onClick"]),
                _createVNode(_component_pp_spacer),
                (_ctx.view.status === _ctx.ViewStatus.DETAILS_REQUESTED)
                  ? (_openBlock(), _createBlock(_component_pp_button, {
                      key: 0,
                      class: "__action-btn",
                      type: "secondary",
                      title: "Update information request",
                      onClick: _ctx.moreInfo
                    }, null, 8, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_pp_button, {
                      key: 1,
                      class: "__action-btn",
                      type: "secondary",
                      title: "Ask to provide more info",
                      onClick: _ctx.moreInfo
                    }, null, 8, ["onClick"])),
                _createVNode(_component_pp_button, {
                  type: "primary",
                  onClick: _ctx.submit,
                  class: "__action-btn",
                  title: "Send an offer"
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }))
      ]))
    : _createCommentVNode("", true)
}