import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_screen = _resolveComponent("alert-screen")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    width: "100%",
    "min-width": "280",
    "max-width": "460"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_body, null, {
            default: _withCtx(() => [
              _createVNode(_component_alert_screen, {
                type: _ctx.type,
                title: _ctx.title,
                text: _ctx.text
              }, null, 8, ["type", "title", "text"])
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, { onClick: _ctx.close }, {
                default: _withCtx(() => [
                  _createTextVNode("Close")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}