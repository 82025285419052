import { createApp } from 'vue';
import { vMaska } from 'maska';
import uikitPlugin from '@precious-payload/uikit-vue';

import App from './App.vue';
import vClickOutside from 'click-outside-vue3';

import { router } from '@/routes';
import { initModals } from '@/modals';
import { useMixpanel, useSmartlook } from '@/services';

import toastPlugin from './plugins/toast.plugin';
import piniaPlugin from './plugins/pinia.plugin';
import vTrack from './plugins/track.directive';

createApp(App)
  .use(piniaPlugin)
  .use(uikitPlugin)
  .use(toastPlugin)
  .use(router)
  .use(vClickOutside)
  .use(vTrack)
  .directive('maska', vMaska)
  .mount('#app');

initModals();
useMixpanel();
useSmartlook();
