import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b81e91e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__top-row" }
const _hoisted_2 = { class: "__icon" }
const _hoisted_3 = { class: "__name" }
const _hoisted_4 = { class: "__bottom-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_markdown_editor = _resolveComponent("pp-markdown-editor")!
  const _component_pp_accordion = _resolveComponent("pp-accordion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["share-request-item", { _selected: _ctx.supplier.selected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "__title",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectSupplier && _ctx.selectSupplier(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.supplier.selected)
            ? (_openBlock(), _createBlock(_component_pp_icon, {
                key: 0,
                name: "check",
                size: "14"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.supplier.name[0].toUpperCase()), 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.supplier.name), 1)
      ]),
      _createVNode(_component_pp_button, {
        type: "text",
        onClick: _ctx.switchAccordion
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isAccordionOpen ? 'Hide template' : 'Show template'), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_pp_accordion, { isOpen: _ctx.isAccordionOpen }, {
        content: _withCtx(() => [
          _createVNode(_component_pp_markdown_editor, {
            class: "__message-editor",
            modelValue: _ctx.message,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
            label: "Message",
            autosize: "",
            onFocus: _ctx.reinitMessage,
            onBlur: _ctx.reinitMessage
          }, null, 8, ["modelValue", "onFocus", "onBlur"])
        ]),
        _: 1
      }, 8, ["isOpen"])
    ])
  ], 2))
}