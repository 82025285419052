import { RequestStatus } from '../enums/request';

export const RequestStatusDictionary = {
  [RequestStatus.DRAFT]: {
    title: 'Draft',
    color: 'light-blue',
  },
  [RequestStatus.MODERATION]: {
    title: 'Moderation',
    color: 'grey',
  },
  [RequestStatus.WAITING_FOR_RESPONSE]: {
    title: 'Waiting for response',
    color: 'light-blue',
  },
  [RequestStatus.DECLINED]: {
    title: 'Declined',
    color: 'red',
  },
  [RequestStatus.EXPIRED]: {
    title: 'EXPIRED',
    color: 'red',
  },
  [RequestStatus.PROPOSAL_RECEIVED]: {
    title: 'Proposal received',
    color: 'green',
  },
} as const;
