
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import useModals from '@/composables/useModals';
import { IUserProfile } from '@/common/interfaces';
import { Modals } from '@/modals';
import { useStore } from '@/stores';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'update-user-modal',
  props: {
    user: {
      type: Object as PropType<IUserProfile>,
      required: true,
    },
  },
  setup(props) {
    const { companyStore, usersStore } = useStore();
    const { hideModal } = useModals();
    const toast = useToast();

    const data = ref<Partial<IUserProfile>>({
      id: props.user.id,
      companyId: props.user.company?.id,
    });

    const companies = computed(() => companyStore.list);
    const companiesIsLoading = computed(() => companyStore.loading);

    function close() {
      hideModal(Modals.UPDATE_USER_MODAL);
    }

    async function save() {
      await usersStore.update(data.value);
      if (!usersStore.error) {
        toast.success('User saved');
      }
      close();
    }

    onMounted(() => companyStore.fetchAll());

    return {
      data,
      companies,
      companiesIsLoading,
      close,
      save,
    };
  },
});
