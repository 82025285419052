<template>
  <section class="skeleton-company">
    <pp-row class="__header">
      <pp-col cols="auto">
        <pp-skeleton-loader type="avatar" />
      </pp-col>
      <pp-col cols="5">
        <pp-skeleton-loader type="h2" />
      </pp-col>
    </pp-row>
    <pp-row class="__info">
      <pp-col>
        <pp-row class="__info-items" v-for="item in 3" :key="item">
          <pp-col cols="8">
            <pp-skeleton-loader type="h3" />
          </pp-col>
        </pp-row>

        <pp-row class="__description">
          <pp-col>
            <pp-skeleton-loader style="height: 150px" />
          </pp-col>
        </pp-row>
      </pp-col>

      <pp-col>
        <pp-skeleton-loader class="__video" />
      </pp-col>
    </pp-row>
  </section>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-company {
  .__header {
    padding: gap('xl') gap('xl') gap('md');
    align-items: center;
  }
  .__description {
    margin: gap('xl') 0 gap('md');
  }
  .__video {
    width: 100%;
    height: 318px;
    max-width: 640px;
  }
  .__info {
    @include gap('xl');
    padding: gap('md') gap('xl');
    align-items: flex-start;

    &-items {
      align-items: center;
    }
  }
}
</style>
