import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_checkbox = _resolveComponent("pp-checkbox")!
  const _component_pp_textarea = _resolveComponent("pp-textarea")!
  const _component_pp_markdown_editor = _resolveComponent("pp-markdown-editor")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    "max-width": "420"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_header, null, {
            default: _withCtx(() => [
              _createTextVNode("Export control compliance")
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_body, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_checkbox, {
                label: "Has EAR-regulated components",
                modelValue: _ctx.exportControl.hasEarComponents,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exportControl.hasEarComponents) = $event)),
                class: "__field"
              }, null, 8, ["modelValue"]),
              (_ctx.exportControl.hasEarComponents)
                ? (_openBlock(), _createBlock(_component_pp_textarea, {
                    key: 0,
                    label: "EAR-regulated components",
                    placeholder: "List of EAR-regulated components",
                    modelValue: _ctx.exportControl.earComponents,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.exportControl.earComponents) = $event)),
                    class: "__field"
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_pp_checkbox, {
                label: "Has ITAR-regulated components",
                modelValue: _ctx.exportControl.hasItarComponents,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.exportControl.hasItarComponents) = $event)),
                class: "__field"
              }, null, 8, ["modelValue"]),
              (_ctx.exportControl.hasItarComponents)
                ? (_openBlock(), _createBlock(_component_pp_textarea, {
                    key: 1,
                    label: "ITAR-regulated components",
                    placeholder: "List of ITAR-regulated components",
                    modelValue: _ctx.exportControl.itarComponents,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.exportControl.itarComponents) = $event)),
                    class: "__field"
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_pp_markdown_editor, {
                modelValue: _ctx.exportControl.additionalNotes,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.exportControl.additionalNotes) = $event)),
                label: "Additional notes",
                placeholder: "Briefly describe them here if you have those",
                allowedTags: _ctx.AllowedMarkdownTags,
                class: "__field"
              }, null, 8, ["modelValue", "allowedTags"])
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_pp_button, { onClick: _ctx.handleSave }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}