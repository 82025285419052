import { ILevelSelectorOption } from '@/components/level-selector/level-selector-option.interface';

export const technologyReadinessLevels: ILevelSelectorOption[] = [
  {
    value: 1,
    hint: 'Basic principles observed and reported',
  },
  {
    value: 2,
    hint: 'Technology concept and/or application formulated.',
  },
  {
    value: 3,
    hint: 'Analytical and experimental critical function and/or characteristic proof of concept.',
  },
  {
    value: 4,
    hint: 'Component and/or breadboard validation in laboratory environment.',
  },
  {
    value: 5,
    hint: 'Component and/or breadboard validation in relevant environment.',
  },
  {
    value: 6,
    hint: 'System/subsystem model or prototype demonstration in a relevant environment (ground or space).',
  },
  {
    value: 7,
    hint: 'System prototype demonstration in a space environment.',
  },
  {
    value: 8,
    hint: "Actual system completed and 'flight qualified' through test and demonstration (ground or space).",
  },
  {
    value: 9,
    hint: "Actual system 'flight proven' through successful mission operations.",
  },
];
