import { IVerifyCodePayload } from '@/common/interfaces';
import { RequestManager, sanitizePhoneNumber } from '@/utils';

export enum AuthType {
  email = 'email',
  sms = 'sms',
}

export interface IAuthApiResponse {
  username: string;
  type: AuthType;
}

export class AuthApi {
  async getCodeBySms(phone: string): Promise<IAuthApiResponse> {
    const phoneNumber = sanitizePhoneNumber(phone);

    const response = await RequestManager.post('/auth/get-code', {
      type: AuthType.sms,
      phoneNumber,
    });

    return response.data;
  }

  async getCodeByEmail(email: string): Promise<IAuthApiResponse> {
    const response = await RequestManager.post('/auth/get-code', {
      type: AuthType.email,
      email: email,
    });

    return response.data;
  }

  async getCode(login: string, type: AuthType) {
    if (type === AuthType.sms) {
      return this.getCodeBySms(login);
    } else if (type === AuthType.email) {
      return this.getCodeByEmail(login);
    } else {
      throw new Error('Unknown auth type');
    }
  }

  async verifyCode(
    code: string,
    login: string,
    type: AuthType,
    invitation: string | undefined,
    utm: IVerifyCodePayload['utm'],
  ) {
    let loginField, sanitizedLogin;
    if (type === AuthType.sms) {
      loginField = 'phoneNumber';
      sanitizedLogin = sanitizePhoneNumber(login);
    } else {
      loginField = 'email';
      sanitizedLogin = login;
    }

    const response = await RequestManager.post('/auth/verify', {
      [loginField]: sanitizedLogin,
      code,
      type,
      invitation,
      utm,
    });

    return response.data;
  }

  async addAuthFactor(accessToken: string): Promise<void> {
    await RequestManager.post('/auth/add-auth-factor', {
      accessToken,
    });
  }
}
