import { BaseApi } from '@/api/base.api';
import { UserRole } from '@/common/enums';
import { IVehicle } from '@/common/interfaces';

import { RequestManager } from '@/utils';

export class VehiclesApi extends BaseApi<IVehicle> {
  constructor(role: UserRole) {
    super('vehicle', role);
  }

  async fetchNames() {
    const response = await RequestManager.get(`${this.path}/names`);
    return response.data;
  }
}
