
import { defineComponent, PropType, ref, toRef } from 'vue';
import { IArchitecture, IOperationalAlgorithm } from '@/common/interfaces';
import { SubsystemType } from '@/common/enums';
import { Modals } from '@/modals';
import useModals from '@/composables/useModals';
import { AllowedMarkdownTags } from '@/common/const/allowed-markdown-tags';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
  },

  setup(props) {
    const { hideModal } = useModals();
    const { architectureStore } = useStore();

    const architecture = toRef(props, 'architecture');

    const data = ref<IOperationalAlgorithm>({
      ...architecture.value.operationalAlgorithm,
    });

    function close() {
      hideModal(Modals.OPERATIONAL_ALGORITHM_MODAL);
    }

    function handleSave() {
      architectureStore.updateSubsystem({
        subsystem: SubsystemType.OPERATIONAL_ALGORITHM,
        subsystemId: architecture.value.operationalAlgorithm?.id,
        architectureId: architecture.value.id,
        data: data.value,
      });

      close();
    }

    return {
      data,
      close,
      handleSave,
      AllowedMarkdownTags,
    };
  },
});
