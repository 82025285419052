
import { defineComponent, PropType, ref } from 'vue';
import useModals from '@/composables/useModals';
import { IResponse, IView } from '@/common/interfaces';
import { Modals } from '@/modals';
import SubmitResponseForm from '@/views/request/modals/submit-response-form.vue';
import alertScreen from '@/components/modals/alert-screen.vue';

export default defineComponent({
  name: 'submit-response-modal',
  components: {
    SubmitResponseForm,
    alertScreen,
  },
  props: {
    view: {
      type: Object as PropType<IView>,
      required: true,
    },
    response: Object as PropType<IResponse>,
  },
  emits: ['finish'],
  setup(props, { emit }) {
    const { hideModal } = useModals();
    const step = ref<number>(1);

    function close(): void {
      hideModal(Modals.SUBMIT_RESPONSE_MODAL);
    }

    function finish(): void {
      close();
      emit('finish');
    }

    return {
      step,
      close,
      finish,
    };
  },
});
