
import {
  SubsystemType,
  LicenseType,
  LicensingResponsible,
} from '@/common/enums';
import { IArchitecture, ILicensing } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { countries } from '@/common/const';
import { Modals } from '@/modals';
import { defineComponent, PropType, ref, toRef } from 'vue';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
  },

  setup(props) {
    const { hideModal } = useModals();
    const { architectureStore } = useStore();

    const architecture = toRef(props, 'architecture');

    const licenseType = Object.values(LicenseType);
    const licensingResponsible = Object.values(LicensingResponsible);

    const data = ref<ILicensing>({
      ...architecture.value.licensing,
    });

    function close(): void {
      hideModal(Modals.LICENSING_MODAL);
    }

    function saveItem() {
      architectureStore.updateSubsystem({
        subsystem: SubsystemType.LICENSING,
        subsystemId: architecture.value.licensing?.id,
        architectureId: architecture.value?.id,
        data: data.value,
      });

      close();
    }

    return {
      licenseType,
      licensingResponsible,
      data,
      countries,
      close,
      saveItem,
    };
  },
});
