<template>
  <div class="forbidden">
    <img class="__img" src="@/assets/planet-red.svg" alt="403" />
    <h1 class="__text __title">403</h1>
    <h2 class="__text __subtitle">Access forbidden</h2>
  </div>
</template>

<script lang="ts"></script>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.forbidden {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.__img {
  width: 90%;

  @include sm {
    width: 70%;
  }

  @include md {
    width: 60%;
  }

  @include lg {
    width: 40%;
  }
}

.__text {
  text-align: center;
  word-break: break-all;
}

.__title {
  font-size: 32px;
  line-height: 32px;

  @include sm {
    font-size: 48px;
    line-height: 48px;
  }

  @include md {
    font-size: 64px;
    line-height: 68px;
  }
}
.__subtitle {
  font-size: 24px;
  line-height: 24px;

  @include sm {
    font-size: 32px;
    line-height: 32px;
  }

  @include md {
    font-size: 48px;
    line-height: 50px;
  }
}
</style>
