export enum MissionPayloadTypes {
  EARTH_OBSERVATION = 'Earth Observation',
  SPACE_WEATHER = 'Space Weather',
  COMMUNICATIONS = 'Communications',
  GNSS_GPS = 'GNSS/GPS',
  SPACE_SITUATIONS_AWARENES = 'Space Situations Awareness',
  AMATEUR_RADIO = 'Amateur Radio',
  THERMAL_CONTROL = 'Thermal Control',
  ASTRONOMY = 'Astronomy',
  SPACE_MICROGRAVITY_EXPERIMENT = 'Space/Microgravity Experiments',
  PROPULSION_SYSTEM = 'Propulsion System',
}
