
import { defineComponent, PropType, ref } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { dragscrollNext } from 'vue-dragscroll';
import { ITab } from '@/common/interfaces';

export default defineComponent({
  name: 'tabs-panel',

  props: {
    tabList: {
      type: Array as PropType<ITab[] | Record<string, string>[]>,
      default: () => [],
    },
    keyBy: {
      type: String,
      default: 'key',
    },
    valueBy: {
      type: String,
      default: 'name',
    },
  },

  emits: ['change-tab'],

  setup(props, { emit }) {
    const currentTab = ref<string>(
      (props.tabList[0] as Record<string, string>)?.[props.keyBy],
    );

    function changeTab(key: string): void {
      currentTab.value = key;
      emit('change-tab', key);
    }

    return {
      currentTab,
      changeTab,
    };
  },

  directives: {
    dragscroll: dragscrollNext,
  },
});
