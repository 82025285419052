
import { defineComponent, watchEffect } from 'vue';
import { computed, ref } from 'vue';
import { MissionDetails } from '@/views/mission';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { ICommonFile } from '@/common/interfaces';
import ProjectGallery from '@/components/project-gallery/project-gallery.vue';
import Architectures from '@/views/architecture/architectures.vue';
import ColorDivider from '@/components/color-divider/color-divider.vue';
import MissionRequests from '@/components/mission/mission-requests.vue';
import { useStore } from '@/stores';
import { EntityNotFoundException } from '@/common/exceptions';

export default defineComponent({
  name: 'view-mission-page',

  components: {
    MissionRequests,
    MissionDetails,
    ProjectGallery,
    Architectures,
    ColorDivider,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const { missionsStore, architectureStore } = useStore();

    const missionId = ref(route.params.missionId);

    onBeforeRouteUpdate((to) => {
      missionId.value = to.params.missionId as string;
      missionsStore.fetchOne(missionId.value);
      architectureStore.fetchForMission(missionId.value);
    });

    watchEffect(() => {
      if (missionsStore.error instanceof EntityNotFoundException) {
        router.replace({
          name: 'not-found',
          params: { path: route.path.split('/').filter((v) => v) },
        });
      }
    });

    const mission = computed(() =>
      missionsStore.getById(missionId.value as string),
    );

    const requests = computed(() => mission.value?.requests ?? []);

    const isMissionsLoading = computed(() => missionsStore.loading);

    const currentArchitectureIndex = computed(
      () => architectureStore.currentIndex,
    );

    const architectureList = computed(() => architectureStore.list);

    const architectureFiles = computed(() => architectureStore.files);

    const isArchitecturesLoading = computed(() => architectureStore.loading);

    function changeTab(index: number) {
      architectureStore.setCurrentIndex(index);
    }

    function updateFiles(files: ICommonFile[]): void {
      architectureStore.updateFiles({
        id: architectureList.value[currentArchitectureIndex.value].id,
        files,
      });
    }

    return {
      mission,
      requests,
      architectureList,
      architectureFiles,
      isMissionsLoading,
      isArchitecturesLoading,
      currentArchitectureIndex,
      changeTab,
      updateFiles,
    };
  },
});
