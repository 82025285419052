import {
  IFetchAllParams,
  IUserProfile,
  IUsersStoreState,
} from '@/common/interfaces';
import { defineStore } from 'pinia';

export const useUsersStore = defineStore('users', {
  state: (): IUsersStoreState => ({
    list: [],
    count: 0,
    limit: 50,
    current: null,
    loading: false,
    error: null,
  }),

  actions: {
    async fetchOne(userId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const result = await this.$api.users.fetchOne(userId);
        this.setCurrent(result);
        return result;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const { data, count } = await this.$api.users.fetchAll(params);
        this.setList(data);
        this.setcount(count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<IUserProfile>) {
      const item = await this.$api.users.update(data);
      this.setItem(item);
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: IUserProfile[]) {
      this.list = list;
    },

    setcount(count: number) {
      this.count = count;
    },

    setItem(item: IUserProfile) {
      const index = this.list.findIndex((view) => view.id === item.id);
      if (index > -1) {
        this.list[index] = item;
      } else {
        this.list.push(item);
      }
    },

    setCurrent(item: IUserProfile) {
      this.current = item;
    },

    reset() {
      this.$reset();
    },
  },
});
