import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';

export interface ISearchStoreState {
  recentSearches: string[];
  recentVisited: RouteLocationNormalized[];
}

export const useSearchStore = defineStore('search', {
  state: (): ISearchStoreState => ({
    recentVisited: [],
    recentSearches: [],
  }),

  actions: {
    addRecentVisited(route: RouteLocationNormalized) {
      this.recentVisited.unshift(route);

      if (this.recentVisited.length > 3) {
        this.recentVisited.pop();
      }
    },

    addRecentSearch(data: string) {
      this.recentSearches.unshift(data);

      if (this.recentSearches.length > 5) {
        this.recentSearches.pop();
      }
    },

    clearRecentVisited() {
      this.recentVisited = [];
    },

    clearRecentSearches() {
      this.recentSearches = [];
    },
  },
});
