export enum RequestServiceTypes {
  LAUNCH_SERVICES = 'Launch services',
  INSURANCE = 'Insurance',
  SPECTRUM = 'Spectrum',
  HOSTED_PAYLOAD = 'Hosted payload',
  GROUND_STATION = 'Ground station',
  COMPONENT = 'Component',
  LICENSE = 'License',
  TRANSPORTATION = 'Transportation',
  OTHER = 'Other',
}
