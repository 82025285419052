
import { CompanyFundingStage, CompanyFundingType } from '@/common/enums';
import { ICompany } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { useStore } from '@/stores';
import { defineComponent, PropType, reactive, toRef } from 'vue';

export default defineComponent({
  props: {
    company: {
      type: Object as PropType<ICompany>,
      required: true,
    },
  },

  setup(props) {
    const { companyStore, userStore } = useStore();
    const { hideModal } = useModals();

    const types = Object.values(CompanyFundingType);
    const stages = Object.values(CompanyFundingStage);

    const company = toRef(props, 'company');

    const data = reactive({
      id: company.value.id,
      fundingType: company.value.fundingType,
      fundingStage: company.value.fundingStage,
      currentlyFundraising: company.value.currentlyFundraising,
    });

    function close(): void {
      hideModal(Modals.COMPANY_FUNDING_MODAL);
    }

    async function update(): Promise<void> {
      const response = await companyStore.update(data);

      userStore.setData({
        ...userStore.data,
        company: response,
      });
      close();
    }

    return {
      data,
      types,
      stages,
      close,
      update,
    };
  },
});
