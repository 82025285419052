
import { computed, defineComponent, PropType, ref, toRefs, watch } from 'vue';
import slugify from 'slugify';

import { CompanySize } from '@/common/enums';
import { ICompany, ICommonFile } from '@/common/interfaces';
import { Modals } from '@/modals';
import { useStore } from '@/stores';
import useModals from '@/composables/useModals';
import { CompanyServiceType } from '@/common/enums/company/company-service-type.enum';

import { CompanyAvatar, CompanyLinks } from '@/components/company';
import FileList from '@/components/file-list/file-list.vue';
import ProjectGallery from '@/components/project-gallery/project-gallery.vue';
import VideoPlayer from '@/components/video/video-player.vue';

export default defineComponent({
  name: 'view-company-route',

  components: {
    ProjectGallery,
    VideoPlayer,
    CompanyAvatar,
    FileList,
    CompanyLinks,
  },

  props: {
    company: {
      type: Object as PropType<ICompany>,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    serviceTypesVisible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update'],

  setup(props, { emit }) {
    const { company } = toRefs(props);
    const { showModal } = useModals();
    const { userStore, companyStore } = useStore();

    const companySize = Object.values(CompanySize);
    const serviceTypeList = Object.values(CompanyServiceType);

    const name = ref<string | undefined>(props.company.name);
    const slug = ref<string | undefined>(props.company.slug);
    const foundation = ref<string | undefined>(props.company.foundation);
    const description = ref<string | undefined>(props.company.description);
    const shortDescription = ref<string | undefined>(
      props.company.shortDescription,
    );
    const editTeamSizeState = ref<boolean>(false);
    const editFoundationState = ref<boolean>(false);
    const editSlugState = ref<boolean>(false);

    watch(
      () => props.company,
      (value) => {
        name.value = value.name || '';
        description.value = value.description || '';
      },
    );

    const slugProxy = computed<string | undefined>({
      get: () => slug.value,
      set: (value) => {
        if (!value) {
          return;
        }

        slug.value = slugify(value, {
          lower: true,
          strict: true,
        });
        slug.value = slug.value.replace(/[^a-zA-Z0-9-_]/g, '');
      },
    });

    const isAdmin = computed<boolean>(() => userStore.isAdmin);

    const shortDescriptionProxy = computed<string | undefined>({
      get: () => company.value?.shortDescription,
      set: (value) => (shortDescription.value = value),
    });

    const size = computed<CompanySize | undefined>({
      get: () => props.company.size,
      set: (value: CompanySize | undefined) => {
        emit('update', {
          id: props.company.id,
          size: value,
        });
      },
    });

    const serviceTypes = computed<CompanyServiceType[]>({
      get: () => (props.company.serviceTypes || []) as CompanyServiceType[],
      set: (values: CompanyServiceType[]) => {
        emit('update', {
          id: props.company.id,
          serviceTypes: values.length === 0 ? null : values,
        });
      },
    });

    const links = computed<string[]>({
      get: () => props.company.links || [],
      set: (values: string[]) => {
        emit('update', {
          id: props.company.id,
          links: values,
        });
      },
    });

    const files = computed<ICommonFile[]>({
      get: () => props.company.files || [],
      set: (values: ICommonFile[]) => {
        emit('update', {
          id: props.company.id,
          files: values,
        });
      },
    });

    const videoUrl = computed<string>({
      get: () => props.company.videoURL || '',
      set: (value: string) => {
        emit('update', {
          id: props.company.id,
          videoURL: value || null,
        });
      },
    });

    function updateName(event: Event): void {
      const eventTarget = event.target as HTMLInputElement;
      const value = eventTarget.value.trim();

      if (!value) {
        name.value = props.company.name;
        return;
      }

      if (value === props.company.name) {
        return;
      }

      emit('update', {
        id: props.company.id,
        name: value,
      });
    }

    function updateFoundation(): void {
      emit('update', {
        id: props.company.id,
        foundation: foundation.value,
      });
      editFoundationState.value = false;
    }

    function updateDescription(): void {
      emit('update', {
        id: props.company.id,
        description: description.value,
      });
    }

    function updateShortDescription(): void {
      emit('update', {
        id: props.company.id,
        shortDescription: shortDescription.value,
      });
    }

    function updateField(name: keyof ICompany, value: unknown): void {
      emit('update', {
        id: props.company.id,
        [name]: value,
      });
    }

    function saveVideoUrl(url: string): void {
      videoUrl.value = url;
    }

    function handleUpdateAvatar({ avatarUrl }: ICompany): void {
      emit('update', {
        id: props.company.id,
        avatarUrl,
      });
    }

    function saveSlug(): void {
      emit('update', {
        id: props.company.id,
        slug: slug.value,
      });
    }

    function generateSlug(): void {
      slugProxy.value = company.value.name || '';
    }

    function changeSlugEditable(): void {
      if (company.value?.slug === slug.value) {
        editSlugState.value = false;
      }
    }

    function clearSlugChanges(): void {
      slug.value = company.value.slug || '';
    }

    function onSlugClick(): void {
      editSlugState.value = true;
    }

    async function updateFiles(files: ICommonFile[]): Promise<void> {
      try {
        const updatedCompany = await companyStore.updateFiles({
          id: company.value.id,
          files,
        });

        if (userStore.data.company?.id === updatedCompany?.id) {
          userStore.fetch();
        }
      } catch (e) {
        //
      }
    }

    function showCompanyLocationModal(): void {
      showModal(Modals.COMPANY_LOCATION_MODAL, { company: props.company });
    }

    function showCompanyFundingModal(): void {
      showModal(Modals.COMPANY_FUNDING_MODAL, { company: props.company });
    }

    return {
      size,
      links,
      files,
      videoUrl,
      name,
      slugProxy,
      isAdmin,
      updateName,
      updateFoundation,
      companySize,
      serviceTypeList,
      serviceTypes,
      shortDescription,
      description,
      foundation,
      editSlugState,
      editTeamSizeState,
      editFoundationState,
      shortDescriptionProxy,
      saveSlug,
      onSlugClick,
      generateSlug,
      clearSlugChanges,
      changeSlugEditable,
      updateFiles,
      updateField,
      saveVideoUrl,
      updateDescription,
      handleUpdateAvatar,
      showCompanyLocationModal,
      showCompanyFundingModal,
      updateShortDescription,
    };
  },
});
