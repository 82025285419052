import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20db2270"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ds-level-selector" }
const _hoisted_2 = { class: "levels" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (level) => {
        return (_openBlock(), _createElementBlock("span", {
          key: level.value,
          onClick: () => _ctx.onChange(level.value),
          class: _normalizeClass({ active: level.value === _ctx.selectedValue?.value })
        }, _toDisplayString(level?.value), 11, _hoisted_3))
      }), 128))
    ]),
    (_ctx.selectedValue?.hint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.selectedValue.hint), 1))
      : _createCommentVNode("", true)
  ]))
}