export function sanitizePhoneNumber(number = '') {
  // TODO: Why are we using letters checking here?
  let sanitizedNumber = number.trim().replace(/[^a-zA-Z\d]/g, '');

  if (sanitizedNumber.startsWith('00')) {
    sanitizedNumber = sanitizedNumber.substring(2);
  }

  if (!sanitizedNumber.startsWith('+')) {
    sanitizedNumber = `+${sanitizedNumber}`;
  }

  return sanitizedNumber;
}
