<template>
  <section class="skeleton-arch">
    <pp-row class="__headers">
      <pp-col cols="4">
        <pp-skeleton-loader type="h3" />
      </pp-col>
    </pp-row>

    <pp-row class="__cards-box">
      <pp-col v-for="item in 3" :key="item">
        <pp-skeleton-loader type="h3" />

        <pp-card class="__cards-item" v-for="item in 3" :key="item">
          <pp-card-header>
            <pp-skeleton-loader type="h3" />
          </pp-card-header>
          <pp-card-body>
            <pp-skeleton-loader v-for="item in 3" :key="item" type="h3" />
          </pp-card-body>
          <pp-card-actions>
            <pp-skeleton-loader type="button" />
            <pp-spacer />
          </pp-card-actions>
        </pp-card>
      </pp-col>
    </pp-row>
  </section>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-arch {
  padding: gap('xl') gap('xl') gap('md');
  margin: gap('md') 0;

  .__headers {
    justify-content: space-between;
  }

  .__cards-box {
    margin: gap('md') 0;
  }

  .__cards-item {
    margin: gap('sm') 0;
  }
}
</style>
