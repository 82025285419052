import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98dc52c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_text_field = _resolveComponent("pp-text-field")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    width: "380",
    "max-width": "460"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_body, { class: "__body" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1),
              (_ctx.type === 'prompt')
                ? (_openBlock(), _createBlock(_component_pp_text_field, {
                    key: 0,
                    modelValue: _ctx.input,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
                    autofocus: "",
                    onKeydown: _withKeys(_ctx.ok, ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_pp_button, { onClick: _ctx.ok }, {
                default: _withCtx(() => [
                  _createTextVNode("Ok")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}