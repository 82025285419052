import { IRequest } from '@/common/interfaces';
import { Currency } from '@/common/enums/currency';

export const emptyRequest: Partial<IRequest> = {
  id: undefined,
  description: undefined,
  status: undefined,
  type: undefined,
  serviceType: undefined,
  mission: undefined,
  missionId: undefined,
  budgetExpectation: undefined,
  budgetAmount: undefined,
  budgetCurrency: Currency.USD,
  fundraising: undefined,
  supplierSelectionCriteria: undefined,
  launchDate: undefined,
  launchDateFlexibility: undefined,
  supplierSelectionTime: undefined,
  deadline: undefined,
};
