
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'sidebar-mobile-header',
  components: {},
  emits: ['closeSidebar'],
  setup(props, { emit }) {
    function closeSidebar(): void {
      emit('closeSidebar');
    }

    return {
      closeSidebar,
    };
  },
});
