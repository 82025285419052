
import { defineComponent, PropType } from 'vue';
import { getProp } from '@/utils/get-prop';
import { ITableData } from '@/common/interfaces';

export default defineComponent({
  name: 'custom-table',
  props: {
    data: {
      type: Object as PropType<ITableData>,
      default: {
        headers: [],
        items: [],
      } as ITableData,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    hideDefaultDeader: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      getProp,
    };
  },
});
