<template>
  <div class="skeleton-share-request">
    <div class="__group" v-for="group in 2" :key="group">
      <div class="__header">
        <pp-skeleton-loader class="__group-name" type="text" />
        <pp-skeleton-loader class="__group-button" type="button" />
      </div>

      <div class="__list">
        <div class="__item" v-for="item in 5" :key="item">
          <pp-skeleton-loader class="__item-avatar" type="circle" />
          <pp-skeleton-loader class="__item-name" type="text" />
          <pp-skeleton-loader class="__item-button" type="button" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'skeleton-share-request',
};
</script>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

$blockName: 'skeleton-share-request';

.#{$blockName} {
  @include gap('xl');

  display: flex;
  flex-direction: column;

  .__header {
    display: flex;
  }

  .__group-name {
    width: 125px;
    margin-right: 15px;
  }

  .__list {
    @include gap('md');

    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .__item {
    display: flex;
  }

  .__item-name {
    width: 170px;
    margin: 0 15px;
  }
}
</style>
