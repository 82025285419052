export enum SubsystemType {
  GROUND_STATION = 'groundStation',
  ORBIT = 'orbit',
  CONFIGURATION = 'configuration',
  PAYLOAD = 'payload',
  PROPULSION_SYSTEM = 'propulsionSystem',
  OPERATIONAL_ALGORITHM = 'operationalAlgorithm',
  EXPORT_CONTROL_COMPLIANCE = 'exportControlCompliance',
  LICENSING = 'licensing',
  INSURANCE = 'insurance',
}
