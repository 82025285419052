import { useStore } from '@/stores';
import { RouteRecordRaw } from 'vue-router';
import AdminCompaniesPage from './admin-companies-page.vue';
import AdminOneCompanyPage from './admin-one-company-page.vue';
import AdminListCompaniesPage from './admin-list-companies-page.vue';

export const adminCompaniesRoutes: RouteRecordRaw = {
  path: '/admin/company',
  component: AdminCompaniesPage,
  name: 'admin-companies',
  redirect: { name: 'admin-list-operators' },
  children: [
    {
      path: ':companyId',
      name: 'admin-one-company',
      component: AdminOneCompanyPage,
      beforeEnter: (to, from, next) => {
        const { missionsStore, companyStore } = useStore();

        companyStore.fetchOne(to.params.companyId as string);
        companyStore.fetchMembers(to.params.companyId as string);

        missionsStore.fetchByParams({
          field: 'companyId',
          operator: 'eq',
          value: to.params.companyId as string,
        });

        next();
      },
    },
    {
      path: '/admin/companies/operators',
      name: 'admin-list-operators',
      component: AdminListCompaniesPage,
      beforeEnter: (to, from, next) => {
        const { companyStore } = useStore();

        companyStore.fetchAll({
          filter: 'serviceTypes||$isnull',
          limit: companyStore.limit,
        });

        next();
      },
    },
    {
      path: '/admin/companies/suppliers',
      name: 'admin-list-suppliers',
      component: AdminListCompaniesPage,
      beforeEnter: (to, from, next) => {
        const { companyStore } = useStore();

        companyStore.fetchAll({
          filter: 'serviceTypes||$notnull',
          limit: companyStore.limit,
        });

        next();
      },
    },
  ],
};
