
import { defineComponent, PropType, toRef } from 'vue';
import { IArchitecture } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { AllowedMarkdownTags } from '@/common/const/allowed-markdown-tags';

export default defineComponent({
  name: 'operational-algorithm-item',

  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { showModal } = useModals();

    const architecture = toRef(props, 'architecture');

    function showOAModal(): void {
      showModal(Modals.OPERATIONAL_ALGORITHM_MODAL, { architecture });
    }

    return {
      showOAModal,
      AllowedMarkdownTags,
    };
  },
});
