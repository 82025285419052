import { RouteRecordRaw } from 'vue-router';
import { useStore } from '@/stores';
import AdminUserPage from './admin-user.page.vue';
import AdminListUserPage from './admin-list-users.page.vue';
import AdminOneUserPage from './admin-one-user.page.vue';

export const adminUserRoutes: RouteRecordRaw = {
  path: '/admin/user',
  component: AdminUserPage,
  children: [
    {
      path: '/admin/users',
      name: 'admin-list-user',
      component: AdminListUserPage,
      beforeEnter: (to, from, next) => {
        const { usersStore } = useStore();
        usersStore.fetchAll({ limit: usersStore.limit });
        next();
      },
    },
    {
      path: ':userId',
      name: 'admin-one-user',
      component: AdminOneUserPage,
      beforeEnter: (to, _from, next) => {
        const { usersStore } = useStore();

        if (typeof to.params.userId === 'string') {
          usersStore.fetchOne(to.params.userId);
          next();
        } else {
          next({ name: 'admin-list-user' });
        }
      },
    },
  ],
};
