import { computed, ref } from 'vue';
import { InvitationLocalStorageKeys } from '@/common/enums';
import { IInvitation } from '@/common/interfaces/invite/invitation.interface';
import { api } from '@/plugins/pinia/api.plugin';
import { HttpException } from '@/common/exceptions';

const invitation = ref<IInvitation | null>(null);

export function useInvites() {
  const isInvited = computed<boolean>(() => {
    if (!invitation.value) return false;
    const { uuid, email } = invitation.value;
    return !!uuid && !!email;
  });

  function saveInvitation(inv: IInvitation) {
    localStorage.setItem(InvitationLocalStorageKeys.UUID, inv.uuid);
    localStorage.setItem(InvitationLocalStorageKeys.EMAIL, inv.email);
    invitation.value = inv;
  }

  async function restoreInvitation() {
    const uuid = localStorage.getItem(InvitationLocalStorageKeys.UUID);
    const email = localStorage.getItem(InvitationLocalStorageKeys.EMAIL);

    if (uuid && email) {
      invitation.value = {
        uuid,
        email,
      };

      try {
        const inv = await fetchInvitation(uuid);
        if (inv) {
          invitation.value = inv;
        }
      } catch (error) {
        if (error instanceof HttpException) {
          clearInvitation();
        }
      }
    }

    return invitation.value;
  }

  function clearInvitation() {
    localStorage.removeItem(InvitationLocalStorageKeys.UUID);
    localStorage.removeItem(InvitationLocalStorageKeys.EMAIL);
    invitation.value = null;
  }

  async function fetchInvitation(uuid: string) {
    return await api.invite.fetchOne(uuid);
  }

  return {
    invitation,
    isInvited,
    saveInvitation,
    restoreInvitation,
    clearInvitation,
    fetchInvitation,
  };
}
