import { RouteRecordRaw } from 'vue-router';
import { useStore } from '@/stores';

import AdminVehiclesPage from './admin-vehicles.page.vue';
import AdminListVehiclesPage from './admin-list-vehicles.page.vue';
import AdminCreateVehiclePage from './admin-create-vehicle.page.vue';
import AdminOneVehicle from './admin-one-vehicle.page.vue';

export const adminVehiclesRoutes: RouteRecordRaw = {
  path: '/admin/vehicle',
  component: AdminVehiclesPage,
  name: 'admin-vehicles',
  redirect: { name: 'admin-list-vehicles' },
  children: [
    {
      path: '/admin/vehicles',
      name: 'admin-list-vehicles',
      component: AdminListVehiclesPage,
      beforeEnter: (to, from, next) => {
        const { vehiclesStore } = useStore();
        vehiclesStore.fetchAll();
        next();
      },
    },
    {
      path: 'create',
      name: 'admin-create-vehicle',
      component: AdminCreateVehiclePage,
      beforeEnter: (to, from, next) => {
        const { companyStore } = useStore();
        companyStore.fetchAll();
        next();
      },
    },
    {
      path: ':id',
      name: 'admin-one-vehicle',
      component: AdminOneVehicle,
      beforeEnter: (to, from, next) => {
        const id = to.params.id as string;
        const { vehiclesStore, companyStore } = useStore();
        vehiclesStore.fetchOne(id);
        companyStore.fetchAll();
        next();
      },
    },
  ],
};
