import { ValuesOf } from '@/utils/types';
import { OrbitAltitudeType } from '../orbit/orbit-altitude-type.enum';

export const ArchitectureOrbitAltitudeType = {
  ...OrbitAltitudeType,
  CUSTOM: 'Custom',
} as const;

export type ArchitectureOrbitAltitudeType = ValuesOf<
  typeof ArchitectureOrbitAltitudeType
>;
