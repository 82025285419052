import useModals from '@/composables/useModals';
const { showConfirm } = useModals();

const useConfirmLinks = () => {
  const allowedHost = [
    'preciouspayload.com',
    'payload.guide',
    'payload.ninja',
    'ucarecdn.com',
    process.env.VUE_APP_AUTH0_ORIGIN,
    window.location.host,
  ];

  async function linksConfirmer(e: Event) {
    const eventTarget = e.target as Element;
    const link = eventTarget?.closest('a');
    if (!link?.host || allowedHost.indexOf(link.host) >= 0) {
      return;
    }

    e.preventDefault();
    const confirm = await showConfirm(
      `You are trying to follow a link to a third-party site. Are you sure? ${link.host}`,
    );

    if (confirm) {
      window.open(link.href, link.target);
    }

    return;
  }

  document.addEventListener('click', linksConfirmer, false);
};
export default useConfirmLinks;
