import {
  ICommonFile,
  ICompany,
  ICompanyStoreState,
  IFetchAllParams,
  IInviteRequest,
  IUserProfile,
} from '@/common/interfaces';
import { defineStore } from 'pinia';

export const useCompanyStore = defineStore('company', {
  state: (): ICompanyStoreState => ({
    loading: false,
    error: null,
    membersError: null,
    data: {
      id: '',
      name: '',
      slug: '',
      description: '',
      shortDescription: '',
      avatarUrl: '',
      country: '',
      city: '',
      size: undefined,
      fundingStage: undefined,
      fundingType: undefined,
      currentlyFundraising: false,
      links: [],
      videoURL: '',
      news: '',
      missions: [],
      foundation: '',
      galleryFiles: [],
    },
    list: [],
    count: 0,
    limit: 200,
    members: null,
  }),

  getters: {
    getById: (state: ICompanyStoreState) => (companyId: string) =>
      state.list.find((el) => el.id === companyId),
  },
  actions: {
    async fetch() {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.company.fetch();
        this.setData(response);
        return this.data;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);

      try {
        const response = await this.$api.company.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(companyId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.company.fetchOne(companyId);

        const index = this.list.findIndex((el) => el.id === companyId);

        if (index > -1) {
          this.updateByIndex({
            index,
            company: response,
          });
        } else {
          this.add(response);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchForRequest(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const company = await this.$api.company.fetchForRequest(requestId);
        this.setList([company]);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchMembers(companyId: string) {
      this.setMembersError(null);

      try {
        const members = await this.$api.company.fetchMembers(companyId);
        this.setMembers(members);
        return members;
      } catch (err) {
        this.setMembersError(err);
        return null;
      }
    },

    async create(data: Partial<ICompany>) {
      this.setError(null);

      try {
        const response = await this.$api.company.create(data);
        this.setData(response);
        this.add(response);
        return response;
      } catch (err) {
        this.setError(err);
      }
    },

    async update(data: Partial<ICompany>) {
      this.setError(null);

      try {
        const response = await this.$api.company.update(data);
        this.setData(response);

        const index = this.list.findIndex((el) => el.id === response.id);

        if (index > -1) {
          this.updateByIndex({
            index,
            company: response,
          });
        }

        return response;
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async remove(companyId: string) {
      this.setError(null);

      try {
        await this.$api.company.delete(companyId);
        const index = this.list.findIndex((el) => el.id === companyId);

        if (index !== -1) {
          this.list.splice(index, 1);
        }

        return true;
      } catch (err) {
        this.setError(err);
        return false;
      }
    },

    async updateFiles(dto: { id: string; files: ICommonFile[] }) {
      try {
        const response = await this.$api.company.updateFiles(dto.id, dto.files);

        if (this.data.id === response.id) {
          this.setData(response);
        }

        const index = this.list.findIndex((el) => el.id === response.id);

        if (index !== -1) {
          this.list[index] = response;
        }

        return response;
      } catch (err) {
        // TODO: CATCH ERROR PROPERLY
      }
    },

    async inviteMember(companyId: string, data: IInviteRequest) {
      try {
        return await this.$api.company.inviteMember(companyId, data);
      } catch (err) {
        console.error(err);
      }
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setData(data: ICompany) {
      if (this.data.id !== data.id) {
        this.setMembers(null);
      }
      this.data = data;
    },

    setList(list: ICompany[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    setMembers(members: IUserProfile[] | null) {
      this.members = members;
    },

    setMembersError(membersError: unknown) {
      this.membersError = membersError;
    },

    add(data: ICompany) {
      this.list.push(data);
    },

    updateByIndex(data: { index: number; company: ICompany }) {
      this.list[data.index] = data.company;
    },

    reset() {
      this.$reset();
    },
  },
});
