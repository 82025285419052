import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58a501b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-companies-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton_table_list = _resolveComponent("skeleton-table-list")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_icon = _resolveComponent("user-icon")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_custom_table = _resolveComponent("custom-table")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_skeleton_table_list, {
          key: 0,
          "items-amount": _ctx.limit
        }, null, 8, ["items-amount"]))
      : (_openBlock(), _createBlock(_component_custom_table, {
          key: 1,
          data: _ctx.tableData
        }, {
          [`item.name`]: _withCtx(({ item }) => [
            _createVNode(_component_router_link, {
              to: {
            name: 'admin-one-company',
            params: { companyId: item.id },
          }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name || 'No Name Company'), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          [`item.serviceTypes`]: _withCtx(({ item }) => [
            (item.serviceTypes)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.itemTransform(item.serviceTypes)), 1))
              : _createCommentVNode("", true)
          ]),
          [`item.createdBy`]: _withCtx(({ item }) => [
            _createVNode(_component_user_icon, {
              id: item.createdBy?.id
            }, null, 8, ["id"])
          ]),
          [`item.actions`]: _withCtx(({ item }) => [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: ($event: any) => (_ctx.impersonate(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Mimic ")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_pp_button, {
                type: "error",
                onClick: ($event: any) => (_ctx.remove(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Delete")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]),
          _: 2
        }, 1032, ["data"]))
  ]))
}