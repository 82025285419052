import ErrorPage from './the-error.page.vue';
import NotFound from './notFound.page.vue';
import Forbidden from './the-forbidden.page.vue';
import Expired from './the-expired.page.vue';
import { RouteRecordRaw } from 'vue-router';
import { LayoutType } from '@/common/enums/layout';

export const ErrorRoute: RouteRecordRaw = {
  path: '/:path(.*)*',
  name: 'error',
  component: ErrorPage,
  meta: {
    layout: LayoutType.Auto,
  },
  children: [
    {
      path: '',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '',
      name: 'forbidden',
      component: Forbidden,
    },
    {
      path: '',
      name: 'expired',
      component: Expired,
    },
  ],
};
