
import { SubsystemType } from '@/common/enums';
import { IArchitecture, IExportControlCompliance } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { defineComponent, PropType, ref, toRef } from 'vue';
import { AllowedMarkdownTags } from '@/common/const/allowed-markdown-tags';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
  },

  setup(props) {
    const { architectureStore } = useStore();

    const { hideModal } = useModals();

    const architecture = toRef(props, 'architecture');

    const exportControl = ref<IExportControlCompliance>({
      ...architecture.value.exportControlCompliance,
    });

    function close(): void {
      hideModal(Modals.EXPORT_CONTROL_MODAL);
    }

    function handleSave() {
      architectureStore.updateSubsystem({
        subsystem: SubsystemType.EXPORT_CONTROL_COMPLIANCE,
        subsystemId: exportControl.value.id,
        architectureId: architecture.value.id,
        data: exportControl.value,
      });

      close();
    }

    return {
      exportControl,
      AllowedMarkdownTags,
      close,
      handleSave,
    };
  },
});
