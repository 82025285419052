import { debounce } from './debounce';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useToast } from 'vue-toastification';
import { useAppStore } from '@/stores/app';
import { noToastErrors } from '@/common/enums/errors/noToastErrors';
import {
  UnauthorizedException,
  ForbiddenException,
  HttpException,
} from '@/common/exceptions';

const toast = useToast();

export const RequestManager = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

RequestManager.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('accessToken');

  if (!token) {
    return config;
  }

  return Object.assign(config, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
});

const showErrorToast = debounce((error: string) => {
  toast.error(error);
}, 200);

const showInfoToast = debounce((message: string) => {
  toast.info(message);
}, 200);

RequestManager.interceptors.response.use(undefined, async function (error) {
  if (!(error instanceof AxiosError) || !error.response) {
    if (error.message) {
      showErrorToast(error.message);
    }
    throw error;
  }

  if (error.response.status === 401) {
    const appStore = useAppStore();
    appStore.logout();
    const exception = new UnauthorizedException('You have been logged out');
    showInfoToast(exception.message);
    throw exception;
  }

  if (error.response.status === 403) {
    const { data, statusText } = error.response;
    throw new ForbiddenException(data || statusText);
  }

  const errorMessage = error.response.data.message;
  const textErrorMessage = Array.isArray(errorMessage)
    ? errorMessage.join('\n')
    : errorMessage;

  const httpError = new HttpException({
    statusCode: error.response.status,
    ...error.response.data,
    message: textErrorMessage || error.response.statusText,
  });

  if (httpError.errorCode && !noToastErrors.has(httpError.errorCode)) {
    showErrorToast(httpError.message || httpError.errorCode || 'Error');
  }

  throw httpError;
});
