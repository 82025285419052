
import { computed, defineComponent, toRefs } from 'vue';

export default defineComponent({
  name: 'date-field',
  props: {
    label: String,
    modelValue: String,
    prefix: String,
    suffix: String,
    disabled: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const value = computed({
      get: () => modelValue.value,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      value,
    };
  },
});
