import { defineStore } from 'pinia';

import { useUserStore } from './user';
import { useAuthStore } from './auth';
import { useViewsStore } from './views';
import { useCompanyStore } from './company';
import { useMissionsStore } from './missions';
import { useRequestsStore } from './requests';
import { useArchitectureStore } from './architecture';
import useMimic from '@/composables/useMimic';

export const useAppStore = defineStore('app', {
  state: () => ({
    loading: false,
    initialized: false,
  }),
  actions: {
    async init() {
      console.info('Initializing store...');

      const authStore = useAuthStore();
      const userStore = useUserStore();
      const companyStore = useCompanyStore();
      const missionsStore = useMissionsStore();
      const { getMimic } = useMimic();

      const isLoggedIn = authStore.isLoggedIn;

      if (isLoggedIn) {
        this.setLoading(true);

        const user = await userStore.fetch();

        if (!user?.id) {
          this.setLoading(false);
          this.setInitialized(true);

          return this.logout();
        }

        if (userStore.isAdmin) {
          const mimicCompanyId = getMimic();

          mimicCompanyId && user.company?.id === mimicCompanyId
            ? await userStore.impersonate(mimicCompanyId)
            : userStore.unimpersonate(); // We don't need to await this method

          this.setLoading(false);
          this.setInitialized(true);

          if (userStore.isMimic) {
            missionsStore.fetchAll();
          }
        } else {
          this.setLoading(false);
          this.setInitialized(true);

          missionsStore.fetchAll();
        }

        if (user.company?.id) {
          companyStore.setData(user.company);
        }
      }

      console.info('Initialization completed');
    },

    async logout() {
      const authStore = useAuthStore();
      const userStore = useUserStore();
      const viewsStore = useViewsStore();
      const companyStore = useCompanyStore();
      const requestStore = useRequestsStore();
      const missionsStore = useMissionsStore();
      const architectureStore = useArchitectureStore();

      console.info('Logging out...');

      await Promise.all([
        authStore.reset(),
        userStore.reset(),
        viewsStore.reset(),
        companyStore.reset(),
        requestStore.reset(),
        missionsStore.reset(),
        architectureStore.reset(),
      ]);

      console.info('Logged out');

      this.setInitialized(false);

      this.$router.push({
        name: 'auth',
      });
    },

    setInitialized(value: boolean) {
      this.initialized = value;
    },

    setLoading(value: boolean) {
      this.loading = value;
    },
  },
});
