import { SubsystemType, UserRole } from '@/common/enums';
import { IArchitecture } from '@/common/interfaces';
import { Subsystem } from '@/common/types/subsystem.type';
import { RequestManager } from '@/utils';
import { ApiRolesBasis } from './base.api';

export class SubsystemApi extends ApiRolesBasis {
  constructor(role: UserRole = UserRole.User) {
    super('subsystem', role);
  }

  async create(
    architectureId: string,
    subsystem: SubsystemType,
    data: Partial<Subsystem>,
  ) {
    const response = await RequestManager.post<Partial<IArchitecture>>(
      this.path,
      {
        architectureId,
        subsystem,
        data,
      },
    );
    return response.data;
  }

  async update(
    subsystem: SubsystemType,
    subsystemId: string,
    data: Partial<Subsystem>,
  ) {
    const response = await RequestManager.patch<Partial<IArchitecture>>(
      this.path,
      {
        subsystem,
        subsystemId,
        data,
      },
    );
    return response.data;
  }

  async delete(
    subsystem: SubsystemType,
    subsystemId: string,
    architectureId: string,
  ) {
    const response = await RequestManager.delete<Partial<IArchitecture>>(
      this.path,
      {
        data: {
          architectureId,
          subsystem,
          subsystemId,
        },
      },
    );
    return response.data;
  }
}
