import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';

import { AuthType } from '@/api';
import {
  IAuthForm,
  IAuthStoreState,
  ISendCodePayload,
  IVerifyCodePayload,
} from '@/common/interfaces';
import { CookieTypes, MixpanelActions, useMixpanel } from '@/services';
import { DAY, SEC } from '@/utils';

const { track } = useMixpanel();

export const useAuthStore = defineStore('auth', {
  state: (): IAuthStoreState => ({
    error: null,
    token: localStorage.getItem('accessToken') || '',
    initialURL: localStorage.getItem('initialURL') || '',
    form: {
      method: AuthType.sms,
      email: '',
      phone: '',
    },
  }),

  getters: {
    isLoggedIn: (state: IAuthStoreState) => !!state.token,
  },

  actions: {
    async sendCode(payload: ISendCodePayload) {
      const { login, type } = payload;

      try {
        const response = await this.$api.auth.getCode(login, type);

        track(MixpanelActions.LOGIN_GET_CODE, {
          login,
          type,
        });

        return response;
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async login(payload: IVerifyCodePayload) {
      this.setError(null);

      const token = await this.verifyCode(payload);

      if (token) {
        this.setAuthToken(token);
      }

      return token;
    },

    async verifyCode(payload: IVerifyCodePayload) {
      try {
        const { code, login, type, utm, invitation } = payload;

        const { accessToken } = await this.$api.auth.verifyCode(
          code,
          login,
          type,
          invitation,
          utm,
        );

        track('Sign-in - Confirmation - success', {
          method: type,
          login,
        });

        // TODO: remove old event
        track(MixpanelActions.LOGIN_SUCCESS, {
          login,
          type,
        });

        return accessToken;
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async addAuthFactor(accessToken: string): Promise<void> {
      try {
        await this.$api.auth.addAuthFactor(accessToken);
      } catch (err) {
        this.setError(err);
      }
    },

    setAuthToken(accessToken: string) {
      const { cookies } = useCookies();

      this.token = accessToken;

      localStorage.setItem('accessToken', accessToken);

      const domain = location.hostname.split('.').slice(-2).join('.');

      cookies.set(
        CookieTypes.ACCESS_TOKEN,
        accessToken,
        DAY / SEC,
        undefined,
        domain,
      );
    },

    setError(error: unknown) {
      this.error = error;
    },

    setUrl(url: string | null) {
      this.initialURL = url;

      if (url) {
        localStorage.setItem('initialURL', url);
      } else {
        localStorage.removeItem('initialURL');
      }
    },

    setForm(form: Partial<IAuthForm>) {
      this.form = {
        ...this.form,
        ...form,
      };
    },

    reset() {
      const { cookies } = useCookies();

      localStorage.removeItem('accessToken');
      cookies.remove(CookieTypes.ACCESS_TOKEN);

      this.$reset();
    },
  },
});
