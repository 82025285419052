import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { IUTM } from '@/common/interfaces';

export function useQueryUtm() {
  const route = useRoute();

  const utm = computed<IUTM>(() => {
    const { utm_source = 'pp', utm_campaign } = route.query;
    let { utm_medium, utm_content } = route.query;

    if (hasLaunchOptions()) {
      if (!utm_medium) {
        utm_medium = 'ls';
      }
      if (!utm_content) {
        utm_content = serializeLaunchOptions();
      }
    }

    const result = {
      utm_source: utm_source as string,
      utm_medium: utm_medium as string,
      utm_campaign: utm_campaign as string,
      utm_content: utm_content as string,
    };

    (Object.keys(result) as (keyof typeof result)[]).forEach((key) => {
      if (result[key] === undefined) {
        delete result[key];
      }
    });

    return result;
  });

  function hasLaunchOptions() {
    return ['launch', 'free_consultation'].some((key) => key in route.query);
  }

  /** @returns array of launch options encoded to base64  */
  function serializeLaunchOptions(): string {
    const { launch } = route.query;

    const result = {
      free_consultation: 'free_consultation' in route.query,
      launchIds: Array.isArray(launch) ? launch : [launch],
    };

    try {
      return btoa(JSON.stringify(result));
    } catch (e) {
      console.error(`Failed to stringify launch options:`, result);
      return '';
    }
  }

  return {
    utm,
  };
}
