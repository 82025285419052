
import { useUserStore } from '@/stores/user';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'sidebar-mimic-alert',
  setup() {
    const usersStore = useUserStore();
    const router = useRouter();
    const company = computed(() => usersStore.data?.company);

    async function handleClick() {
      await usersStore.unimpersonate();
      router.push({
        name: 'admin',
      });
    }

    return {
      company,
      handleClick,
    };
  },
});
