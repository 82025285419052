const useMimic = () => {
  function setMimic(companyId: string): void {
    localStorage.setItem('mimic', companyId);
  }

  function getMimic(): string | null {
    return localStorage.getItem('mimic');
  }

  function removeMimic(): void {
    localStorage.removeItem('mimic');
  }

  return {
    setMimic,
    getMimic,
    removeMimic,
  };
};

export default useMimic;
