/** Custom tokent for v-maska directive */
export const tokens = {
  /** Repeatable digit */
  '9': {
    pattern: /[0-9]/,
    multiple: true,
  },
  /** Optional digit */
  '0': {
    pattern: /[0-9]/,
    optional: true,
  },
};

export const Integer = {
  mask: '9',
  tokens,
};

export const Float = ToFixed(2);

export const Time = {
  mask: '##:##',
};

export const Phone = {
  mask: '+###########0000',
  tokens,
};

export const PhoneNoCountry = {
  mask: '##########0000',
  tokens,
};

export function ToFixed(m = 2) {
  const mantissa = '0'.repeat(m);

  return {
    mask: '9.' + mantissa,
    tokens,
  };
}
