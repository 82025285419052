export enum CompanySize {
  XS = '1-3',
  S = '5-10',
  M = '11-20',
  L = '21-49',
  XL = '51-100',
  XXL = '101-250',
  XXXL = '251-500',
  XXXXL = '501-1000',
  XXXXXL = '1001-2000',
  XXXXXXL = '> 2000',
}
