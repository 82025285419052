import { watchEffect } from 'vue';

export function checkUntil(
  checkFunction: (resolve: () => void) => void,
): Promise<void> {
  return new Promise((resolve) => {
    checkFunction(resolve);

    watchEffect(() => checkFunction(resolve));
  });
}
