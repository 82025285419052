
import { computed, defineComponent, ref } from 'vue';
import uploadcare from 'uploadcare-widget';
import { IFilePromise } from '@/types/uploadcare-widget';

export default defineComponent({
  name: 'base-avatar',
  props: {
    modelValue: String,
    label: String,
    editable: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const backgroundImage = computed(() => {
      return props.modelValue ? `url("${props.modelValue}")` : '';
    });

    const isLoading = ref(false);
    const loadingProgress = ref(0);

    async function openFileDialog() {
      const dialog = uploadcare.openDialog<IFilePromise>([], 'file', {
        publicKey: process.env.VUE_APP_UPLOADCARE,
        tabs: 'file url',
        imagesOnly: true,
        crop: '1:1',
        imageShrink: '500x500',
      });

      dialog.done((filePromise) => {
        loadingProgress.value = 0;

        return filePromise
          .progress((file) => {
            isLoading.value = true;
            loadingProgress.value = file.uploadProgress;
          })
          .done((file) => {
            emit('update:modelValue', file.cdnUrl, isLoading.value);
          })
          .always(() => {
            isLoading.value = false;
          });
      });
    }

    return {
      isLoading,
      loadingProgress,
      backgroundImage,
      openFileDialog,
    };
  },
});
