
import { defineComponent, ref } from 'vue';

interface ITab {
  title: string;
  url: string;
}

export default defineComponent({
  name: 'terms-route',
  setup() {
    const tabs: ITab[] = [
      {
        title: 'Privacy Policy',
        url: 'https://www.iubenda.com/privacy-policy/44828816',
      },
      {
        title: 'Terms and Conditions',
        url: 'https://www.iubenda.com/terms-and-conditions/44828816',
      },
      {
        title: 'Cookie Solution Privacy Notice',
        url: 'https://www.iubenda.com/privacy-policy/44828816/cookie-policy',
      },
    ];
    const activeTab = ref<ITab>(tabs[0]);

    return {
      tabs,
      activeTab,
    };
  },
});
