
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { computed, defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
  props: {
    videoSrc: {
      type: String,
      default: '',
    },
    onSave: {
      type: Function,
      default: () => {
        /* noop */
      },
    },
  },

  setup(props) {
    const { hideModal } = useModals();

    const { videoSrc, onSave } = toRefs(props);
    const newVideoSrc = ref<string>(videoSrc.value);

    const isEqual = computed(() => newVideoSrc.value === videoSrc.value);

    function close(): void {
      hideModal(Modals.ADD_VIDEO_LINK_MODAL);
    }

    function saveVideoUrl(): void {
      if (!isEqual.value) {
        onSave.value?.(newVideoSrc.value);
        close();
      }
    }

    return {
      newVideoSrc,
      isEqual,
      saveVideoUrl,
      close,
    };
  },
});
