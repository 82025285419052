import {
  IRelevantProviders,
  IRequest,
  IShareRequest,
} from '@/common/interfaces';
import { RequestManager } from '@/utils';
import { BaseApi } from './base.api';
import { UserRole } from '@/common/enums';

export class RequestApi extends BaseApi<IRequest> {
  constructor(role: UserRole) {
    super('request', role);
  }

  async share(data: IShareRequest): Promise<IShareRequest> {
    const response = await RequestManager.post(`${this.path}/share`, data);
    return response.data;
  }

  async withdraw(requestId: string): Promise<IRequest> {
    const response = await RequestManager.post(
      `${this.path}/${requestId}/withdraw`,
    );
    return response.data;
  }

  async resend(requestId: string): Promise<IRequest> {
    const response = await RequestManager.post(
      `${this.path}/${requestId}/resend`,
    );
    return response.data;
  }

  async fetchRelevantSuppliers(requestId: string): Promise<IRelevantProviders> {
    const response = await RequestManager.get(
      `${this.path}/${requestId}/share/suppliers`,
    );
    return response.data;
  }
}
