import {
  ICommonFile,
  ICompany,
  IInviteRequest,
  IUserProfile,
} from '@/common/interfaces';
import { RequestManager } from '@/utils';
import { UserRole } from '@/common/enums';
import { BaseApi } from './base.api';

export class CompanyApi extends BaseApi<ICompany> {
  constructor(role: UserRole) {
    super('company', role);
  }

  /** Get current user company */
  async fetch() {
    const response = await RequestManager.get<ICompany>(this.path);
    return response.data;
  }

  async updateFiles(companyId: string, files: ICommonFile[]) {
    const response = await RequestManager.put<ICompany>(
      `${this.path}/${companyId}/files`,
      { files },
    );
    return response.data;
  }

  async fetchForRequest(requestId: string) {
    const response = await RequestManager.get<ICompany>(
      `${this.path}/request/${requestId}`,
    );
    return response.data;
  }

  async fetchMembers(companyId: string) {
    const response = await RequestManager.get<IUserProfile[]>(
      `${this.path}/${companyId}/members`,
    );
    return response.data;
  }

  async inviteMember(companyId: string, data: IInviteRequest) {
    const response = await RequestManager.post(
      `${this.path}/${companyId}/invite`,
      data,
    );
    return response.data;
  }
}
