import { UserRole } from '@/common/enums';
import { IEntity, IFetchAllParams, Paginated } from '@/common/interfaces';
import {
  SomethingWrongException,
  HttpException,
  EntityNotFoundException,
} from '@/common/exceptions';
import { RequestManager } from '@/utils';
import { defaultFetchAllParams } from '@/common/const';

export abstract class ApiRolesBasis {
  protected path = '';
  protected entityName = '';

  protected static prefixes = {
    [UserRole.User]: '',
    [UserRole.Admin]: 'admin',
  };

  protected constructor(entity: string, role: UserRole = UserRole.User) {
    const prefix = BaseApi.prefixes[role];
    this.path = `${prefix}/${entity}`;
    this.entityName = entity;
  }
}

export abstract class BaseApi<T extends IEntity> extends ApiRolesBasis {
  protected constructor(entity: string, role: UserRole) {
    super(entity, role);
  }

  async fetchOne(id: string): Promise<T> {
    if (!id || id === 'undefined' || id === 'null') {
      throw new SomethingWrongException('Id is not valid');
    }
    try {
      const { data } = await RequestManager.get<T>(`${this.path}/${id}`);
      return data;
    } catch (err) {
      if (err instanceof HttpException) {
        if (err.statusCode === 404) {
          throw new EntityNotFoundException(
            `${this.entityName} with id ${id} not found`,
          );
        }
      }

      throw err;
    }
  }

  async fetchAll(
    params: IFetchAllParams = defaultFetchAllParams,
  ): Promise<Paginated<T>> {
    const response = await RequestManager.get<Paginated<T>>(this.path, {
      params: {
        ...defaultFetchAllParams,
        ...params,
      },
    });
    return response.data;
  }

  async create(data: Partial<T>): Promise<T> {
    const response = await RequestManager.post<T>(this.path, data);
    return response.data;
  }

  async update(data: Partial<T>): Promise<T> {
    const response = await RequestManager.patch<T>(
      `${this.path}/${data.id}`,
      data,
    );
    return response.data;
  }

  async delete(id: string): Promise<T> {
    const response = await RequestManager.delete(`${this.path}/${id}`);
    return response.data;
  }
}
