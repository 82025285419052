import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_textarea = _resolveComponent("pp-textarea")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!

  return (_openBlock(), _createBlock(_component_pp_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card_header, null, {
        default: _withCtx(() => [
          _createTextVNode("Decline")
        ]),
        _: 1
      }),
      _createVNode(_component_pp_card_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_textarea, {
            modelValue: _ctx.data.reason,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.reason) = $event)),
            label: "Enter a message to satellite operator",
            placeholder: "We had declined your application according to..."
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_pp_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_button, {
            type: "secondary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_component_pp_button, { onClick: _ctx.ok }, {
            default: _withCtx(() => [
              _createTextVNode("Send")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}