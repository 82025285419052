import {
  IFetchAllParams,
  IMission,
  IMissionStoreState,
} from '@/common/interfaces';
import { MixpanelActions, useMixpanel } from '@/services/analytics';
import { defineStore } from 'pinia';

const { track } = useMixpanel();

export const useMissionsStore = defineStore('mission', {
  state: (): IMissionStoreState => ({
    list: [],
    count: 0,
    limit: 100,
    error: null,
    loading: true,
  }),

  getters: {
    getById: (state: IMissionStoreState) => (id: string) =>
      state.list.find((mission) => mission.id === id),
  },
  actions: {
    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.missions.fetchAll(params);
        const data = response.data ?? response;
        const count = response.count ?? data.length;
        this.setList(data, count);
        return data;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchDeleted(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);
      try {
        const response = await this.$api.missions.fetchDeleted(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchByParams(params: {
      field: string;
      operator: string;
      value: string;
    }) {
      this.setError(null);
      this.setLoading(true);

      try {
        const filter = `${params.field}||$${params.operator}||${params.value}`;
        const { data, count } = await this.$api.missions.fetchAll({ filter });
        this.setList(data, count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(missionId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.missions.fetchOne(missionId);

        const index = this.list.findIndex((el) => el.id === missionId);

        if (index > -1) {
          this.updateByIndex({
            index,
            mission: response,
          });
        } else {
          this.add(response);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchForRequest(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const mission = await this.$api.missions.fetchForRequest(requestId);

        this.setList([mission]);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async create(data: Partial<IMission>) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.missions.create(data);
        this.unshift(response);
        track(MixpanelActions.MISSION_CREATED, response);
        return response;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<IMission>) {
      this.setError(null);

      try {
        const response = await this.$api.missions.update(data);
        track(MixpanelActions.MISSION_UPDATED, response);
        this.setItemData(response);
      } catch (err) {
        this.setError(err);
      }
    },

    async remove(missionId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        await this.$api.missions.delete(missionId);

        const index = this.list.findIndex((el) => el.id === missionId);

        if (index !== -1) {
          this.list.splice(index, 1);
          this.count = this.count - 1;
        }

        return true;
      } catch (err) {
        this.setError(err);
        return false;
      } finally {
        this.setLoading(false);
      }
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: IMission[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    setItemData(mission: IMission) {
      const index = this.list.findIndex((item) => item.id === mission.id);

      if (index !== -1) {
        this.list[index] = mission;
      }
    },

    add(mission: IMission) {
      this.list.push(mission);
    },

    unshift(mission: IMission) {
      this.list.unshift(mission);
    },

    updateByIndex(payload: { index: number; mission: IMission }) {
      this.list[payload.index] = payload.mission;
    },

    reset() {
      this.$reset();
    },
  },
});
