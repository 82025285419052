import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_markdown_editor = _resolveComponent("pp-markdown-editor")!
  const _component_level_selector = _resolveComponent("level-selector")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    "max-width": "420"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_header, null, {
            default: _withCtx(() => [
              _createTextVNode("Payload")
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_body, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_markdown_editor, {
                modelValue: _ctx.payload.description,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.description) = $event)),
                label: "Description",
                placeholder: "What is it and how does it work?",
                allowedTags: _ctx.AllowedMarkdownTags,
                class: "__field"
              }, null, 8, ["modelValue", "allowedTags"]),
              _createVNode(_component_level_selector, {
                label: "Technology readiness level",
                options: _ctx.technologyReadinessLevels,
                modelValue: _ctx.payload.readinessLevel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.readinessLevel) = $event)),
                class: "__field"
              }, null, 8, ["options", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_pp_button, { onClick: _ctx.handleSave }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}