
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { computed, defineComponent, toRefs } from 'vue';

export default defineComponent({
  name: 'video-player',

  props: {
    src: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:src'],

  setup(props, { emit }) {
    const { showModal } = useModals();

    const { src } = toRefs(props);

    const isYouTube = computed(
      () =>
        src.value &&
        src.value.match(/(?:youtu|youtube)(?:\.com|\.be)\/([\w\W]+)/i),
    );

    const preparedSrc = computed(() => {
      if (isYouTube.value) {
        // reference: https://stackoverflow.com/a/27728417/13935391
        const regExp =
          /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?vi?=|&vi?=))([^#&?]*).*/;
        const videoId = src.value?.match(regExp)?.[1];
        return videoId ? `https://www.youtube.com/embed/${videoId}` : src.value;
      }

      return src.value;
    });

    function showAddVideoLinkModal() {
      showModal(Modals.ADD_VIDEO_LINK_MODAL, {
        videoSrc: src.value,
        onSave: (src: string) => {
          emit('update:src', src);
        },
      });
    }

    return {
      isYouTube,
      preparedSrc,
      showAddVideoLinkModal,
    };
  },
});
