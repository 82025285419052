
import { defineComponent, PropType, toRef } from 'vue';
import { IArchitecture } from '@/common/interfaces';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { AllowedMarkdownTags } from '@/common/const/allowed-markdown-tags';
import { CheckList, CheckListItem } from '@/components/check-list';

export default defineComponent({
  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CheckList,
    CheckListItem,
  },

  setup(props) {
    const { showModal } = useModals();

    const architecture = toRef(props, 'architecture');
    function showGroundStationModal(): void {
      showModal(Modals.GROUND_STATION_MODAL, { architecture });
    }

    return {
      showGroundStationModal,
      AllowedMarkdownTags,
    };
  },
});
