import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39c35e00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "__headway-anchor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_button = _resolveComponent("pp-button")!

  return (_openBlock(), _createBlock(_component_pp_button, {
    class: "app-headway",
    type: "text"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_pp_icon, { name: "help" })
      ])
    ]),
    _: 1
  }))
}