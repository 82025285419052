import { defineStore } from 'pinia';

import { UserRole } from '@/common/enums';
import { IUserProfile } from '@/common/interfaces';
import { MixpanelActions, useMixpanel } from '@/services/analytics';
import useMimic from '@/composables/useMimic';

const { track } = useMixpanel();

export interface IUserStoreState {
  data: IUserProfile;
  isMimic: boolean;
  loading: boolean;
  error: unknown;
}

const { setMimic, removeMimic } = useMimic();

export const useUserStore = defineStore('user', {
  state: (): IUserStoreState => ({
    error: null,
    loading: false,
    data: {
      id: undefined,
      name: undefined,
      surname: undefined,
      email: undefined,
      emailVerified: undefined,
      phoneNumber: undefined,
      phoneVerified: undefined,
      isAdmin: false,
      metadata: {},
      company: undefined,
    },
    isMimic: false,
  }),

  getters: {
    isAdmin: (state) => (state.isMimic ? false : state.data?.isAdmin === true),
    isSufficientInfo(state) {
      if (this.isAdmin) {
        return true;
      }

      const { company, name, surname } = state.data;
      return company?.name && name && surname;
    },
  },

  actions: {
    async fetch() {
      this.setError(null);
      this.setLoading(true);

      try {
        const user = await this.$api.user.fetch();
        this.setData(user);
        this.$setRole(this.isAdmin ? UserRole.Admin : UserRole.User);
        return user;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<IUserProfile>) {
      this.setError(null);

      try {
        const user = await this.$api.user.update({
          id: this.data.id,
          ...data,
        });

        this.setData(user);

        track(MixpanelActions.ACCOUNT_UPDATED, {
          email: data.metadata?.userEmail,
          phone: data.metadata?.phoneNumber,
          company: data.metadata?.company,
          first_name: data.name,
          last_name: data.surname,
        });

        return user;
      } catch (err) {
        this.setError(err);
      }
    },

    async saveLaunches(launchIds: string[]) {
      const launches = await this.$api.user.saveLaunches(launchIds);
      return launches;
    },

    async impersonate(companyId: string) {
      if (!this.isAdmin) {
        return;
      }

      this.setError(null);
      this.setLoading(true);

      try {
        const profile = await this.$api.user.impersonate(companyId);

        this.isMimic = true;
        this.data = {
          ...profile,
        };

        this.$setRole(UserRole.User);

        setMimic(companyId);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async unimpersonate() {
      const profile = await this.$api.user.unimpersonate();

      this.isMimic = false;
      this.data = {
        ...profile,
      };

      this.$setRole(UserRole.Admin);

      removeMimic();
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setData(user: IUserProfile) {
      Object.assign(this.data, user);
    },

    reset() {
      this.$reset();
    },
  },
});
