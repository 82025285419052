import { useAppStore } from './app';
import { useArchitectureStore } from './architecture';
import { useAuthStore } from './auth';
import { useCompanyStore } from './company';
import { useLaunchesDraftStore } from './launchesDraft';
import { useLaunchesStore } from './launches';
import { useMissionsStore } from './missions';
import { useRequestsStore } from './requests';
import { useSearchStore } from './search';
import { useUsersStore } from './users';
import { useUserStore } from './user';
import { useVehiclesStore } from './vehicles';
import { useViewsStore } from './views';

export const useStore = () => ({
  appStore: useAppStore(),
  architectureStore: useArchitectureStore(),
  authStore: useAuthStore(),
  companyStore: useCompanyStore(),
  launchesDraftStore: useLaunchesDraftStore(),
  launchesStore: useLaunchesStore(),
  missionsStore: useMissionsStore(),
  requestsStore: useRequestsStore(),
  searchStore: useSearchStore(),
  usersStore: useUsersStore(),
  userStore: useUserStore(),
  vehiclesStore: useVehiclesStore(),
  viewsStore: useViewsStore(),
});
