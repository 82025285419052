import { defineStore } from 'pinia';
import { IVehiclesStoreState } from '@/common/interfaces/vehicle/vehicles-store-state.interface';
import { IFetchAllParams, IVehicle } from '@/common/interfaces';

export const useVehiclesStore = defineStore('vehicles', {
  state: (): IVehiclesStoreState => ({
    list: [],
    count: 0,
    limit: 100,
    error: null,
    loading: true,
  }),
  getters: {
    getById: (state) => (id: string) =>
      state.list.find((vehicle: IVehicle) => vehicle.id === id),
  },
  actions: {
    async fetchAll(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.vehicles.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchAllDraft(params?: IFetchAllParams) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.vehicles.fetchAll(params);
        this.setList(response.data ?? response, response.count);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchOne(vehicleId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.vehicles.fetchOne(vehicleId);

        const index = this.list.findIndex((el) => el.id === vehicleId);

        if (index > -1) {
          this.updateByIndex({
            index,
            vehicle: response,
          });
        } else {
          this.add(response);
        }
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: Partial<IVehicle>) {
      this.setError(null);

      try {
        const response = await this.$api.vehicles.update(data);
        this.setItemData(response);
        return response;
      } catch (err) {
        this.setError(err);
        throw err;
      }
    },

    async create(data: Partial<IVehicle>) {
      this.setError(null);

      try {
        const response = await this.$api.vehicles.create(data);
        this.unshift(response);
        return response;
      } catch (err) {
        this.setError(err);
      }
    },

    async remove(id: string) {
      this.setError(null);

      try {
        await this.$api.vehicles.delete(id);
        this.deleteById(id);
      } catch (err) {
        this.setError(err);
      }
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: IVehicle[], count?: number) {
      this.list = list;
      this.count = count ?? list?.length ?? 0;
    },

    setItemData(vehicle: IVehicle) {
      const index = this.list.findIndex((item) => item.id === vehicle.id);

      if (index !== -1) {
        this.list[index] = vehicle;
      }
    },

    add(vehicle: IVehicle) {
      this.list.push(vehicle);
    },

    unshift(vehicle: IVehicle) {
      this.list.unshift(vehicle);
    },

    updateByIndex(payload: { index: number; vehicle: IVehicle }) {
      this.list[payload.index] = payload.vehicle;
    },

    deleteById(id: string) {
      const index = this.list.findIndex((item) => item.id === id);

      if (index !== -1) {
        this.list.splice(index, 1);
      }
    },
  },
});
