import { ErrorCodes } from '../enums';

export interface IHttpExceptionData {
  statusCode: number;
  message: string;
  error?: ErrorCodes | string;
}

export class HttpException extends Error {
  statusCode: number;
  errorCode?: ErrorCodes;

  constructor(
    data: IHttpExceptionData,
    params?: ConstructorParameters<typeof Error>[1],
  ) {
    const { statusCode, message, error } = data;
    super(message, params);
    this.statusCode = statusCode;
    if (error) {
      this.errorCode = error as ErrorCodes;
    }
  }
}
