import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f49c626"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-mission-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mission_details = _resolveComponent("mission-details")!
  const _component_color_divider = _resolveComponent("color-divider")!
  const _component_architectures = _resolveComponent("architectures")!
  const _component_project_gallery = _resolveComponent("project-gallery")!
  const _component_mission_requests = _resolveComponent("mission-requests")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mission_details, {
      mission: _ctx.mission,
      isLoading: _ctx.isMissionsLoading,
      class: "__section"
    }, null, 8, ["mission", "isLoading"]),
    _createVNode(_component_color_divider),
    _createVNode(_component_architectures, {
      mission: _ctx.mission,
      class: "__section"
    }, null, 8, ["mission"]),
    _createVNode(_component_color_divider),
    _createVNode(_component_project_gallery, {
      label: "Architecture files",
      images: _ctx.architectureFiles,
      onUpdate: _ctx.updateFiles,
      class: "__section"
    }, null, 8, ["images", "onUpdate"]),
    _createVNode(_component_color_divider),
    (_ctx.requests.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_mission_requests, {
            requests: _ctx.requests,
            class: "__section"
          }, null, 8, ["requests"]),
          _createVNode(_component_color_divider)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}