import { ValuesOf } from '@/utils/types';

export const OrbitAltitudeType = {
  SUBORBITAL: 'Suborbital',
  /** LEO is applicable for LEO and VLEO orbits */
  LEO: 'LEO',
  GEO: 'GEO',
  GTO: 'GTO',
  LUNAR: 'Lunar',
} as const;

export type OrbitAltitudeType = ValuesOf<typeof OrbitAltitudeType>;
