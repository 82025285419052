/** Old events */
export enum MixpanelActions {
  ACCOUNT_UPDATED = 'account_updated',
  APP_STARTED = 'app_started',
  AUTH_TOKEN_CHECK = 'auth_token_check',
  CREATE_MISSION_PAGE_OPENED = 'create_mission_page_opened',
  CREATE_REQUEST_DONE = 'create_request_done',
  CREATE_REQUEST_PAGE_OPENED = 'create_request_page_opened',
  LOGIN_GET_CODE = 'login_get_code',
  LOGIN_OPENED = 'login_opened',
  LOGIN_SUCCESS = 'login_success',
  MISSION_CREATED = 'mission_created',
  MISSION_UPDATED = 'mission_updated',
  REQUESTS_PAGE_OPENED = 'requests_page_opened',
  ROUTE_TO = 'route_to',
}
