import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa3e6a52"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "__label"
}
const _hoisted_2 = { class: "__input-inner" }
const _hoisted_3 = { class: "prefix" }
const _hoisted_4 = { class: "suffix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-field", { _disabled: _ctx.disabled }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "prefix", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.prefix), 1)
        ], true)
      ]),
      _withDirectives(_createElementVNode("input", {
        class: "__input",
        type: "date",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, null, 512), [
        [_vModelText, _ctx.value]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "suffix", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.suffix), 1)
        ], true)
      ])
    ])
  ], 2))
}