
import { useRoute, useRouter } from 'vue-router';
import { computed, defineComponent, watchEffect } from 'vue';
import { ICompany, ICommonFile, IMission, IView } from '@/common/interfaces';
import { EntityNotFoundException } from '@/common/exceptions';
import { ViewStatus } from '@/common/enums';

import useModals from '@/composables/useModals';
import { useStore } from '@/stores';
import { Modals } from '@/modals';

import CompanyDetails from '@/views/company/company-details.vue';
import MissionDetails from '@/views/mission/mission-details.vue';
import ActionsPanel from '@/components/common/actions-panel.vue';
import Architectures from '@/views/architecture/architectures.vue';
import ColorDivider from '@/components/color-divider/color-divider.vue';
import ProjectGallery from '@/components/project-gallery/project-gallery.vue';
import { ViewStatusDictionary } from '@/common/dictionaries/view-status.dict';

export default defineComponent({
  name: 'incoming-one-request-page',

  components: {
    CompanyDetails,
    ActionsPanel,
    MissionDetails,
    Architectures,
    ProjectGallery,
    ColorDivider,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const { companyStore, missionsStore, architectureStore, viewsStore } =
      useStore();

    watchEffect(() => {
      if (
        companyStore.error instanceof EntityNotFoundException ||
        missionsStore.error instanceof EntityNotFoundException ||
        viewsStore.error instanceof EntityNotFoundException
      ) {
        router.replace({
          name: 'not-found',
          params: { path: route.path.split('/').filter((v) => v) },
        });
      }
    });

    const company = computed<ICompany>(() => companyStore.list[0]);
    const isCompanyLoading = computed(() => companyStore.loading);

    const mission = computed<IMission>(() => missionsStore.list[0]);
    const isMissionsLoading = computed<boolean>(() => missionsStore.loading);

    const architectureImageList = computed<ICommonFile[]>(
      () => architectureStore.files,
    );
    const isArchitecturesLoading = computed<boolean>(
      () => architectureStore.loading,
    );

    const view = computed<IView>(() => viewsStore.list[0]);

    /* Response */
    const { showModal, showConfirm } = useModals();

    function decline() {
      const { responses } = view.value;
      const response =
        responses && [...responses].reverse().find((v) => !!v.reason);

      showModal(Modals.DECLINE_REQUEST_MODAL, {
        view: view.value,
        response: response,
        onFinish() {
          router.push({ name: 'incoming-requests' });
        },
      });
    }

    function moreInfo() {
      const { responses } = view.value;
      const response =
        responses && [...responses].reverse().find((v) => !!v.reason);

      showModal(Modals.INFO_REQUEST_MODAL, {
        view: view.value,
        response: response,
        onFinish() {
          router.push({ name: 'incoming-requests' });
        },
      });
    }

    function submit() {
      const { responses } = view.value;
      const response =
        responses && [...responses].reverse().find((v) => !!v.launch);

      showModal(Modals.SUBMIT_RESPONSE_MODAL, {
        view: view.value,
        response: response,
        onFinish() {
          router.push({ name: 'incoming-requests' });
        },
      });
    }

    async function revokeView() {
      const confirmed = await showConfirm('Do you want to revoke proposals?');

      if (confirmed) {
        viewsStore.revoke(view.value.id);
      }
    }

    return {
      company,
      isCompanyLoading,
      mission,
      isMissionsLoading,
      architectureImageList,
      isArchitecturesLoading,
      view,
      ViewStatus,
      decline,
      moreInfo,
      submit,
      revokeView,
      ViewStatusDictionary,
    };
  },
});
