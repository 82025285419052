<template>
  <section class="skeleton-mission">
    <pp-row class="__title">
      <pp-col cols="6">
        <pp-skeleton-loader type="h1" />
      </pp-col>
    </pp-row>

    <pp-row class="__container">
      <pp-col cols="8">
        <div class="__details">
          <pp-skeleton-loader v-for="item in 3" :key="item" type="h3" />
        </div>

        <pp-skeleton-loader style="height: 100px" />
      </pp-col>

      <pp-col>
        <pp-skeleton-loader v-for="item in 3" :key="item" type="h2" />
      </pp-col>
    </pp-row>
  </section>
</template>

<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.skeleton-mission {
  padding: gap('xl') gap('xl') gap('md');
  margin-bottom: gap('md');

  .__title {
    margin-top: gap('md');
    margin-bottom: gap('md');
  }

  .__container {
    align-items: flex-start;
    @include gap(0, 120px);
  }

  .__details {
    margin-bottom: gap('md');
  }

  .__details > .skeleton-loader {
    margin: gap('md') 0;
  }
}
</style>
