import { App } from 'vue';

import 'vue-toastification/dist/index.css';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';

export default {
  install(app: App) {
    const toastOptions: PluginOptions = {
      position: POSITION.BOTTOM_RIGHT,
      timeout: 5000,
    };

    app.use(Toast, toastOptions);
  },
};
