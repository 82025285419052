import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1614b6a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-screen" }
const _hoisted_2 = {
  key: 0,
  class: "__title"
}
const _hoisted_3 = {
  key: 1,
  class: "__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_icon = _resolveComponent("pp-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pp_icon, {
      class: _normalizeClass(["__icon", `_${_ctx.type}`]),
      name: _ctx.types[_ctx.type].icon,
      size: "120"
    }, null, 8, ["class", "name"]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ]))
}