import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12c906be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-table" }
const _hoisted_2 = {
  key: 0,
  class: "__head"
}
const _hoisted_3 = { class: "__row" }
const _hoisted_4 = { class: "__body" }
const _hoisted_5 = { class: "__row-content" }
const _hoisted_6 = ["data-label"]
const _hoisted_7 = { class: "__row-after-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideDefaultDeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.headers, (header) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "__col",
                key: header.text
              }, _toDisplayString(header.text), 1))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "__row",
          key: item[_ctx.itemKey] || index
        }, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.headers, (header) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "__col",
                key: header.text,
                "data-label": header.text,
                style: _normalizeStyle({ flexGrow: header.flex })
              }, [
                _renderSlot(_ctx.$slots, `item.${header.value}`, { item: item }, () => [
                  _createTextVNode(_toDisplayString(_ctx.getProp(item, header.value)), 1)
                ], true)
              ], 12, _hoisted_6))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, `row-after-content`, { item: item }, undefined, true)
          ])
        ]))
      }), 128))
    ])
  ]))
}