import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8382847a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-one-mission-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mission_details = _resolveComponent("mission-details")!
  const _component_color_divider = _resolveComponent("color-divider")!
  const _component_architectures = _resolveComponent("architectures")!
  const _component_project_gallery = _resolveComponent("project-gallery")!
  const _component_mission_requests = _resolveComponent("mission-requests")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mission?.company)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "company-link",
          to: {
        name: 'admin-one-company',
        params: { companyId: _ctx.mission.company.id },
      }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_pp_button, {
              title: `Open company ${_ctx.mission.company.name}`,
              type: "outline"
            }, null, 8, ["title"])
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createVNode(_component_mission_details, {
      mission: _ctx.mission,
      isLoading: _ctx.isMissionsLoading,
      class: "__section"
    }, null, 8, ["mission", "isLoading"]),
    _createVNode(_component_color_divider),
    (_ctx.mission)
      ? (_openBlock(), _createBlock(_component_architectures, {
          key: 1,
          mission: _ctx.mission,
          class: "__section"
        }, null, 8, ["mission"]))
      : _createCommentVNode("", true),
    _createVNode(_component_color_divider),
    (!_ctx.isArchitecturesLoading)
      ? (_openBlock(), _createBlock(_component_project_gallery, {
          key: 2,
          images: _ctx.architectureImages,
          onUpdate: _ctx.updateFiles,
          label: "Architecture Files",
          class: "__section"
        }, null, 8, ["images", "onUpdate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_color_divider),
    _createVNode(_component_mission_requests, {
      requests: _ctx.requests,
      class: "__section"
    }, null, 8, ["requests"]),
    _createVNode(_component_color_divider)
  ]))
}