
import { InteractionModalType } from '@/common/enums';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'interaction-modal',

  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<InteractionModalType>,
      require: true,
    },
  },

  emits: ['ok', 'cancel'],

  setup(props, { emit }) {
    const { hideModal } = useModals();

    const input = ref<string>('');

    function close() {
      hideModal(Modals.INTERACTION_MODAL);
      emit('cancel');
    }

    function ok() {
      if (props.type === InteractionModalType.CONFIRM) {
        emit('ok');
      } else {
        emit('ok', input.value);
      }

      close();
    }

    return {
      input,
      ok,
      close,
    };
  },
});
