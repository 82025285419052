import { useStore } from '@/stores';
import { RouteRecordRaw } from 'vue-router';
import AdminRequestPage from './admin-request-page.vue';
import AdminOneRequestPage from './admin-one-request-page.vue';
import AdminListRequestPage from './admin-list-request-page.vue';
import AdminShareRequestPage from './admin-share-request-page.vue';

export const adminRequestRoutes: RouteRecordRaw = {
  path: '/admin/request',
  component: AdminRequestPage,
  children: [
    {
      path: '/admin/request/create',
      name: 'admin-create-request',
      component: AdminOneRequestPage,
      beforeEnter: (to, from, next) => {
        const { missionsStore } = useStore();

        missionsStore.fetchAll();
        next();
      },
    },
    {
      path: '/admin/request/:requestId',
      name: 'admin-one-request',
      component: AdminOneRequestPage,
      beforeEnter: (to, from, next) => {
        const { requestsStore, missionsStore } = useStore();

        requestsStore.fetchOne(to.params.requestId as string);
        missionsStore.fetchAll();
        next();
      },
    },
    {
      path: '/admin/requests',
      name: 'admin-list-request',
      component: AdminListRequestPage,
      beforeEnter: (to, from, next) => {
        const { requestsStore } = useStore();
        requestsStore.fetchAll({
          limit: requestsStore.limit,
        });
        next();
      },
    },
    {
      path: '/admin/request/:requestId/share',
      name: 'admin-share-request',
      component: AdminShareRequestPage,
      beforeEnter: (to, from, next) => {
        const { companyStore, requestsStore } = useStore();

        requestsStore.fetchOne(to.params.requestId as string);
        companyStore.fetchAll({
          filter: 'serviceTypes||$notnull',
        });

        next();
      },
    },
  ],
};
