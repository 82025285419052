import { App } from 'vue';
import { createPinia } from 'pinia';
import { apiPiniaPlugin } from './pinia/api.plugin';
import { routerPiniaPlugin } from './pinia/router.plugin';

export default {
  install(app: App) {
    const pinia = createPinia().use(apiPiniaPlugin).use(routerPiniaPlugin);
    app.use(pinia);
  },
};
