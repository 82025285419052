import { useStore } from '@/stores';
import { watchEffect } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { AdminRoutes } from '../admin';
import { CompanyRoute } from '../company';
import DefaultPage from './the-default.page.vue';

export const DefaultRoute: RouteRecordRaw = {
  path: '',
  name: 'home',
  component: DefaultPage,
  beforeEnter: async (to, from, next) => {
    const { authStore, userStore } = useStore();
    const initialURL = authStore.initialURL;

    await new Promise((resolve) => {
      watchEffect(() => {
        if (userStore.loading === false) {
          resolve(true);
        }
      });
    });

    if (initialURL) {
      authStore.setUrl(null);
      return next({ path: initialURL });
    }

    if (userStore.isAdmin) {
      return next({ name: AdminRoutes.name });
    }

    next({ name: CompanyRoute.name });
  },
};
