import { HttpException } from './http.exception';

export class EntityNotFoundException extends HttpException {
  constructor(message: string) {
    super({
      statusCode: 404,
      message,
    });
  }
}
