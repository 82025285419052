import {
  IBaseLaunch,
  IBasePad,
  IBaseVehicle,
  ILaunch,
  ILaunchDate,
  ILaunchDiff,
  ILaunchOrbit,
  LaunchOrbitCorrection,
} from '@/common/interfaces';
import { LaunchType, PriceUnit } from '@/common/enums';

export class Launch implements IBaseLaunch {
  slug = '';
  name = '';
  vehicle: IBaseVehicle = {
    name: '',
  };
  pad: IBasePad = {
    name: '',
    location: '',
    country: '',
  };
  capacity = '';
  orbit: ILaunchOrbit = {
    inclinationType: undefined,
    altitudeRange: '',
    ltan: '',
  };
  orbitCorrection: LaunchOrbitCorrection = {
    inclination: '',
    altitudeRange: '',
    ltan: '',
  };
  knownPayloads = '';
  rescheduleOptions = '';
  score = '';
  type = LaunchType.RIDESHARE;
  status = '';
  isMaidenLaunch = false;
  isLastMinuteDeal = false;
  bookingDeadline: ILaunchDate = {} as ILaunchDate;
  integrationDate: ILaunchDate = {} as ILaunchDate;
  launchDate: ILaunchDate = {} as ILaunchDate;
  estimatedDate = false;
  deliveryDeadline: ILaunchDate = {} as ILaunchDate;
  launchDateVerified = false;
  unitPrice = {
    unit: PriceUnit.KG,
    value: '',
  };
  payloadTypes = [];
  maxCubesatSize = undefined;
  maxPocketqubeSize = undefined;
  maxPayloadMass = undefined;
  tags: string[] = [];
  comments = '';

  constructor(launch?: Partial<ILaunch>) {
    if (launch) {
      Object.assign(this, launch);

      if (!this.vehicle) {
        this.vehicle = { name: '' };
      }
    }
  }

  public joinDiff(diffs?: ILaunchDiff[]): Launch {
    if (!diffs) {
      return this;
    }

    diffs.forEach((diff) => {
      if (diff.field in this) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[diff.field] = diff.value;
      }
    });

    return this;
  }

  public toPlainObject(): IBaseLaunch {
    return Object.assign({}, this);
  }
}
