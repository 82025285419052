import { defineStore } from 'pinia';
import { SubsystemType } from '@/common/enums';
import {
  CreateArchitectureDto,
  IArchitecture,
  IArchitectureStoreState,
  ICommonFile,
} from '@/common/interfaces';
import { getProperty, setProperty } from '@/utils';
import { Subsystem } from '@/common/types/subsystem.type';

interface IUpdateSubsystem<T> {
  subsystem: SubsystemType;
  subsystemId?: string;
  architectureId: string;
  data: Partial<T>;
}

// How to deal with interfaces and not types?
type IDeleteSubsystem = Pick<
  IUpdateSubsystem<Subsystem>,
  'subsystem' | 'subsystemId' | 'architectureId'
>;

export const useArchitectureStore = defineStore('architecture', {
  state: (): IArchitectureStoreState => ({
    list: [] as IArchitecture[],
    currentIndex: 0,
    error: null,
    loading: false,
  }),

  getters: {
    files: (state: IArchitectureStoreState) =>
      state.list[state.currentIndex]?.files || [],
    current: (state: IArchitectureStoreState) => state.list[state.currentIndex],
  },

  actions: {
    async create(dto: CreateArchitectureDto) {
      this.setError(null);
      this.setLoading(true);

      try {
        const { missionId, data } = dto;

        const response = await this.$api.architecture.createForMission(
          missionId,
          data,
        );

        this.add(response);
        return this.list;
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchForMission(missionId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.architecture.fetchAllForMission(
          missionId,
        );

        this.setList(response);
        this.setCurrentIndex(0);
        this.setLoading(false);

        return response;
      } catch (err) {
        this.setError(err);
        this.setLoading(false);
      }
    },

    async fetchForRequest(requestId: string) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.architecture.fetchForRequest(
          requestId,
        );
        this.setList(response);
        this.setCurrentIndex(0);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async fetchByListId(archListId: string[]) {
      this.setError(null);
      this.setLoading(true);

      try {
        const archList: Promise<IArchitecture>[] = [];
        for (let i = 0; i < archListId.length; i++) {
          archList.push(this.$api.architecture.fetchOne(archListId[i]));
        }
        const response = await Promise.all(archList);

        this.setList(response);
        this.setCurrentIndex(0);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async update(data: IArchitecture) {
      this.setError(null);
      this.setLoading(true);

      try {
        const response = await this.$api.architecture.update(data);
        this.setData(response);
      } catch (err) {
        this.setError(err);
      } finally {
        this.setLoading(false);
      }
    },

    async updateSubsystem<T>(dto: IUpdateSubsystem<T>) {
      try {
        let response: Partial<IArchitecture>;

        if (dto.subsystemId) {
          response = await this.$api.subsystems.update(
            dto.subsystem,
            dto.subsystemId,
            dto.data,
          );
        } else {
          response = await this.$api.subsystems.create(
            dto.architectureId,
            dto.subsystem,
            dto.data,
          );
        }

        this.setSubsystem({
          architectureId: dto.architectureId,
          subsystemId: dto.subsystemId,
          subsystem: dto.subsystem,
          data: response,
        });
      } catch (err) {
        // TODO: CATCH ERROR PROPERLY
      }
    },

    async updateFiles(dto: { id: string; files: ICommonFile[] }) {
      try {
        const response = await this.$api.architecture.updateFiles(
          dto.id,
          dto.files,
        );

        this.setData(response);
      } catch (err) {
        // TODO: CATCH ERROR PROPERLY
      }
    },

    async deleteSubsystem(dto: IDeleteSubsystem) {
      try {
        if (!dto.subsystemId) {
          throw new Error('Subsystem ID is required');
        }

        await this.$api.subsystems.delete(
          dto.subsystem,
          dto.subsystemId,
          dto.architectureId,
        );

        const { subsystem, architectureId } = dto;
        const architecture = this.list.find((v) => v.id === architectureId);

        if (!architecture) {
          throw new Error(`Architecture with id ${architectureId} not found!`);
        }

        const prop = getProperty(architecture, subsystem);

        if (prop instanceof Array) {
          const index = prop.findIndex((v) => v.id === dto.subsystemId);
          const isExist = index !== -1;

          if (isExist) {
            setProperty(
              architecture,
              subsystem,
              prop.filter((v, i) => i !== index),
            );
          }
        } else {
          setProperty(architecture, subsystem, {});
        }
      } catch (err) {
        // TODO: CATCH ERROR PROPERLY
      }
    },

    setCurrentIndex(index: number) {
      this.currentIndex = index;
    },

    setError(error: unknown) {
      this.error = error;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    setList(list: IArchitecture[]) {
      this.list = list;
    },

    setData(data: IArchitecture) {
      const index = this.list.findIndex((mission) => mission.id === data.id);

      if (index !== -1) {
        this.list[index] = data;
      }
    },

    add(data: IArchitecture) {
      this.list.push(data);
    },

    setSubsystem<T>(dto: IUpdateSubsystem<T>) {
      const { subsystem, architectureId, data } = dto;

      if (!data || !subsystem || !architectureId) {
        throw new Error(`Could not update subsystem, wrong data!`);
      }

      const architecture = this.list.find((v) => v.id === architectureId);

      if (!architecture) {
        throw new Error(`Architecture with id ${architectureId} not found!`);
      }

      const prop = getProperty(architecture, subsystem);

      if (!prop) {
        setProperty(architecture, subsystem, data);
      } else if (prop instanceof Array) {
        const index = prop.findIndex((v) => v.id === dto.subsystemId);
        const isExist = index !== -1;

        if (isExist) {
          Object.assign(prop[index], data);
        } else {
          prop.push(data);
        }
      } else {
        Object.assign(prop, data);
      }
    },

    reset() {
      this.$reset();
    },
  },
});
