
import { computed, defineComponent, ref, toRefs } from 'vue';
import uploadcare from 'uploadcare-widget';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { ICommonFile } from '@/common/interfaces';
import { isImage } from '@/utils/is-image';
import { IFilesPromise } from '@/types/uploadcare-widget';

export default defineComponent({
  name: 'project-gallery-modal',
  components: {},

  props: {
    images: {
      type: Object as () => ICommonFile[],
      required: true,
    },
    saveImages: {
      type: Function,
      default: () => {
        //dummy
      },
    },
  },

  setup(props) {
    const { images, saveImages } = toRefs(props);
    const { hideModal } = useModals();

    const isLoading = ref<boolean>(false);
    const activeIndex = ref<number>(images.value.length ? 0 : -1);
    const loadedImages = ref<ICommonFile[]>([...images.value]);

    const selectedImage = computed<ICommonFile>(
      () => loadedImages.value?.[activeIndex.value],
    );

    const isSelectedImg = computed<boolean>(() => {
      if (selectedImage.value) {
        return isImage(selectedImage.value.name);
      } else {
        return false;
      }
    });

    function isImg(file: ICommonFile): boolean {
      return isImage(file.name);
    }

    function chooseImage(index: number): void {
      activeIndex.value = index;
    }

    function close(): void {
      hideModal(Modals.PROJECT_GALLERY_MODAL);
    }

    async function openFileDialog(): Promise<void> {
      const dialog = uploadcare.openDialog<IFilesPromise>([], 'file', {
        publicKey: process.env.VUE_APP_UPLOADCARE,
        tabs: 'file url gdrive gphotos dropbox onedrive',
        multiple: true,
      });

      dialog
        .done((result) => {
          isLoading.value = true;

          result.promise().done(() => {
            result.files().forEach((filePromise) => {
              filePromise.done((file) => {
                loadedImages.value.push({
                  url: file.cdnUrl,
                  description: '',
                  name: file.name,
                });

                activeIndex.value = loadedImages.value.length - 1;

                setTimeout(scrollToEnd, 0);
              });
            });
          });
        })
        .always(() => {
          isLoading.value = false;
        });
    }

    function save(): void {
      saveImages.value(loadedImages.value);
      close();
    }

    function removeImage(index: number): void {
      loadedImages.value.splice(index, 1);
    }

    function scrollToEnd(): void {
      const previewsNode = document.querySelector('.__previews') as HTMLElement;

      previewsNode.scrollLeft = previewsNode.offsetWidth;
    }

    return {
      loadedImages,
      isLoading,
      activeIndex,
      selectedImage,
      isSelectedImg,
      isImg,
      removeImage,
      chooseImage,
      close,
      openFileDialog,
      save,
    };
  },
});
