import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73eccd86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "request-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_select = _resolveComponent("pp-select")!
  const _component_sum_field = _resolveComponent("sum-field")!
  const _component_pp_checkbox = _resolveComponent("pp-checkbox")!
  const _component_pp_chip_group = _resolveComponent("pp-chip-group")!
  const _component_date_field = _resolveComponent("date-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pp_select, {
      label: "Budget expectation",
      modelValue: _ctx.request.budgetExpectation,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.request.budgetExpectation) = $event)),
      options: _ctx.budgetExpectationOptions
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_component_sum_field, {
      label: "Maximum budget value",
      modelValue: _ctx.request.budgetAmount,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.request.budgetAmount) = $event)),
      currency: _ctx.request.budgetCurrency,
      "onUpdate:currency": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.request.budgetCurrency) = $event))
    }, null, 8, ["modelValue", "currency"]),
    _createVNode(_component_pp_checkbox, {
      modelValue: _ctx.request.fundraising,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.request.fundraising) = $event))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Are you fundraising for this project now? ")
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_pp_chip_group, {
      modelValue: _ctx.request.supplierSelectionCriteria,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.request.supplierSelectionCriteria) = $event)),
      options: _ctx.supplierSelectionCriteriaOptions,
      multiple: "",
      label: "Criteria for supplier selection"
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_component_pp_select, {
      label: "Launch date flexibility",
      modelValue: _ctx.request.launchDateFlexibility,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.request.launchDateFlexibility) = $event)),
      options: _ctx.launchDateFlexibilityOptions
    }, null, 8, ["modelValue", "options"]),
    _createVNode(_component_pp_select, {
      label: "I want to select a supplier in",
      modelValue: _ctx.request.supplierSelectionTime,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.request.supplierSelectionTime) = $event)),
      options: _ctx.supplierSelectionTimeOptions
    }, null, 8, ["modelValue", "options"]),
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_date_field, {
          key: 0,
          modelValue: _ctx.request.deadline,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.request.deadline) = $event)),
          label: "Deadline"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}