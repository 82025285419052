
import { defineComponent, toRefs } from 'vue';
import ProjectGalleryCard from '@/components/project-gallery/project-gallery-card.vue';
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { ICommonFile } from '@/common/interfaces';

export default defineComponent({
  name: 'project-gallery',

  props: {
    label: {
      type: String,
    },
    images: {
      type: Object as () => ICommonFile[],
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ProjectGalleryCard,
  },

  emits: ['update'],

  setup(props, { emit }) {
    const { images } = toRefs(props);
    const { showModal } = useModals();

    function saveImages(images: ICommonFile[]): void {
      emit('update', images);
    }

    function showProjectGalleryModal() {
      showModal(Modals.PROJECT_GALLERY_MODAL, {
        images,
        saveImages,
      });
    }

    return {
      showProjectGalleryModal,
    };
  },
});
