import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7704d580"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs-panel" }
const _hoisted_2 = { class: "__tabs" }
const _hoisted_3 = { class: "__list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "__actions" }
const _hoisted_6 = { class: "__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_dragscroll = _resolveDirective("dragscroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tab[_ctx.keyBy],
            onClick: () => _ctx.changeTab(tab[_ctx.keyBy]),
            class: _normalizeClass(["__list-el", { _active: tab[_ctx.keyBy] === _ctx.currentTab }])
          }, _toDisplayString(tab[_ctx.valueBy]), 11, _hoisted_4))
        }), 128))
      ])), [
        [_directive_dragscroll]
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, 'tabs-actions', {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _renderSlot(_ctx.$slots, _ctx.currentTab, {}, undefined, true)
    ])
  ]))
}