
import { defineComponent, PropType } from 'vue';
import { IArchitecture } from '@/common/interfaces';

import OrbitItem from './subsystems/orbit/orbit-item.vue';
import PayloadItem from './subsystems/payload/payload-item.vue';
import LicensingItem from './subsystems/licensing/licensing-item.vue';
import InsuranceItem from './subsystems/insurance/insurance-item.vue';
import ConfigurationItem from './subsystems/configuration/configuration-item.vue';
import GroundStationItem from './subsystems/ground-station/ground-station-item.vue';
import ExportControlItem from './subsystems/export-control/export-control-item.vue';
import PropulsionSystemItem from './subsystems/propulsion-system/propulsion-system-item.vue';
import OperationalAlgorithmItem from '@/views/architecture/subsystems/operational-algorithm/operational-algorithm-item.vue';

export default defineComponent({
  name: 'mission-architectures',

  props: {
    architecture: {
      type: Object as PropType<IArchitecture>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    OperationalAlgorithmItem,
    OrbitItem,
    PayloadItem,
    ConfigurationItem,
    GroundStationItem,
    ExportControlItem,
    PropulsionSystemItem,
    LicensingItem,
    InsuranceItem,
  },
});
