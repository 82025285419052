import { IModalRules } from '@/common/interfaces';

export function getDtoOnRules<T>(
  rules: IModalRules,
  dto: Record<string, unknown>,
): Partial<T> {
  return Object.keys(rules).reduce((acc, key) => {
    const isValid = rules[key];

    Object.assign(acc, {
      [key]: isValid ? dto[key] : null,
    });

    return acc;
  }, {});
}
