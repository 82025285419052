
import { computed, defineComponent, toRefs } from 'vue';
import useModals from '@/composables/useModals';
import CustomTable from '@/components/table/custom-table.vue';
import UserIcon from '@/views/user/user-icon.vue';
import { useToast } from 'vue-toastification';
import { ICompany, ITableData } from '@/common/interfaces';
import { DateTime } from 'luxon';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import SkeletonTableList from '@/components/skeletons/skeleton-table-list.vue';

export default defineComponent({
  name: 'admin-companies-table',

  components: {
    SkeletonTableList,
    UserIcon,
    CustomTable,
  },

  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const toast = useToast();
    const companyStore = useCompanyStore();
    const userStore = useUserStore();
    const router = useRouter();
    const { showConfirm } = useModals();

    const { currentPage, limit } = toRefs(props);

    const companyList = computed<ICompany[]>(() => companyStore.list);
    const isLoading = computed<boolean>(() => companyStore.loading);

    const tableHeader = [
      {
        text: 'Company name',
        value: 'name',
      },
      {
        text: 'Country',
        value: 'country',
      },
      {
        text: 'Service type',
        value: 'serviceTypes',
      },
    ];

    const tableData = computed<ITableData>(() => ({
      headers: [
        ...tableHeader,
        {
          text: 'Date created',
          value: 'createdAt',
        },
        {
          text: 'Created by',
          value: 'createdBy',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
      items: companyList.value.map((item) => ({
        ...item,
        createdAt: item.createdAt
          ? DateTime.fromISO(item.createdAt).toLocaleString()
          : null,
      })),
    }));

    function itemTransform(item: string[]): string {
      return [...item].join(', ');
    }

    async function remove(item: ICompany): Promise<void> {
      const confirmed = await showConfirm(
        `Do you really want to delete the operator's company?`,
      );

      if (!confirmed) {
        return;
      }

      const companyRemoved = await companyStore.remove(item.id);

      if (companyRemoved) {
        toast.success(`Company ${item.name} removed`);

        companyStore.fetchAll({
          limit: limit.value,
          skip: (currentPage.value - 1) * limit.value,
        });
      }
    }

    async function impersonate(company: ICompany): Promise<void> {
      await userStore.impersonate(company.id);
      router.push({ name: 'company' });
    }

    return {
      // tabList,
      tableData,
      isLoading,
      // tableDataArchive,
      itemTransform,
      remove,
      impersonate,
    };
  },
});
