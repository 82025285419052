import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93c804c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "company-details" }
const _hoisted_2 = { class: "__company" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "__slug"
}
const _hoisted_5 = { class: "__location" }
const _hoisted_6 = { class: "__funding" }
const _hoisted_7 = { class: "__size" }
const _hoisted_8 = {
  key: 1,
  class: "__foundation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_avatar = _resolveComponent("company-avatar")!
  const _component_pp_text_field = _resolveComponent("pp-text-field")!
  const _component_pp_icon = _resolveComponent("pp-icon")!
  const _component_pp_join_text = _resolveComponent("pp-join-text")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_select = _resolveComponent("pp-select")!
  const _component_pp_markdown_editor = _resolveComponent("pp-markdown-editor")!
  const _component_company_links = _resolveComponent("company-links")!
  const _component_file_list = _resolveComponent("file-list")!
  const _component_pp_chip_group = _resolveComponent("pp-chip-group")!
  const _component_pp_col = _resolveComponent("pp-col")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_pp_row = _resolveComponent("pp-row")!
  const _component_project_gallery = _resolveComponent("project-gallery")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_company_avatar, {
        company: _ctx.company,
        size: "lg",
        editable: !_ctx.readonly,
        "onUpdate:company": _ctx.handleUpdateAvatar,
        class: "__company-avatar"
      }, null, 8, ["company", "editable", "onUpdate:company"]),
      (_ctx.readonly)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.company.name), 1))
        : (_openBlock(), _createBlock(_component_pp_text_field, {
            key: 1,
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            modelModifiers: { trim: true },
            placeholder: "Company name",
            class: "__company-name",
            onBlur: _ctx.updateName
          }, null, 8, ["modelValue", "onBlur"]))
    ]),
    _createVNode(_component_pp_row, { class: "__info" }, {
      default: _withCtx(() => [
        _createVNode(_component_pp_col, {
          cols: "12",
          md: "6",
          order: "2",
          mdOrder: "1"
        }, {
          default: _withCtx(() => [
            (_ctx.isAdmin)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_pp_icon, {
                    name: "link",
                    class: "__size-icon"
                  }),
                  (!_ctx.editSlugState)
                    ? (_openBlock(), _createBlock(_component_pp_join_text, {
                        key: 0,
                        placeholder: "Slug",
                        text: [_ctx.company.slug],
                        readonly: _ctx.readonly,
                        onClick: _ctx.onSlugClick,
                        class: "_inline-flex"
                      }, null, 8, ["text", "readonly", "onClick"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_pp_text_field, {
                          modelValue: _ctx.slugProxy,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.slugProxy) = $event)),
                          autofocus: "",
                          placeholder: "Enter company slug"
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_pp_button, {
                          class: "__save-slug-btn",
                          type: "secondary",
                          onClick: _ctx.generateSlug
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_pp_icon, { name: "settings" })
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_pp_button, {
                          class: "__save-slug-btn",
                          type: "secondary",
                          onClick: _ctx.saveSlug
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_pp_icon, { name: "check" })
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_pp_button, {
                          class: "__save-slug-btn",
                          type: "secondary",
                          onClick: _ctx.clearSlugChanges
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_pp_icon, { name: "clear" })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ], 64))
                ])), [
                  [_directive_click_outside, _ctx.changeSlugEditable]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_pp_join_text, {
                icon: "pin",
                placeholder: "Location",
                text: [_ctx.company.country, _ctx.company.city],
                readonly: _ctx.readonly,
                onClick: _ctx.showCompanyLocationModal,
                class: "_inline-flex"
              }, null, 8, ["text", "readonly", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_pp_join_text, {
                icon: "funding",
                placeholder: "Funding",
                text: [
              _ctx.company.fundingType,
              _ctx.company.fundingStage,
              _ctx.company.currentlyFundraising ? 'Fundraising now' : '',
            ],
                readonly: _ctx.readonly,
                onClick: _ctx.showCompanyFundingModal,
                class: "_inline-flex"
              }, null, 8, ["text", "readonly", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.editTeamSizeState)
                ? (_openBlock(), _createBlock(_component_pp_join_text, {
                    key: 0,
                    placeholder: "Company size",
                    icon: "team-size",
                    text: [_ctx.size],
                    readonly: _ctx.readonly,
                    onClick: _cache[2] || (_cache[2] = () => (_ctx.editTeamSizeState = true)),
                    class: "_inline-flex"
                  }, null, 8, ["text", "readonly"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_pp_icon, {
                      name: "team-size",
                      class: "__size-icon"
                    }),
                    _createVNode(_component_pp_select, {
                      modelValue: _ctx.size,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.size) = $event)),
                      options: _ctx.companySize,
                      onBlur: _cache[4] || (_cache[4] = () => (_ctx.editTeamSizeState = false)),
                      class: "__size-select"
                    }, null, 8, ["modelValue", "options"])
                  ], 64))
            ]),
            (_ctx.isAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_pp_icon, {
                    name: "team-size",
                    class: "__size-icon"
                  }),
                  (!_ctx.editFoundationState)
                    ? (_openBlock(), _createBlock(_component_pp_join_text, {
                        key: 0,
                        placeholder: "Foundation",
                        readonly: _ctx.readonly,
                        text: [_ctx.company.foundation],
                        onClick: _cache[5] || (_cache[5] = () => (_ctx.editFoundationState = true))
                      }, null, 8, ["readonly", "text"]))
                    : (_openBlock(), _createBlock(_component_pp_text_field, {
                        key: 1,
                        modelValue: _ctx.foundation,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.foundation) = $event)),
                        autofocus: "",
                        onBlur: _ctx.updateFoundation,
                        onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => ($event.target.blur()), ["enter"]))
                      }, null, 8, ["modelValue", "onBlur"]))
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_pp_markdown_editor, {
              autosize: "",
              modelValue: _ctx.shortDescriptionProxy,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.shortDescriptionProxy) = $event)),
              label: "Short description",
              placeholder: "Add company short description",
              readonly: _ctx.readonly,
              onSave: _ctx.updateShortDescription,
              class: "__description"
            }, null, 8, ["modelValue", "readonly", "onSave"]),
            _createVNode(_component_pp_markdown_editor, {
              autosize: "",
              modelValue: _ctx.description,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.description) = $event)),
              label: "Description",
              placeholder: "Add company description",
              readonly: _ctx.readonly,
              onSave: _ctx.updateDescription,
              class: "__description"
            }, null, 8, ["modelValue", "readonly", "onSave"]),
            _createVNode(_component_company_links, {
              modelValue: _ctx.links,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.links) = $event)),
              readonly: _ctx.readonly,
              class: "__list"
            }, null, 8, ["modelValue", "readonly"]),
            _createVNode(_component_file_list, {
              modelValue: _ctx.files,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.files) = $event)),
              label: "Files",
              readonly: _ctx.readonly,
              class: "__list"
            }, null, 8, ["modelValue", "readonly"]),
            (_ctx.serviceTypesVisible)
              ? (_openBlock(), _createBlock(_component_pp_chip_group, {
                  key: 2,
                  label: "Service type",
                  modelValue: _ctx.serviceTypes,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.serviceTypes) = $event)),
                  options: _ctx.serviceTypeList,
                  multiple: "",
                  class: "__list"
                }, null, 8, ["modelValue", "options"]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }),
        _createVNode(_component_pp_col, {
          cols: "12",
          md: "6",
          order: "1",
          mdOrder: "2",
          class: "__col-video"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_video_player, {
              src: _ctx.videoUrl,
              "onUpdate:src": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.videoUrl) = $event)),
              readonly: _ctx.readonly
            }, null, 8, ["src", "readonly"])
          ]),
          _: 1
        })
      ]),
      _: 3
    }),
    (_ctx.isAdmin)
      ? (_openBlock(), _createBlock(_component_project_gallery, {
          key: 0,
          images: _ctx.company.galleryFiles || [],
          readonly: _ctx.readonly,
          label: "Company images",
          onUpdate: _ctx.updateFiles
        }, null, 8, ["images", "readonly", "onUpdate"]))
      : _createCommentVNode("", true)
  ]))
}