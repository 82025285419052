<template>
  <ul class="check-list">
    <slot />
  </ul>
</template>
<script lang="ts"></script>
<style lang="scss" scoped>
@import '@precious-payload/uikit-vue';

.check-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include gap('sm');
}
</style>
