
import { defineComponent, ref } from 'vue';
import useModals from '@/composables/useModals';
import { IResponse, IView } from '@/common/interfaces';
import { Modals } from '@/modals';
import DeclineRequestForm from './decline-request-form.vue';
import alertScreen from '@/components/modals/alert-screen.vue';

export default defineComponent({
  name: 'decline-request-modal',
  components: {
    DeclineRequestForm,
    alertScreen,
  },
  props: {
    view: {
      type: Object as () => IView,
      required: true,
    },
    response: {
      type: Object as () => IResponse,
    },
  },
  emits: ['finish'],
  setup(props, { emit }) {
    const { hideModal } = useModals();
    const step = ref(1);

    function close() {
      hideModal(Modals.DECLINE_REQUEST_MODAL);
    }

    function finish() {
      close();
      emit('finish');
    }

    return {
      step,
      close,
      finish,
    };
  },
});
