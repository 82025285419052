
import useModals from '@/composables/useModals';
import { Modals } from '@/modals';
import { defineComponent } from 'vue';
import alertScreen from './alert-screen.vue';

export default defineComponent({
  name: 'alert-modal',

  components: {
    alertScreen,
  },

  props: {
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },

  emits: ['cancel'],

  setup(props, { emit }) {
    const { hideModal } = useModals();

    function close() {
      hideModal(Modals.ALERT_MODAL);
      emit('cancel');
    }

    return {
      close,
    };
  },
});
