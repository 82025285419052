import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import { useStore } from '@/stores';
import { AccessType } from '@/common/enums/access';
import { LayoutType } from '@/common/enums/layout';
import { MixpanelActions, useMixpanel } from '@/services';
import { useInvites } from '@/composables/useInvites';

import ProxyPage from '@/components/proxy-page.vue';
import AuthPage from './auth.page.vue';
import TokenPage from './token.page.vue';

const { track } = useMixpanel();

export const AuthRoute: RouteRecordRaw = {
  path: '/auth',
  component: ProxyPage,
  meta: {
    access: AccessType.Public,
    layout: LayoutType.Simplified,
  },
  redirect: { name: 'auth' },

  children: [
    {
      name: 'auth',
      path: '',
      component: AuthPage,
      beforeEnter: (to, from, next) => {
        const { authStore } = useStore();
        const { saveInvitation } = useInvites();

        const isLoggedIn = computed(() => authStore.isLoggedIn);

        if (isLoggedIn.value) {
          next({
            name: 'home',
          });
        } else {
          track(MixpanelActions.LOGIN_OPENED);

          const { invitation, email } = to.query;
          if (to.query.invitation && to.query.email) {
            saveInvitation({
              uuid: invitation as string,
              email: email as string,
            });
          }

          next();
        }
      },
    },

    {
      name: 'auth-callback',
      path: 'callback',
      component: TokenPage,
    },
  ],
};
