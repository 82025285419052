import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pp_card_header = _resolveComponent("pp-card-header")!
  const _component_pp_text_field = _resolveComponent("pp-text-field")!
  const _component_pp_chip_group = _resolveComponent("pp-chip-group")!
  const _component_sum_field = _resolveComponent("sum-field")!
  const _component_pp_select = _resolveComponent("pp-select")!
  const _component_pp_card_body = _resolveComponent("pp-card-body")!
  const _component_pp_button = _resolveComponent("pp-button")!
  const _component_pp_spacer = _resolveComponent("pp-spacer")!
  const _component_pp_card_actions = _resolveComponent("pp-card-actions")!
  const _component_pp_card = _resolveComponent("pp-card")!
  const _component_pp_modal = _resolveComponent("pp-modal")!

  return (_openBlock(), _createBlock(_component_pp_modal, {
    onClose: _ctx.close,
    "max-width": "520"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_pp_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_pp_card_header, null, {
            default: _withCtx(() => [
              _createTextVNode("Insurance")
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_body, null, {
            default: _withCtx(() => [
              _createVNode(_component_pp_text_field, {
                label: "Name",
                modelValue: _ctx.data.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.name) = $event)),
                class: "__field"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_pp_chip_group, {
                modelValue: _ctx.data.type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.type) = $event)),
                label: "Insurance type",
                options: _ctx.insuranceType,
                class: "__field"
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_sum_field, {
                label: "Sum insured",
                modelValue: _ctx.data.amount,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.amount) = $event)),
                currency: _ctx.data.currency,
                "onUpdate:currency": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.currency) = $event)),
                class: "__field"
              }, null, 8, ["modelValue", "currency"]),
              _createVNode(_component_pp_select, {
                label: "Contractor",
                modelValue: _ctx.data.contractor,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.contractor) = $event)),
                options: _ctx.contractors,
                class: "__field"
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_pp_text_field, {
                label: "Status",
                modelValue: _ctx.data.status,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.status) = $event)),
                class: "__field"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_pp_card_actions, null, {
            default: _withCtx(() => [
              (_ctx.data.id)
                ? (_openBlock(), _createBlock(_component_pp_button, {
                    key: 0,
                    type: "error",
                    onClick: _ctx.deleteItem,
                    title: _ctx.agreedWithDelete ? 'Confirm delete' : 'Delete'
                  }, null, 8, ["onClick", "title"]))
                : _createCommentVNode("", true),
              _createVNode(_component_pp_spacer),
              _createVNode(_component_pp_button, {
                type: "secondary",
                onClick: _ctx.close,
                title: "Cancel"
              }, null, 8, ["onClick"]),
              _createVNode(_component_pp_button, {
                onClick: _ctx.saveItem,
                title: "Save"
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClose"]))
}