import { email, helpers } from '@vuelidate/validators';

export const emailValidator = {
  ...email,
  $message: 'Please enter a valid email address',
};

export const phoneValidator = {
  $validator: helpers.regex(/^\+?\d{10,15}$/),
  $message: 'Please enter in the correct format. Example: +14155552671',
};
