import { RouteRecordRaw } from 'vue-router';
import { AccessType } from '@/common/enums/access/access-type.enum';
import { LayoutType } from '@/common/enums/layout';
import { adminLaunchesRoutes } from '@/routes/admin/routes/launches';
import AdminDefaultPage from './routes/default/admin-default-page.vue';
import { adminCompaniesRoutes } from './routes/companies';
import { adminMissionsRoutes } from './routes/missions';
import { adminRequestRoutes } from './routes/request';
import { adminUserRoutes } from './routes/users';
import { adminVehiclesRoutes } from './routes/vehicles';

export const AdminRoutes: RouteRecordRaw = {
  path: '/admin',
  name: 'admin',
  component: AdminDefaultPage,
  meta: {
    access: AccessType.Admin,
    layout: LayoutType.Default,
  },
  redirect() {
    return {
      name: 'admin-list-operators',
    };
  },
  children: [
    adminCompaniesRoutes,
    adminMissionsRoutes,
    adminRequestRoutes,
    adminUserRoutes,
    adminLaunchesRoutes,
    adminVehiclesRoutes,
  ],
};
