
import { computed, defineComponent } from 'vue';
import useModals from '@/composables/useModals';
import { IUserProfile } from '@/common/interfaces';
import { Modals } from '@/modals';
import { useStore } from '@/stores';

export default defineComponent({
  name: 'confirm-credentials-notice',

  setup() {
    const { userStore } = useStore();
    const { showModal } = useModals();

    const userData = computed<IUserProfile>(() => userStore.data);

    const fieldName = computed<string>(() =>
      !userData.value.emailVerified ? 'email' : 'phone',
    );

    function showConfirmCredentialsModal(): void {
      showModal(Modals.CONFIRM_CREDENTIALS_MODAL);
    }

    return {
      fieldName,
      showConfirmCredentialsModal,
    };
  },
});
