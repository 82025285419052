
import { defineComponent } from 'vue';
import { ref } from 'vue';
import { IMission } from '@/common/interfaces';
import { MissionPayloadTypes } from '@/common/enums';
import { Modals } from '@/modals';
import useModals from '@/composables/useModals';
import { useStore } from '@/stores';

export default defineComponent({
  props: {
    mission: {
      type: Object as () => IMission,
      required: true,
    },
  },

  setup(props) {
    const { hideModal } = useModals();
    const { missionsStore } = useStore();

    const types = Object.values(MissionPayloadTypes);

    const payloadType = ref(
      props.mission.payloadType
        ? [...props.mission.payloadType].filter((v) => !!v)
        : [],
    );

    function close() {
      hideModal(Modals.EDIT_PAYLOAD_TYPE_MODAL);
    }

    async function handleSave() {
      await missionsStore.update({
        id: props.mission.id,
        payloadType: payloadType.value,
      });

      close();
    }

    return {
      types,
      payloadType,
      close,
      handleSave,
    };
  },
});
