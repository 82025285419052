import { ComponentPublicInstance } from 'vue';
import { useCookies } from 'vue3-cookies';
import mixpanel, { Dict } from 'mixpanel-browser';

import { MixpanelActions } from '@/services';
import { CookieTypes } from './cookie-types.enum';
import posthog from 'posthog-js';

export function useMixpanel() {
  function initMixpanel(): void {
    const { cookies } = useCookies();

    if (cookies.isKey(CookieTypes.SKIP_MIXPANEL)) {
      console.info('Analytics events disabled');
      return;
    }

    if (process.env.VUE_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
      track('app_started');
    } else {
      throw new Error('Add a mixpanel token to .env');
    }

    // TODO: REMOVE POSTHOG TO THE SEPARATE COMPOSABLE
    const key = process.env.VUE_APP_POSTHOG_KEY;
    const enabled = process.env.VUE_APP_POSTHOG_ENABLED;

    console.info(key, enabled);

    if (key && enabled) {
      posthog.init(key, {
        api_host: 'https://hog.preciouspayload.com',
      });
    }
  }

  function track(id: MixpanelActions | string, action: Dict = {}): void {
    const { cookies } = useCookies();

    if (cookies.isKey(CookieTypes.SKIP_MIXPANEL)) {
      console.info('Tracking event: ', id, action);
      return;
    }

    if (process.env.VUE_APP_MIXPANEL_TOKEN) {
      mixpanel.track(id, {
        ...action,
        app_name: 'ctrl.manager',
      });
    }

    // TODO: REMOVE POSTHOG TO THE SEPARATE COMPOSABLE
    const key = process.env.VUE_APP_POSTHOG_KEY;
    const enabled = process.env.VUE_APP_POSTHOG_ENABLED;

    if (key && enabled) {
      posthog.capture(id, {
        ...action,
      });
    }
  }

  function trackEl(
    _ref: HTMLElement | ComponentPublicInstance,
    eventId: string,
    action: Dict = {},
  ): void {
    const el = _ref instanceof HTMLElement ? _ref : _ref.$el;

    const ids: string[] = [eventId];
    const args = {};
    let current = el;

    while (current.parentNode) {
      const { dataset } = current;

      if ('noTrack' in dataset) {
        return;
      }

      if (dataset.trackId) {
        ids.unshift(dataset.trackId);
      }

      if (dataset.trackArgs) {
        try {
          const arg = JSON.parse(dataset.trackArgs);
          Object.assign(args, arg);
        } catch (e) {
          console.error(e);
        }
      }

      current = current.parentNode as HTMLElement;
    }

    let id = ids.join(' - ');
    if (ids.length === 4) {
      id = `${ids[0]} - ${ids[1]} ${ids[2]} - ${ids[3]}`;
    }

    track(id, {
      ...args,
      ...action,
    });
  }

  return {
    initMixpanel,
    track,
    trackEl,
  };
}
