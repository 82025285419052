import { IJsonObject } from '@/common/interfaces';

export function getProp(data: IJsonObject, path: string) {
  path = path.replace(/\[(\w+)]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  const splitedPath = path.split('.');

  for (let i = 0, n = splitedPath.length; i < n; ++i) {
    const k = splitedPath[i];

    if (data[k] === void 0 || data[k] === null) {
      return;
    }

    if (k in data) {
      data = data[k] as IJsonObject;
    } else {
      return;
    }
  }

  return data;
}
