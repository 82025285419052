
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { IArchitecture, IMission, ITab } from '@/common/interfaces';
import SkeletonArch from '@/components/skeletons/skeleton-arch.vue';
import Architecture from './architecture.vue';
import TabsPanel from '@/components/tabs/tabs-panel.vue';
import useModals from '@/composables/useModals';
import { useStore } from '@/stores';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'mission-architectures',

  components: {
    TabsPanel,
    Architecture,
    SkeletonArch,
  },

  props: {
    mission: {
      type: Object as PropType<IMission>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { mission } = toRefs(props);
    const { showPrompt } = useModals();
    const { architectureStore } = useStore();
    const toast = useToast();

    const isLoading = computed<boolean>(() => architectureStore.loading);
    const currentIndex = computed<number>(() => architectureStore.currentIndex);
    const list = computed<IArchitecture[]>(() => architectureStore.list);
    const tabs = computed<ITab[]>(() => {
      return architectureStore.list.map((arch) => ({
        key: arch.id,
        name: arch.name,
      }));
    });

    function setIndex(index: number): void {
      architectureStore.setCurrentIndex(index);
    }

    async function addArchitecture(): Promise<void> {
      if (!mission.value.id) {
        toast.error('Mission is not valid');
        return;
      }

      const data: Partial<IArchitecture> = {};
      data.name = (await showPrompt('Enter name of new architecture')) || '';
      data.name = data.name.trim();

      if (!data.name) {
        toast.error('Please, provide correct name for architecture');
        return;
      }

      await architectureStore.create({
        missionId: mission.value.id,
        data,
      });
    }

    function changeTab(archId: string): void {
      const index = tabs.value.findIndex((el) => el.key === archId);

      if (index > -1) {
        setIndex(index);
      }
    }

    return {
      isLoading,
      currentIndex,
      list,
      tabs,
      changeTab,
      addArchitecture,
    };
  },
});
